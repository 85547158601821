<div id="shipmentNotes" class="collapsedShipmentNotes clickable" *ngIf="collapsed && showIconWhenCollapsed" (click)="toggleShipmentNotes()"
    [ngClass]="{ 'isClipboardExpanded': !isClipboardShortCutsFloaterCollapsed }">
    <div class="toggle-container">
        <span class="shipmentNotesIcon">
            <i class=" fa fa-pencil-square-o pencilFont " aria-hidden="true "></i>
        </span>
    </div>
</div>

<div *ngIf="!collapsed" class="expandedShipmentNotes">
    <div class="shipmentNotesContainer">
        <div class="shipmentNotesHeader">
            <div class="shipmentNotesHeaderLabel ">Shipment Notes</div>
            <div class="compressIconContainer">
                <span class="compressIconContainer clickable" (click)="toggleShipmentNotes()">
                    <img src="../../../assets/images/Compress_Icon.svg " class="compressIcon ">
                </span>
                <span class="pinIcon clickable " *ngIf="!isShipmentNotesPinned" (click)="togglePinnedState()">
                    <i class="fa fa-lock  pinFont "></i>
                </span>
                <span class="pinIcon" *ngIf="isShipmentNotesPinned" (click)="togglePinnedState()">
                    <i class="fa fa-unlock  pinFont"></i>
                </span>
            </div>

        </div>
        <div class="inputNotesSection">
            <input class="filterNotesInput" placeholder="Add a comment here">
            <button class="btn-outline clickable">
                <span>
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </span>
                <span class="buttonText">Comment</span>
            </button>
        </div>
        <div class="sectionContent">
            <div *ngIf="shipmentNotes" class="sectionHeader">
                <div class="sectionHeaderName" *ngIf="shipmentNotes.length">Name</div>
                <div class="sectionHeaderDateTime" *ngIf="shipmentNotes.length">Date &amp; Time</div>
            </div>
            <div *ngIf="shipmentNotes" class="noteCardContainerWrapper scrollbar">
                <div class="noteCardContainer ">
                    <div class="noteCard" *ngFor="let note of shipmentNotes">
                        <div class="noteHeading">
                            <div class="noteUserNamePanel">
                                <i class="fa fa-chevron-up collapseIcon blue" aria-hidden="true"></i>
                                <div *ngIf="!note?.noteOwner" class="userName">Unavailable</div>
                                <div *ngIf="note?.noteOwner" class="userName">{{note?.noteOwner}}</div>
                            </div>
                            <div class="noteDateTimePanel">
                                <div class="noteDate">{{note?.date}}</div>
                                <div class="noteTime">{{note?.time}}</div>
                                <span class="noteDeleteIcon">
                                    <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        <div class="noteBody">
                            <span>"</span><span [innerHTML]="note.note"></span>
                            <span>"</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
