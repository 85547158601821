import {FormControl} from "@angular/forms";

export function validateMoney(c: FormControl) {

    return isMoney(c.value) ? null : {
        validateMoney: {
            valid: false
        }
    };
}

export function isMoney(value: string) {
    let US_MONEY = /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/;
    let EURO_MONEY = /^(\d+|\d{1,3}(.\d{3})*)(\,\d+)?$/;

    return (US_MONEY.test(value) || EURO_MONEY.test(value));
}
