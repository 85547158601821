import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

import * as DateConstants from '../constants/datetime.constants';
import {AppStateService} from "../services/app-state.service";

@Injectable()
export class DateTimeHelper {

    constructor(
        private _appStateService: AppStateService
    ) { }

    public getDisplayDateFormat(): string {
        return this._appStateService.getAppState()['global.uniformDateDisplay.format'];
    }

    public getDisplayDateValidation(): RegExp {
        return this._appStateService.getAppState()['global.uniformDateDisplay.validation'];
    }

    public getFormattedDisplayDate(recordDate: string, apiDateFormat: string | moment.MomentBuiltinFormat): string {
        if (!recordDate || !apiDateFormat) {
            return '';
        }

        const displayDateFormat = this.getDisplayDateFormat();
        const formatttedRecordDate: string = moment.utc(recordDate, apiDateFormat).format(displayDateFormat);

        return formatttedRecordDate;
    }

    public prepareDateIfEndDateIndefinitely(recordDate: string, apiDateFormat: string) {
        if (recordDate.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
            const indefinteDate = moment(DateConstants.DATE_VALUE_INDEFINITELY, DateConstants.DATE_VALUE_INDEFINITELY_format).format(apiDateFormat);
            return this.getFormattedDisplayDate(indefinteDate, apiDateFormat);
        }
    }

    public prepareDate(recordDate: string, apiDateFormat: string): string {
        if (!recordDate || !apiDateFormat) {
            return '';
        }

        if (recordDate.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
            return moment(DateConstants.DATE_VALUE_INDEFINITELY, DateConstants.DATE_VALUE_INDEFINITELY_format).format(apiDateFormat);
        }

        const displayDateFormat = this.getDisplayDateFormat();
        const unformattedRecordDate: string = moment(recordDate, displayDateFormat).format(apiDateFormat);

        return unformattedRecordDate;
    }

    public prepareISO_8601Date(recordDate: string): string {
        if (!recordDate) {
            return '';
        }

        if (recordDate.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
            return moment.utc(DateConstants.DATE_VALUE_INDEFINITELY, DateConstants.DATE_VALUE_INDEFINITELY_format).toISOString();
        }

        const displayDateFormat = this.getDisplayDateFormat();
        const unformattedRecordDate: string = moment.utc(recordDate, displayDateFormat).toISOString();

        return unformattedRecordDate;
    }

    public getFormattedCurrentDate(): string {
        const displayDateFormat = this.getDisplayDateFormat();
        const formattedCurrentDate: string = moment().format(displayDateFormat);

        return formattedCurrentDate;
    }

    public getPreparedCurrentDate(apiDateFormat: string): string {
        if (!apiDateFormat) {
            return '';
        }

        const preparedCurrentDate: string = moment().format(apiDateFormat);

        return preparedCurrentDate;
    }

    public getFormattedStartOfCurrentMonth(): string {
        const displayDateFormat = this.getDisplayDateFormat();
        const formattedStartOfMonthDate: string = moment().startOf('month').format(displayDateFormat);

        return formattedStartOfMonthDate;
    }

    public dateValueValidator(): ValidatorFn {
        const displayDateValidation: RegExp = this.getDisplayDateValidation();
        const indefinitelyDisplay: string = DateConstants.DATE_VALUE_INDEFINITELY_display;

        return function (dateFormControl: UntypedFormControl): { invalidDate?: boolean } {
            const dateValue: string = dateFormControl.value;

            if (!dateValue || !displayDateValidation) {
                return null;
            }

            if (dateValue !== indefinitelyDisplay && !dateValue.match(displayDateValidation)) {
                return {
                    invalidDate: true
                };
            }

            return null;
        }
    }
}
