import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService, LogLevels } from '../services/logging.service';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class NotificationService {

    private options = {
        closeButton: true,
        tapToDismiss: false,
    }

    constructor(
        private _loggingService: LoggingService,
        private _toasterService: ToastrService,
    ) { }

    public notifySuccess({ title, message }: { title: string, message: string }) {
        return this._toasterService.success(message, title, this.options);
    }

    public notifyInfo({ title, message }: { title: string, message: string }) {
        return this._toasterService.info(message, title, this.options);
    }

    public notifyWarning({ title, message }: { title: string, message: string }) {
        return this._toasterService.warning(message, title, this.options);
    }

    public notifyError({ title, message }: { title: string, message: string }) {
        return this._toasterService.error(message, title, this.options);
    }

    public async showNotificationsFromResponseDtoMessages({ response, title }: { response: any, title: string }) {
        if (response && response.messages && response.messages.length) {
            response.messages.forEach(message => {
                if (message.messageType?.toUpperCase() === 'ERROR') {
                    this.notifyError({ title, message: `${this.getErrorTextFromMessage(message.message)}` });
                } else if (message.messageType?.toUpperCase() === 'WARNING') {
                    this.notifyWarning({ title, message: `${this.getErrorTextFromMessage(message.message)}` });
                } else if (message.messageType?.toUpperCase() === 'SUCCESS') {
                    this.notifySuccess({ title, message: `${this.getErrorTextFromMessage(message.message)}` });
                } else {
                    this.notifyError({ title, message: `${message.messageType} - ${this.getErrorTextFromMessage(message.message)}` });
                    this.notifyError({ title: `Invalid MessageType`, message: `Unknown message type [${message.messageType}] provided in ResponseDTO.` });
                }
            });
        } else if (response instanceof HttpErrorResponse) {
            if (response.error) {
                try {
                    if (response.error.Message) {
                        this.notifyError({title, message: `${response.error.Message}`});
                    } else {
                        try {
                            const errMsg = (response.error instanceof Blob) ? JSON.parse(await response.error.text()) : JSON.parse(response.error);
                            if (errMsg && errMsg.Message) {
                                this.notifyError({ title, message: `${errMsg.Message}` });
                            } else {
                                this.notifyError({ title, message: `${response.error}` });
                            }
                        } catch (e) {
                            // ???
                            this.notifyError({ title, message: `Unknown error occurred.` });
                        }
                    }
                } catch (e) {
                    this.notifyError({ title, message: `${response.message}` });
                }
            } else {
                this.notifyError({ title, message: `${response.message}` });
            }
        } else if (response.Message) {
            this.notifyError({ title, message: `${this.getErrorTextFromMessage(response.Message)}` });
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `Invalid Response structure provided to NotificationActions.showNotificationsFromResponseDtoMessages() => ${JSON.stringify(response)}`);
            this.notifyError({ title, message: `Invalid Response structure - Unknown error occurred.` });
        }
    }

    public displayInProgressNotification({ title = 'In Progress', message = 'This functionality is in progress' }: { title?: string, message?: string }): void {
        this.notifyWarning({ title, message });
    }

    private getErrorTextFromMessage(e: any): string {
        if (typeof e === 'string') {
            return e;
        } else if (typeof e === 'object' && e.Message) { // ColdFusion error
            if (e.Detail) {
                return `${e.Message}:  ${e.Detail}`;
            } else {
                return `${e.Message}`;
            }
        } else if (typeof e === 'object') {
            return JSON.stringify(e);
        } else {
            // what other cases and how to handle???
            return JSON.stringify(e);
        }
    }
}
