import {Component, Input, OnChanges} from '@angular/core';
import * as _ from 'lodash-es';
import {Stop, USO} from '../../models/unifiedShipmentObject';
import {QuickQuoteService} from '../../services/quickQuote.service';

@Component({
    selector: 'app-quote-costs',
    styleUrls: ['./app-quote-costs.component.scss'],
    templateUrl: './app-quote-costs.component.html',
})
export class AppQuoteCostsComponent implements OnChanges {
    @Input()
    public quickQuote: USO;
    @Input()
    public insuranceCost = 0;

    public lineHaulCost = 0;
    public fuelCost = 0;
    public accesorials = 0;
    public total = 0;
    public currency = '';

    constructor(private _quickQuoteService: QuickQuoteService) {}

    public ngOnChanges() {
        const result: any = this._quickQuoteService.loadCosts(this.quickQuote.bookingSelection);

        this.insuranceCost = this._quickQuoteService.lastInsuranceCharge;
        this.total = result.total;
        this.currency = result.currency;
        this.accesorials = result.extra;
        this.fuelCost = result.fuel;
        this.lineHaulCost = result.lineHaul;
    }

    public getTimeInTransit() {
        let days = 0;
        if (this.quickQuote.bookingSelection && this.quickQuote.bookingSelection.totalTransit) {
            days = this.quickQuote.bookingSelection.totalTransit.value;
        }

        return (days === 1) ? `${days} day` : `${days} days`;
    }

    public getEta() {
        let dateString = '';
        let destinationStopId;
        let destinationStop: Stop;

        if (this.quickQuote.bookingSelection &&
            this.quickQuote.bookingSelection.transitTimes &&
            this.quickQuote.bookingSelection.transitTimes.length > 0 &&
            this.quickQuote.bookingSelection.transitTimes[0] &&
            this.quickQuote.bookingSelection.route &&
            this.quickQuote.bookingSelection.route.length > 0
        ) {
            destinationStopId = this.quickQuote.bookingSelection.transitTimes[0].destinationStop;
            destinationStop = _.find(this.quickQuote.bookingSelection.route, (stop: Stop) => stop.stopID === destinationStopId);
            dateString = destinationStop.window.date;
        }

        return dateString;
    }
}
