import { HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {UserHelper} from '../helpers/userHelper';
import {Insurance} from '../models/insurance';
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from './api.service';
import {NotificationService} from "./notification.service";
import {ResponseDTO} from "../models/responseDto";

@Injectable()
export class InsuranceService {

    constructor(private _api: ApiService,
                private _notificationService: NotificationService,
                private _userHelper: UserHelper,
    ) {}

    public getInsuranceRec(shipmentId, quoteId) {
        const url = `shipment/insurance/getRecord?customer=${this._userHelper.getUserCustomer()}` +
            ((shipmentId) ? `&shipmentId=${shipmentId}` : '') +
            ((quoteId) ? `&quoteId=${quoteId}` : '');
        const req = new HttpRequest(REQUEST_TYPE_GET, url);
        return this._api.callApiService<ResponseDTO<Insurance|''>>(req).pipe(
            map(response => response.dto)
        );
    }

    public getInsuranceCharge(declaredShipmentValue: string) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `shipment/insurance/getCharge/${this._userHelper.getUserCustomer()}/${declaredShipmentValue}`);
        return this._api.callApiService<ResponseDTO<{ insuranceCharge: number }>>(req).pipe(
            map(response => {
                if (response) {
                    return response.dto.insuranceCharge;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `Error retrieving quote for shipment`, response: err});
                throw err;
            })
        );
    }

    public createInsurance(shipmentId: number|null, quoteId: number|null, insuranceCharge: number, shipmentValue: number) {
        let body: any = {
            shipmentValue,
            insuranceCharge,
            customer: this._userHelper.getUserCustomer(),
        }
        const req = new HttpRequest(REQUEST_TYPE_POST, `shipment/insurance/postRecord`, body);
        if (shipmentId) {
            body.shipmentId = shipmentId;
        }
        if (quoteId) {
            body.quoteId = quoteId;
        }
        return this._api.callApiService<ResponseDTO<any>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Add Insurance to Quote' });
                throw err;
            })
        );
    }

    public removeRecordInsurance(shipmentId: number) {
        const customerId = this._userHelper.getUserCustomer();
        const req = new HttpRequest(REQUEST_TYPE_DELETE, `shipment/insurance/${customerId}/${shipmentId}`);
        return this._api.callApiService<ResponseDTO<{ removed: boolean }>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.dto.removed;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `Error removing insurance for Customer: ${customerId} and Shipment: ${shipmentId}`, response: err});
                throw err;
            })
        );
    }
}
