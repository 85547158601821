import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {ClipboardNote} from '../models/clipboardNote';
import {ResponseDTO} from '../models/responseDto';
import {REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from '../services/api.service';

// Mock Data ...
const clipboardNotesMock: ResponseDTO<Array<ClipboardNote>> = {
    isValid: true,
    messages: [],
    dto: [
        {
            clipboardID: 1,
            clipboard: 'Branch review, yet race without a finish line fire up your browser market-facing, or powerPointless. Who is responsible for the ask for this request? strategic fit gain traction for overcome key issues to meet key milestones that jerk from finance really threw me under the bus.We want to see more charts I have zero cycles for this, for at the end of the day, metal and social currency. Mobile friendly wheelhouse, and root-an',
            clipboardGroup: 'Internal Mtg Minutes',
            clipboardType: 'note',
            clipboardUserid: 1,
            changeDate: '2018-04-26 16:44:43.0',
            changeBy: '2',
        },
        {
            clipboardID: 2,
            clipboard: 'Lets fire up your browser market We want to see more charts I have zero cycles for this, for at the end of the day, but new economy but level the playing field, but closer to the metal and social currency. Mobile friendly wheelhouse, and root-and-branch review, yet race without a finish line -facing come key issues to meet key milestones that jerk from finance really threw me under the bus.',
            clipboardGroup: 'Tuesday ToDos',
            clipboardType: 'note',
            clipboardUserid: 1,
            changeDate: '2018-04-26 16:44:43.0',
            changeBy: '2',
        },
        {
            clipboardID: 3,
            clipboard: 'Mobile friendly wheelhouse, and root-and-branch review, yet race without a finish line fire up your browser market-facing, or powerPointless. Who is responsible for the ask for this request? strategic fit gain traction for overcome key issues to meet key milestones that jerk from finance really threw me under the bus.',
            clipboardGroup: 'Procedure for Creating Quote',
            clipboardType: 'note',
            clipboardUserid: 1,
            changeDate: '2018-04-26 16:44:43.0',
            changeBy: '2',
        },
        {
            clipboardID: 4,
            clipboard: 'We want to see more charts I have zero cycles for this, for at the end of the day, but new economy but level the playing field, but closer to the metal and social currency. Mobile friendly wheelhouse, and root-and-branch review, yet race without a finish line fire up your browser market-facing, or powerPointless. Who is responsible for the ask for this request? strategic fit gain traction for overcome key issues to meet key milestones that jerk from finance really threw me under the bus.',
            clipboardGroup: 'Details for Carrier Order',
            clipboardType: 'note',
            clipboardUserid: 1,
            changeDate: '2018-04-26 16:44:43.0',
            changeBy: '2',
        },
        {
            clipboardID: 5,
            clipboard: 'Golden goose out of scope commitment to the cause and hard stop. Streamline wiggle room when does this sunset? this is a no-brainer, draw a line in the sand. Future-proof. When does this sunset? obviously where the metal hits the meat churning anomalies and upsell drill down, but cannibalize. To be inspired is to become creative, innovative and energized we want this philosophy to trickle down to all our stakeholders great plan! let me diarize this, and we can synchronise ourselves at a later timepoint. Take five, punch the tree, and come back in here with a clear head ladder up / ladder back to the strategy, for accountable talk nor pulling teeth, yet ramp up. Work flows ultimate measure of success on your plate, but goalposts or imagineer. Reach out. Paddle on both sides dog and pony show, great plan! let me diarize this, and we can synchronise ourselves at a later timepoint, nor window-licker herding cats player-coach bake it in. We need to start advertising on social media what do you feel you would bring to the table if you were hired for this position productize nor waste of resources game plan.',
            clipboardGroup: 'Notes from Tues Client Call',
            clipboardType: 'note',
            clipboardUserid: 1,
            changeDate: '2018-04-26 16:44:43.0',
            changeBy: '2',
        },
    ]
};

@Injectable()
export class MockClipboardInterceptor implements HttpInterceptor {

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.useMocking.clipboard) {
            if (req.url === `${environment.apiBaseUrl}/MasterData/Cclipboard` && req.method === REQUEST_TYPE_GET) {
                const response = new HttpResponse({body: this.getClipboardNotesResponse()});
                return of(response);
            }
        }
        return next.handle(req);
    }

    public getClipboardNotesResponse(): ResponseDTO<Array<ClipboardNote>> {
        return clipboardNotesMock;
    }
}
