import {Contact, Window} from './unifiedShipmentObject';

export class ZipSearchResult {
    location: {
        longitude: number;
        latitude: number;
    };
    id: number;
    _type: string;
    zip_code: string;
    areacode: number;
    state: string; // state code
    statename: string;
    city: string;
    citytype: string;
    countryname: string;
    postaltype: string;
    dst: string;
    utc: number;
    latitude: number;
    longitude: number;
    countyfips: string;
    countyname: string;
    statefips: string;
    timezone: string;
    msacode: string;
    name?: string;
    addressLines?: string[];
    type?: string;
    window?: Window;
    contact?: Contact;
    appointmentRequired?: boolean;
    '@version': number;
    '@timestamp': string;
}
