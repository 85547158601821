import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import * as SearchConstants from '../constants/searchCriteria';
import {Dashboard} from '../models/dashboard';
import {DashboardView} from '../models/dashboardView';
import {ResponseDTO} from '../models/responseDto';
import {REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from '../services/api.service';

// Mock Data ...
const defaultViews: Array<DashboardView> = [
    {
        id: 3056,
        name: 'Help Center',
        category: 'Fundamentals',
        editable: 1,
        icon: 'fa fa-info-circle',
        viewOrder: 1,
        viewType: 'helpCenter',
        visibleColumns: [],
    },
    {
        id: 1,
        name: 'My Alerts',
        icon: 'fa fa-bell-o',
        category: 'Fundamentals',
        editable: 1,
        visibleColumns: ['bol', 'shipper_name', 'consignee_name', 'carrier', 'pickup_date', 'rated_amt'],
        viewType: 'contentQuery',
        viewOrder: 2,
        viewContentQuery: [
            {
                type: SearchConstants.SEARCH_CRITERIA_type.ALL,
                value: 'freight',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            },
            {
                type: 'consignee_city',
                value: 'norfolk',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            }
        ]
    },
    {
        id: 2,
        name: 'Global Search',
        icon: 'fa fa-search',
        category: 'Fundamentals',
        editable: 0,
        visibleColumns: null,
        viewType: 'globalSearch',
        viewOrder: 3,
        viewContentQuery: null,
    },
    {
        id: 3,
        name: 'Create View',
        icon: 'fa fa-plus-circle',
        category: 'Fundamentals',
        editable: 0,
        visibleColumns: null,
        viewType: 'createView',
        viewOrder: 4,
        viewContentQuery: null,
    }
];
const userCreatedViews = [
    {
        id: 4,
        name: 'My Shortcuts',
        icon: 'fa fa-user-circle-o',    // TODO - need to replace this with icon svg in assets folder
        category: 'Fundamentals',
        editable: 1,
        visibleColumns: ['Favorites', 'Templates', 'Tags'],
        viewType: 'myShortcuts',
        viewOrder: 4,
        viewContentQuery: [
            {
                type: SearchConstants.SEARCH_CRITERIA_type.ALL,
                value: 'WARD',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            },
            {
                type: 'consignee_city',
                value: 'norfolk',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            }
        ]
    },
    {
        id: 5,
        name: 'Open Records',
        icon: 'fa fa-pie-chart',
        category: 'Record Management',
        editable: 1,
        visibleColumns: ['bol', 'shipper_name', 'consignee_name', 'carrier', 'pickup_date', 'rated_amt'],
        viewType: 'contentQuery',
        viewOrder: 5,
        viewContentQuery: [
            {
                type: SearchConstants.SEARCH_CRITERIA_type.ALL,
                value: 'WARD',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            },
            {
                type: 'consignee_city',
                value: 'norfolk',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            }
        ]
    },
    {
        id: 6,
        name: 'Shipper Pickup',
        icon: 'fa fa-calendar',
        category: 'Quote Management',
        editable: 1,
        visibleColumns: ['bol', 'shipper_name', 'consignee_name', 'carrier', 'pickup_date', 'rated_amt'],
        viewType: 'contentQuery',
        viewOrder: 6,
        viewContentQuery: [
            {
                type: SearchConstants.SEARCH_CRITERIA_type.ALL,
                value: 'WARD',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            },
            {
                type: 'consignee_city',
                value: 'norfolk',
                entityType: 'shipments',
                boolQuery: 'must',
                pattern: 'match'
            }
        ]
    }
];

export const defaultDashboards: Array<Dashboard> = [
    {
        id: 1,
        name: 'My First Dashboard',
        views: [...defaultViews],
        defaultDashboard: 1,
    },
    {
        id: 2,
        name: 'Dashboard2',
        views: [...userCreatedViews],
        defaultDashboard: 0,
    }
];

@Injectable()
export class MockDashboardInterceptor implements HttpInterceptor {

    private id: number = 2;
    private dashboards: Array<Dashboard> = defaultDashboards;

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.useMocking.dashboard) {
            if (req.url === `${environment.apiBaseUrl}/dashboards` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: this.getDashboards()}));
            } else if (req.url === `${environment.apiBaseUrl}/dashboards/1` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: this.getDashboard(1)}));
            } else if (req.url === `${environment.apiBaseUrl}/dashboards/1` && req.method === REQUEST_TYPE_PUT) {
                return of(new HttpResponse({body: this.updateDashboardName(req.body)}));
            } else if (req.url === `${environment.apiBaseUrl}/dashboards` && req.method === REQUEST_TYPE_POST) {
                return of(new HttpResponse({body: this.createDashboard(req.body)}));
            } else if (req.url.startsWith(`${environment.apiBaseUrl}/dashboards/`) && req.method === REQUEST_TYPE_DELETE) {
                return of(new HttpResponse({body: this.deleteDashboard(parseInt(req.url.substring(-1), 10))}));
            } else if (req.url.startsWith(`${environment.apiBaseUrl}/dashboardViews/views/`) && req.method === REQUEST_TYPE_POST) {
                return of(new HttpResponse({body: this.createDashboardView(req.body)}));
            } else if (req.url.startsWith(`${environment.apiBaseUrl}/dashboardViews/views/`) && req.method === REQUEST_TYPE_PUT) {
                return of(new HttpResponse({body: this.updateDashboardView(req.body)}));
            } else if (req.url.startsWith(`${environment.apiBaseUrl}/dashboardViews/views/`) && req.method === REQUEST_TYPE_DELETE) {
                return of(new HttpResponse({body: this.deleteDashboardView()}));
            }
        }
        return next.handle(req);
    }

    private getDashboards(): ResponseDTO<Array<Dashboard>> {
        const response: ResponseDTO<Dashboard[]> = {
            dto: this.dashboards,
            isValid: true,
            messages: []
        }
        return response;
    }

    private getDashboard(id: number): Dashboard {
        return this.dashboards.find(dashboard => dashboard.id === id);
    }

    private deleteDashboard(id: number): ResponseDTO<any> {
        return {
            dto: null,
            isValid: true,
            messages: []
        }
    }

    private updateDashboardName({name, id = 1}): ResponseDTO<Dashboard> {
        const selectedDashboard = this.dashboards.find(dashboard => {
            return dashboard.id === id;
        });
        selectedDashboard.name = name;
        return {
            dto: selectedDashboard,
            isValid: true,
            messages: []
        };
    }

    private createDashboard(name: string): ResponseDTO<Dashboard> {
        return {
            dto: {
                name: name,
                id: Math.floor(Math.random() * 3000) + 1000,
                defaultDashboard: 0,
                views: defaultViews
            },
            isValid: true,
            messages: []
            // ...this.dashboards,
        };
    }

    private updateDashboardView(editedDashboardView: DashboardView): ResponseDTO<DashboardView> {
        return {
            dto: editedDashboardView,
            isValid: true,
            messages: []
        }
    }

    private createDashboardView(newDashboardView: DashboardView): ResponseDTO<DashboardView> {
        const newRandomIdNumber = Math.round((Math.random() * 3000)) + 1000;
        const dashboardView = {
            id: newRandomIdNumber, // intermediary step to produce unique id for front end consumption
            name: newDashboardView.name,
            icon: newDashboardView.icon,
            category: newDashboardView.category,
            editable: newDashboardView.editable,
            visibleColumns: newDashboardView.visibleColumns,
            viewType: newDashboardView.viewType,
            viewOrder: 1,
            viewContentQuery: newDashboardView.viewContentQuery
        };
        // defaultViews.push(dashboardView);
        return {
            dto: dashboardView,
            isValid: true,
            messages: []
        }
    }

    private deleteDashboardView(): ResponseDTO<any> {
        return {
            dto: null,
            isValid: true,
            messages: []
        }
    }

}
