import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash-es';
import moment from 'moment';

import {environment} from '../../../environments/environment';

import * as DateConstants from '../../constants/datetime.constants';
import * as MDConstants from '../../constants/masterData.constants';
import * as SearchConstants from '../../constants/searchCriteria';
import {DateTimeHelper} from '../../helpers/datetime.helper';
import {UserHelper} from '../../helpers/userHelper';
import {ClipboardNote, CreateClipboardNoteRequest} from '../../models/clipboardNote';
import {Favorite} from '../../models/favorite';
import {Template} from '../../models/template';
import {ShipmentService} from '../../services/shipment.service';
import {SignalsService} from "../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {ClipboardService} from "../../services/clipboard.service";
import {TemplateService} from "../../services/template.service";
import {FavoritesService} from "../../services/favorites.service";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-clipboard',
    templateUrl: './clipboard.component.html',
    styleUrls: ['./clipboard.component.scss']
})
export class ClipboardComponent implements OnInit, OnDestroy {

    /* temp feature flag to turn ON/OFF templates by env until feature complete */
    public SHOWTEMPLATES: boolean = environment.showTemplates;

    public isClipboardFloaterCollapsed: boolean;
    public isClipboardPinned: boolean;
    public isFavoritesShown: boolean;
    public isTemplatesShown: boolean;
    public isNotesShown: boolean;
    public isCreateIconShown: boolean;
    public isCreateNoteInputBoxShown: boolean = false;
    public createNoteInput: string;
    private appStateSubscription;

    public favorites: Array<Favorite>;
    private favoritesSubscription: any;

    public notes: Array<ClipboardNote>;
    private notesSubscription: any;

    public templates: Array<Template>;
    private templatesSubscription: any;

    public MDConstants = MDConstants;
    public isConfirmDeleteTemplateModalVisible: boolean = false;
    public confirmDeleteTemplateTitle: string = 'Confirm Template Deletion';
    public confirmDeleteTemplateMessage: string = 'Are you certain you\'d like to delete this template?';
    public selectedTemplateId: number;

    public isShipmentNotesCollapsed: boolean;
    private appState$;
    private clips$;
    private favorites$;
    private templates$;

    constructor(
        private _router: Router,
        private _signalsService: SignalsService,
        private _clipboardService: ClipboardService,
        private _notificationService: NotificationService,
        private _templateService: TemplateService,
        private _favoritesService: FavoritesService,
        private _dateHelper: DateTimeHelper,
        private _userHelper: UserHelper,
        private _shipmentService: ShipmentService,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.clips$ = toObservable(this._signalsService.clipsSignal);
        this.favorites$ = toObservable(this._signalsService.favoritesSignal);
        this.templates$ = toObservable(this._signalsService.templatesSignal);
    }

    ngOnInit() {
        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.isClipboardFloaterCollapsed = appState['floater.clipboardCollapsed'];
            this.isClipboardPinned = appState['floater.clipboardPinned'];
            this.isFavoritesShown = appState['clipboard.isFavoritesShown'];
            this.isTemplatesShown = appState['clipboard.isTemplatesShown'];
            this.isNotesShown = appState['clipboard.isNotesShown'];
            this.isCreateIconShown = this.isNotesShown; // only show create icon for Notes tab
            this.isShipmentNotesCollapsed = appState['floater.shipmentNotesCollapsed'];
        });

        this.favoritesSubscription = this.favorites$.subscribe((favs: Array<Favorite>) => {
            this.favorites = favs;
        });

        this.templatesSubscription = this.templates$.subscribe((t: Array<Template>) => {
            this.templates = t;
        });

        this.notesSubscription = this.clips$.subscribe((cn: Array<ClipboardNote>) => {
            // sort notes in reverse chronological order (show most recent notes first!)
            this.notes = _.sortBy(cn, ['id', 'changeDate']).reverse();
        });
    }

    ngOnDestroy() {
        this.appStateSubscription.unsubscribe();
        this.favoritesSubscription.unsubscribe();
        this.templatesSubscription.unsubscribe();
        this.notesSubscription.unsubscribe();
    }

    public toggleClipboard() {
        const isClipboardFloaterCollapsed = !this.isClipboardFloaterCollapsed;
        this._signalsService.updateAppState({'floater.clipboardCollapsed': isClipboardFloaterCollapsed});
        if (!this.isClipboardFloaterCollapsed) {
            this._signalsService.updateAppState({'floater.shipmentNotesCollapsed': true});
        }
    }

    public togglePinnedState(): void {
        if (!this.isClipboardPinned) {
            this._signalsService.updateAppState({'floater.clipboardPinned': true});
        } else {
            this._signalsService.updateAppState({'floater.clipboardPinned': false});
        }
    }

    public getClipboardDisplayDate(d: string): string {
        const momentFavoriteDate: moment.Moment = moment(d, DateConstants.DATE_TIME_FORMAT_YYYYMMDDHHmmssS);
        if (momentFavoriteDate.isSame(moment(), 'day')) {
            return momentFavoriteDate.format(DateConstants.TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN_WITH_SPACE);
        } else {
            return this._dateHelper.getFormattedDisplayDate(d, DateConstants.DATE_TIME_FORMAT_YYYYMMDDHHmmssS);
        }
    }

    public navigateToRecord(favorite: Favorite): void {
        const customerId = this._userHelper.getUserCustomer();
        switch (favorite.favoriteType) {
            case SearchConstants.SEARCH_CRITERIA_entityType.SHIPMENTS:
                const shipmentId = favorite.favoriteIdentifier;
                this._shipmentService.navigateViaShipment(customerId, shipmentId);
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.INVOICES:
                const invoiceId = favorite.favoriteIdentifier;
                this._router.navigate(['invoice', invoiceId]);
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.QUOTES:
                const quoteId = favorite.favoriteIdentifier;
                this._shipmentService.navigateViaQuote(quoteId);
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS:
                const contactId = favorite.favoriteIdentifier;
                this._router.navigate(['masterData/contacts', contactId]);
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_PRODUCTS:
                const productId = favorite.favoriteIdentifier;
                this._router.navigate(['masterData/products', productId]);
                break;
        }
    }

    public showFavorites(): void {
        this._signalsService.updateAppState({
            'clipboard.isFavoritesShown': true,
            'clipboard.isTemplatesShown': false,
            'clipboard.isNotesShown': false
        });
    }

    public showTemplates(): void {
        this._signalsService.updateAppState({
            'clipboard.isFavoritesShown': false,
            'clipboard.isTemplatesShown': true,
            'clipboard.isNotesShown': false
        });
    }

    public showNotes(): void {
        this._signalsService.updateAppState({
            'clipboard.isFavoritesShown': false,
            'clipboard.isTemplatesShown': false,
            'clipboard.isNotesShown': true
        });
    }

    public removeSelectedFavorite(event: Event, favorite: Favorite) {
        event.stopPropagation();
        this._favoritesService.removeFavorite(favorite.favoriteNo);
    }

    public templateSelected(id: number): void {
        const template = this.templates.find(t => t.templateID === id);
        if (!template) {
            this._notificationService.notifyError({
                title: 'Template',
                message: `Unable to retrieve template having ID [${id}]`
            });
            return;
        }
        this._templateService.applyUserTemplateNavigation(id, template.templateType);
    }

    public createNote(): void {
        this.isCreateNoteInputBoxShown = true;
    }

    public cancelNoteCreation(): void {
        this.isCreateNoteInputBoxShown = false;
        this.createNoteInput = '';
    }

    public saveNote(): void {
        const newNote: CreateClipboardNoteRequest = {
            clipboardGroup: '?',
            clipboardType: '?',
            clipboard: this.createNoteInput
        };
        this._clipboardService.createClipboardNote(newNote);

        // hide new note box assuming successful creation (may need to move this to success response handler)
        this.isCreateNoteInputBoxShown = false;
        this.createNoteInput = '';
    }

    public deleteNote(id: number): void {
        this._clipboardService.deleteClipboardNote(id);
    }

    public showTemplateConfirmDeleteModal(event: Event, templateId: number): void {
        this.selectedTemplateId = templateId;
        event.stopPropagation();
        this.isConfirmDeleteTemplateModalVisible = true;
    }

    public hideTemplateConfirmDeleteModal(): void {
        this.isConfirmDeleteTemplateModalVisible = false;
    }

    public deleteTemplate(templateId: number): void {
        this.hideTemplateConfirmDeleteModal();
        this._templateService.deleteUserTemplate(templateId);
    }
}
