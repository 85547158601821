import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {AccessorialForQuote} from '../models/accessorialForQuote';
import {Country} from '../models/country';
import {HandlingUnit} from '../models/handlingUnit';
import {NmfcClass} from '../models/nmfcClass';
import {PackageType} from '../models/packageType';
import {ResponseDTO} from '../models/responseDto';
import {ResponseList} from '../models/responseList';
import {UnitOfMeasure} from '../models/unitOfMeasure';
import {REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from '../services/api.service';

// Mock data ...
const mockResponse_AppDisplayData: ResponseDTO<any> = {
    isValid: true,
    messages: [
        {
            message: 'First Message',
            messageType: 'inside interceptor'
        },
        {
            message: 'Second Message',
            messageType: 'secondary'
        }
    ],
    dto: {
        searchCriteriaTypes: [
            'All',
            'User',
            'Creator',
            'BOL Number',
            'Carrier Name',
            'Shipper',
            'Consignee',
            'Status',
            'Pro Number',
            'PO Number',
            'Load ID',
            'Record ID',
            'Location',
            'Zip Code',
            'Group',
            'Tag',
            'Departments',
            'Shipment Data',
            'Records',
            'Invoices',
            'Master Data'
        ],
        searchEntityColumns: {
            records: {},
            shipments: {
                rated_amt: 'Amount',
                pickup_date: 'Ship Date',
                carrier: 'Carrier',
                shipper_name: 'Shipper',
                bol: 'BOL',
                consignee_name: 'Consignee'
            },
            masterdata: {},
            quotes: {
                shipdate: 'Ship Date',
                ship_id: 'Quote ID',
                amount: 'Amount',
                ozip: 'Origin Zip',
                dzip: 'Destination Zip',
                carrier: 'Carrier'
            },
            invoices: {
                wk_end_date: 'Invoice Date',
                gl_code: 'GL Code',
                pro_number: 'Pro Number',
                carrier: 'Carrier'
            }
        },
        searchEntityFilters: {
            records: {},
            shipments: {
                rated_amt: 'Price',
                consignee_city: 'Consignee City',
                pickup_date: 'Ship Date',
                pro_number: 'Pro Number',
                consignee_zip: 'Consignee Zip',
                carrier: 'Carrier',
                po_num: 'Purchase Order',
                status: 'Status',
                shipper_zip: 'Shipper Zip',
                scac: 'SCAC',
                delivery_date: 'Delivery Date',
                product: 'Product',
                customer: 'Customer',
                shipper_city: 'Shipper City',
                shipper_name: 'Shipper Name',
                bol: 'BOL',
                consignee_name: 'Consignee Name'
            },
            'master mata': {},
            quotes: {
                shipdate: 'Ship Date',
                ship_id: 'Quote ID',
                scac: 'SCAC',
                amount: 'Amount',
                product: 'Product',
                ozip: 'Origin Zip',
                customer: 'Customer',
                dzip: 'Destination Zip',
                carrier: 'Carrier'
            },
            invoices: {
                wk_end_date: 'Invoice Date',
                accl_name: 'Accessorial',
                gl_code: 'GL Code',
                mileage: 'Mileage',
                consignee: 'Consignee',
                filler: 'Purchase Order',
                ttl_wgt: 'Weight',
                shipper: 'Shipper',
                carrier: 'Carrier',
                delv_date: 'Delivery Date',
                scac: 'SCAC',
                po_bol: 'Bill of Lading',
                audit_code: 'Audit Code',
                ship_date: 'Ship Date',
                check_no: 'Check Number',
                customer: 'Customer',
                recd_date: 'Received Date',
                paid_date: 'Paid Date',
                pro_no: 'Pro Number'
            }
        }
    }
};

const mockResponse_Accessorials: { accessorial: Array<AccessorialForQuote>, error: number } = {
    accessorial:
        [
            {
                'code': 'ADVANCECOLLECTIONFEE',
                'displayName': 'Advance Collection Fee'
            },
            {
                'code': 'AFTERHOURSCHARGE',
                'displayName': 'After Hours Charge'
            },
            {
                'code': 'AFTERHOURSDELIVERY',
                'displayName': 'After Hours Delivery'
            },
            {
                'code': 'AFTERHOURSPICKUP',
                'displayName': 'After Hours Pick Up'
            },
            {
                'code': 'AIRPORTPICKUPORDELIVERY',
                'displayName': 'Airport Pick Up or Delivery'
            },
            {
                'code': 'APPOINTMENT',
                'displayName': 'Appointment'
            },
            {
                'code': 'ATTEMPTEDPICKUP',
                'displayName': 'Attempted Pickup'
            },
            {
                'code': 'BLINDSHIPMENTDELIVERY',
                'displayName': 'Blind Shipment Delivery'
            },
            {
                'code': 'BLINDSHIPMENTPICKUP',
                'displayName': 'Blind Shipment Pick Up'
            },
            {
                'code': 'BONDPASSTHROUGH',
                'displayName': 'Bond Pass Through'
            },
            {
                'code': 'CANADIANCUSTOMS',
                'displayName': 'Canadian Customs'
            },
            {
                'code': 'CHANGEBOLFEE',
                'displayName': 'Change BOL Fee'
            },
            {
                'code': 'CLEANUPDEBRIS',
                'displayName': 'Clean up Debris'
            },
            {
                'code': 'COD',
                'valueRequired': 'Value of Shipment',
                'displayName': 'COD'
            },
            {
                'code': 'CONSOLIDATION',
                'displayName': 'Consolidation'
            },
            {
                'code': 'CONSTRUCTIONSITE',
                'displayName': 'Construction Site'
            },
            {
                'code': 'CORRECTEDBOL',
                'displayName': 'Corrected BOL'
            },
            {
                'code': 'CUSTOMSBONDCREATION',
                'displayName': 'Customs Bond Creation'
            },
            {
                'code': 'CUSTOMSCLEARANCEDOCUMENT',
                'displayName': 'Customs Clearance Document'
            },
            {
                'code': 'DEADFREIGHT',
                'displayName': 'Dead Freight'
            },
            {
                'code': 'DELAYCHARGE',
                'displayName': 'Delay Charge'
            },
            {
                'code': 'DELIVERYCHARGE',
                'displayName': 'Delivery Charge'
            },
            {
                'code': 'DESCRIPTIONINSPECTION',
                'displayName': 'Description Inspection'
            },
            {
                'code': 'DETENTIONFEE',
                'displayName': 'Detention Fee'
            },
            {
                'code': 'DOCUMENTATION',
                'displayName': 'Documentation'
            },
            {
                'code': 'DRIVERASSIST',
                'displayName': 'Driver Assist'
            },
            {
                'code': 'DRYRUNCHARGE',
                'valueRequired': 'Number of Items',
                'displayName': 'Dry Run Charge'
            },
            {
                'code': 'EXCESSOVERLENGTH',
                'displayName': 'Excess Over Length'
            },
            {
                'code': 'EXHIBITIONTRADEDEL',
                'displayName': 'Exhibition Trade Del'
            },
            {
                'code': 'EXHIBITIONTRADEPU',
                'displayName': 'Exhibition Trade PU'
            },
            {
                'code': 'EXPEDITED',
                'displayName': 'Expedited'
            },
            {
                'code': 'EXPEDITEDSAMEDAY',
                'displayName': 'Expedited Same Day'
            },
            {
                'code': 'EXTRALABORHELP',
                'valueRequired': 'Number of Hours',
                'displayName': 'Extra Labor Help'
            },
            {
                'code': 'EXTRASTOPCHARGE',
                'displayName': 'Extra Stop charge'
            },
            {
                'code': 'FREEZEPROTECTION',
                'displayName': 'Freeze Protection'
            },
            {
                'code': 'GUARANTEEBY10',
                'displayName': 'Guarantee By 10'
            },
            {
                'code': 'GUARANTEEBY12',
                'displayName': 'Guarantee By 12'
            },
            {
                'code': 'GUARANTEEBY9',
                'displayName': 'Guarantee By 9'
            },
            {
                'code': 'GUARANTEESTANDARD',
                'displayName': 'Guarantee Standard'
            },
            {
                'code': 'GUARANTEETIMESLOT',
                'displayName': 'Guarantee Time Slot'
            },
            {
                'code': 'HAZMAT',
                'displayName': 'Hazmat'
            },
            {
                'code': 'INBONDFREIGHT',
                'displayName': 'Inbond Freight'
            },
            {
                'code': 'INSIDEDELIVERY',
                'displayName': 'Inside Delivery'
            },
            {
                'code': 'INSIDEPICKUP',
                'displayName': 'Inside Pickup'
            },
            {
                'code': 'LAYOVERFEE',
                'displayName': 'Layover Fee'
            },
            {
                'code': 'LIFTGATEATDELIVERY',
                'displayName': 'Lift Gate at Delivery'
            },
            {
                'code': 'LIFTGATEATPICKUP',
                'displayName': 'Lift Gate at Pick Up'
            },
            {
                'code': 'LIMITEDACCESS',
                'displayName': 'Limited Access'
            },
            {
                'code': 'LOADINGORUNLOADING',
                'displayName': 'Loading or Unloading'
            },
            {
                'code': 'LUMPERFEE',
                'valueRequired': 'Number of Hours',
                'displayName': 'Lumper Fee'
            },
            {
                'code': 'MARKINGANDTAGGING',
                'valueRequired': 'Number of Items',
                'displayName': 'Marking and Tagging'
            },
            {
                'code': 'MILITARYBASEDELIVERY',
                'displayName': 'Military Base Delivery'
            },
            {
                'code': 'NOTIFICATION',
                'displayName': 'Notification'
            },
            {
                'code': 'PERMITFEE',
                'displayName': 'Permit Fee'
            },
            {
                'code': 'PICKUPCHARGE',
                'displayName': 'Pickup Charge'
            },
            {
                'code': 'PIERCHARGE',
                'displayName': 'Pier Charge'
            },
            {
                'code': 'PORTCHARGE',
                'displayName': 'Port Charge'
            },
            {
                'code': 'RECONSIGNMENT',
                'displayName': 'Reconsignment'
            },
            {
                'code': 'REDELIVERY',
                'displayName': 'Redelivery'
            },
            {
                'code': 'RESIDENTIALDELIVERY',
                'displayName': 'Residential Delivery'
            },
            {
                'code': 'RESIDENTIALPICKUP',
                'displayName': 'Residential pickup'
            },
            {
                'code': 'ROOMOFCHOICE',
                'displayName': 'Room Of Choice'
            },
            {
                'code': 'SATURDAYDELIVERY',
                'valueRequired': 'Number of Hours',
                'displayName': 'Saturday Delivery'
            },
            {
                'code': 'SECURITYSERVICECHG',
                'displayName': 'Security Service Chg'
            },
            {
                'code': 'SINGLESHIPMENT',
                'displayName': 'Single Shipment'
            },
            {
                'code': 'SORTINGSEGREGATING',
                'valueRequired': 'Number of Items',
                'displayName': 'Sorting Segregating'
            },
            {
                'code': 'STOPOFFCHARGE',
                'displayName': 'Stop Off Charge'
            },
            {
                'code': 'STORAGE',
                'displayName': 'Storage'
            },
            {
                'code': 'TARPCHARGE',
                'displayName': 'Tarp Charge'
            },
            {
                'code': 'TEAMCHARGE',
                'displayName': 'Team Charge'
            },
            {
                'code': 'TRUCKNOTUSED',
                'displayName': 'Truck Not Used'
            },
            {
                'code': 'TRUCKORDEREDNOTUSED',
                'displayName': 'Truck ordered not used'
            },
            {
                'code': 'TWOMAN',
                'valueRequired': 'Number of Hours',
                'displayName': 'Two Man'
            },
            {
                'code': 'UNLOADINGLABOR',
                'displayName': 'Unloading Labor'
            },
            {
                'code': 'WAITTIME',
                'valueRequired': 'Number of Hours',
                'displayName': 'Wait Time'
            },
            {
                'code': 'WEEKENDORHOLIDAYDEL',
                'valueRequired': 'Number of Hours',
                'displayName': 'Weekend or Holiday Del'
            },
            {
                'code': 'WEIGHTINSPECTION',
                'displayName': 'Weight Inspection'
            },
            {
                'code': 'WEIGHTVERIFICATION',
                'displayName': 'Weight Verification'
            },
            {
                'code': 'WHITEGLOVE',
                'displayName': 'White Glove'
            }
        ],
    error: 0.0
};

const mockResponse_Countries: ResponseList<Country> = {
    count: 28,
    results: [
        {
            countryCode: 'US',
            sort: 1,
            id: 1,
            countryDesc: 'United States'
        },
        {
            countryCode: 'CA',
            sort: 2,
            id: 2,
            countryDesc: 'Canada'
        },
        {
            countryCode: 'AT',
            sort: 3,
            id: 3,
            countryDesc: 'Austria'
        },
        {
            countryCode: 'BE',
            sort: 3,
            id: 4,
            countryDesc: 'Belgium'
        },
        {
            countryCode: 'BG',
            sort: 3,
            id: 15,
            countryDesc: ''
        },
        {
            countryCode: 'CH',
            sort: 3,
            id: 5,
            countryDesc: 'Switzerland'
        },
        {
            countryCode: 'CZ',
            sort: 3,
            id: 6,
            countryDesc: 'Czech Republic'
        },
        {
            countryCode: 'DE',
            sort: 3,
            id: 7,
            countryDesc: 'Germany'
        },
        {
            countryCode: 'DK',
            sort: 3,
            id: 16,
            countryDesc: ''
        },
        {
            countryCode: 'EE',
            sort: 3,
            id: 17,
            countryDesc: ''
        },
        {
            countryCode: 'ES',
            sort: 3,
            id: 8,
            countryDesc: 'Spain'
        },
        {
            countryCode: 'FI',
            sort: 3,
            id: 18,
            countryDesc: ''
        },
        {
            countryCode: 'FR',
            sort: 3,
            id: 9,
            countryDesc: 'France'
        },
        {
            countryCode: 'GB',
            sort: 3,
            id: 14,
            countryDesc: 'Great Britain'
        },
        {
            countryCode: 'GR',
            sort: 3,
            id: 19,
            countryDesc: ''
        },
        {
            countryCode: 'HU',
            sort: 3,
            id: 12,
            countryDesc: 'Hungary'
        },
        {
            countryCode: 'IT',
            sort: 3,
            id: 10,
            countryDesc: 'Italy'
        },
        {
            countryCode: 'LT',
            sort: 3,
            id: 20,
            countryDesc: ''
        },
        {
            countryCode: 'LU',
            sort: 3,
            id: 21,
            countryDesc: ''
        },
        {
            countryCode: 'LV',
            sort: 3,
            id: 22,
            countryDesc: ''
        },
        {
            countryCode: 'NL',
            sort: 3,
            id: 11,
            countryDesc: 'Netherlands'
        },
        {
            countryCode: 'NO', // false,
            sort: 3,
            id: 28,
            countryDesc: 'Norway'
        },
        {
            countryCode: 'PL',
            sort: 3,
            id: 23,
            countryDesc: ''
        },
        {
            countryCode: 'PT',
            sort: 3,
            id: 13,
            countryDesc: 'Portugal'
        },
        {
            countryCode: 'RO',
            sort: 3,
            id: 24,
            countryDesc: ''
        },
        {
            countryCode: 'SE',
            sort: 3,
            id: 25,
            countryDesc: ''
        },
        {
            countryCode: 'SI',
            sort: 3,
            id: 26,
            countryDesc: ''
        },
        {
            countryCode: 'SK',
            sort: 3,
            id: 27,
            countryDesc: ''
        }
    ]
};

const mockResponse_HandlingUnits: Array<HandlingUnit> = [
    {id: '1', description: 'Pallet', apiCode: 'PAL'},
    {id: '2', description: 'Skid', apiCode: 'SKD'},
    {id: '3', description: 'Loose', apiCode: 'LSE'},
    {id: '4', description: 'Other', apiCode: 'OTH'},
    {id: '5', description: 'Gaylord', apiCode: 'GLD'},
    {id: '6', description: 'Master Bundles', apiCode: 'MBL'},
    {id: '7', description: 'Carrier', apiCode: 'CAR'}
];

const mockResponse_NmfcClasses: Array<NmfcClass> = [
    {class: 50, displayClass: 50},
    {class: 55, displayClass: 55},
    {class: 60, displayClass: 60},
    {class: 65, displayClass: 65},
    {class: 70, displayClass: 70},
    {class: 77, displayClass: 77},
    {class: 85, displayClass: 85},
    {class: 92, displayClass: 92},
    {class: 100, displayClass: 100},
    {class: 110, displayClass: 110},
    {class: 125, displayClass: 125},
    {class: 150, displayClass: 150},
    {class: 175, displayClass: 175},
    {class: 200, displayClass: 200},
    {class: 250, displayClass: 250},
    {class: 300, displayClass: 300},
    {class: 400, displayClass: 400},
    {class: 500, displayClass: 500}
];

const mockResponse_PackageTypes: Array<PackageType> = [
    {id: '1', description: 'Carton', apiCode: 'CTN'},
    {id: '2', description: 'Crate', apiCode: 'CRT'},
    {id: '3', description: 'Drum', apiCode: 'DRM'},
    {id: '4', description: 'Bag', apiCode: 'BAG'},
    {id: '5', description: 'Bundle', apiCode: 'BDL'},
    {id: '6', description: 'Pail', apiCode: 'PAL'},
    {id: '7', description: 'Reel', apiCode: 'REL'},
    {id: '8', description: 'Other', apiCode: 'OTH'},
    {id: '9', description: 'Pieces', apiCode: 'PCS'},
    {id: '10', description: 'Barrel', apiCode: 'BRL'},
    {id: '11', description: 'Box', apiCode: 'BOX'},
    {id: '12', description: 'Fedex Box', apiCode: 'FEB'},
    {id: '13', description: 'Fedex Envelope', apiCode: 'FEE'},
    {id: '14', description: 'Fedex Pak', apiCode: 'FEP'},
    {id: '15', description: 'Fedex Tube', apiCode: 'FET'},
    {id: '16', description: 'Gaylord', apiCode: 'GLD'},
    {id: '17', description: 'Tube', apiCode: 'TUB'},
    {id: '19', description: 'Coil', apiCode: 'COI'},
    {id: '20', description: 'Totes', apiCode: ' TOT'},
    {id: '21', description: 'Rolls', apiCode: 'ROL'},
    {id: '22', description: 'Cylinder', apiCode: 'CYL'}
];

const mockResponse_States: ResponseList<string> = {
    count: 0,
    results: []
};

const mockResponse_UnitOfMeasures: Array<UnitOfMeasure> = [
    {id: '3', unitOfMeasure: 'Imperial (US)', unitOfMeasureCode: 'U'},
    {id: '4', unitOfMeasure: 'Metric', unitOfMeasureCode: 'M'}
];

@Injectable()
export class MockMasterDataInterceptor implements HttpInterceptor {

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { // TODO 9/6/17: commented out until made obsolete via refactoring
        if (environment.useMocking.masterData) {
            if (req.url === `${environment.apiBaseUrl}/AppDisplayData` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_AppDisplayData}));

            } else if (req.url === `${environment.apiBaseUrl}/accessorials/accls/4880` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_Accessorials}));

            } else if (req.url === `${environment.apiBaseUrl}/CtsRateEngine/Countries` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_Countries}));

            } else if (req.url === `${environment.apiBaseUrl}/common/class` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_NmfcClasses}));

            } else if (req.url === `${environment.apiBaseUrl}/common/handlingUnits` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_HandlingUnits}));

            } else if (req.url === `${environment.apiBaseUrl}/common/packageTypes` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_PackageTypes}));

            } else if (req.url === `${environment.apiBaseUrl}/MasterData/States` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_States}));

            } else if (req.url === `${environment.apiBaseUrl}/common/unitOfMeasure` && req.method === REQUEST_TYPE_GET) {
                return of(new HttpResponse({body: mockResponse_UnitOfMeasures}));

            }
        }
        return next.handle(req);
    }

}
