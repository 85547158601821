// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    apiBaseUrl: 'https://apidev.shiprite.com/rest',
//    apiBaseUrl: 'http://107.21.174.65/rest',
    production: false,
    useMocking: {
        clipboard: false,
        dashboard: false,
        masterData: false,
        quote: false,
        record: false,
        search: false,
        user: false,
    },
    usingMocking: true,
    showTemplates: true,
};
