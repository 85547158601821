import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from '../services/api.service';

// Mock Data ...
const BOL_bol_ACENTB_1201 = {
    'count': 1,
    'results': [
        {
            'acContact': '',
            'acEmail': '',
            'acFax': '',
            'acPhone': '',
            'acPro_Number': '',
            'actual_carrier_name': '',
            'actual_carrier': 0,
            'actualDeliveryDate': '2017-08-10 12:27:00',
            'actualPickupDate': '2017-08-08 17:32:00',
            'billRec': '',
            'billRecBy': '',
            'billReq': '',
            'billReqBy': '',
            'BillTo': 1,
            'BOL': 813481,
            'BOLEmail': 'NTB@AMERICANCARGOEXPRESS.COM',
            'BOLName': 'American Cargo Express',
            'BOLPhone': 9083513400,
            'cancel_batch': '',
            'cancel_confirm': 'N',
            'Carrier_Name': 'A DUIE PYLE INC',
            'Carrier': 'PYLE',
            'carrierQuoteNo': '',
            'COD': 0,
            'Comments': 'ACK#16505',
            'consignee_address': '235 HERITAGE AVE',
            'consignee_address2': '',
            'consignee_city': 'Portsmouth',
            'consignee_contact_email': '',
            'consignee_contact_fax': 6035555555,
            'consignee_contact_name': 'RONNY SANBORN',
            'consignee_contact_phone': 6035310229,
            'consignee_country': 'US',
            'consignee_hours': '',
            'Consignee_Name': 'COAST TO COAST',
            'consignee_name2': '',
            'consignee_state': 'NH',
            'consignee_zip': '03801',
            'Consignee': 0,
            'consigneeCallAppt': 0,
            'consigneeCommercialResidential': 'C',
            'consigneeHoursClose': '17:00:00',
            'consigneeHoursOpen': '08:00:00',
            'consigneeTimezone': 'E',
            'Container_No': '',
            'created': '2017-08-08 11:25:23',
            'currency': 'USD',
            'Customer': 'ACENTB',
            'd_appt_date': '',
            'd_appt_time': '',
            'd_appt_tz': '',
            'Delivery_Date': '2017-08-10',
            'dest_terminal': 'MAS00',
            'dispatcher': 0,
            'dterm800': '',
            'dtermAddress': '210 BARTLETT STREET',
            'dtermAddress2': '',
            'dtermCity': 'NORTHBOROUGH',
            'dtermFax': '',
            'dtermName': 'NORTHBOROUGH  MA',
            'dtermPhone': '800-523-5020',
            'dtermState': 'MA',
            'dtermZip': '01532',
            'edi_batch': '',
            'edi_confirm': 'N',
            'emergency_contact': '',
            'emergency_fax': '',
            'emergency_phone': '',
            'equipmentType': 0,
            'estimatedTransitTime': 1,
            'fedexDirectSignatureRequired': 0,
            'fedexDropOffType': '',
            'fedexEmailNotification': 0,
            'fedexEmailNotificationAddress': '',
            'forwarder_ref': '',
            'FSC_buy': 12.63,
            'FSC': 12.63,
            'GL_Code': 189856018,
            'GL_Code2': '',
            'guarantee': 0,
            'ID': 1201,
            'IT_No': '',
            'lastnote': '2017-08-22 13:26:19',
            'loadNumber': 0,
            'location_code': '',
            'lockAmounts': 0,
            'matchedEDI': '',
            'matchedToEDI': 0,
            'MBOL': '',
            'miles': 281,
            'Net_amt': 110.15,
            'Net_FSC': 6.23,
            'orig_terminal': 'NJC00',
            'oterm800': '',
            'otermAddress': '5000 INDUSTRIAL ROAD',
            'otermAddress2': '',
            'otermCity': 'CARTERET',
            'otermFax': '',
            'otermName': 'CARTERET  NJ',
            'otermPhone': '800-523-5020',
            'otermState': 'NJ',
            'otermZip': '07008',
            'OutsideID': '',
            'p_appt_date': '',
            'p_appt_time': '',
            'p_appt_tz': '',
            'Payment_Term': 3,
            'Pickup_Date': '2017-08-08',
            'Pickup_Time': '',
            'Pickup_Timezone': '',
            'Pro_Number': 386273569,
            'problem': 0,
            'PU_No': '',
            'quoteNumber': '',
            'rated_amt': 132.12,
            'rated_buy': 132.12,
            'ratedClass': 0,
            'ReferenceNumber': '',
            'scheduled': '2017-08-08 11:30:16',
            'sealNumber': '',
            'service_carrier_code': '',
            'service_name': 'LTL',
            'Service': 60,
            'shipper_address': '2345 Vauxhall Rd',
            'shipper_address2': '',
            'shipper_city': 'Union ',
            'shipper_contact_email': '',
            'shipper_contact_fax': 9086323097,
            'shipper_contact_name': 'Receiving',
            'shipper_contact_phone': '908-351-3400',
            'shipper_country': 'US',
            'shipper_hours': '',
            'Shipper_Name': 'American Cargo Express',
            'shipper_name2': '',
            'shipper_ref': '',
            'shipper_state': 'NJ',
            'shipper_zip': '07083',
            'Shipper': 3,
            'shipperCallAppt': 0,
            'shipperCommercialResidential': 'C',
            'shipperHoursClose': '17:00:00',
            'shipperHoursOpen': '08:00:00',
            'shipperTimezone': 'E',
            'signature': 'Ron',
            'status': 14,
            'submitted': '2017-08-08 11:18:00',
            'Third_Party': 0,
            'trailerNo': '',
            'unitmeasurement': 'U',
            'User': 7267
        }
    ]
};

const Shipment_Carrier_PYLE = {
    'ID': 6,
    'Active': 1,
    'Address1': '',
    'Address2': '',
    'City': '',
    'Contact': '',
    'Customer': 'ACENTB',
    'Email': '',
    'Fax': '',
    'Name': 'A DUIE PYLE INC',
    'Phone': '',
    'SCAC': 'PYLE',
    'SR': 'Y',
    'srxDedCarrier': 0,
    'State': '',
    'Zip': '',
};

@Injectable()
export class MockRecordInterceptor implements HttpInterceptor {

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.useMocking.record) {
            if (req.method === REQUEST_TYPE_GET && req.url === `${environment.apiBaseUrl}/BOL/bol/ACENTB/1201`) {
                return of(new HttpResponse({body: BOL_bol_ACENTB_1201}));
            }
        }
        return next.handle(req);
    }
}
