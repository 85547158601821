import {Injectable} from "@angular/core";
import {ApiService, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from "./api.service";
import {InvoiceSummaryReportRequest} from "../models/invoiceSummaryReportRequest";
import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import {ResponseDTO} from "../models/responseDto";
import {InvoiceSummaryReportResponse} from "../models/invoiceSummaryReportResponse";
import {InvoiceDetailReportRequest} from "../models/invoiceDetailReportRequest";
import {InvoiceDetailReportResponse} from "../models/invoiceDetailReportResponse";
import {ReportDropdownTypeSet} from "../models/reportDropdownTypeSet";
import {ShipmentSummaryReportRequest} from "../models/shipmentSummaryReportRequest";
import {ShipmentSummaryReportResponse} from "../models/shipmentSummaryReportResponse";
import {ShipmentDetailReportRequest} from "../models/shipmentDetailReportRequest";
import {ShipmentDetailReportResponse} from "../models/shipmentDetailReportResponse";
import {SignalsService} from "./signals.service";
import {NotificationService} from "./notification.service";

@Injectable()
export class ReportingService {
    constructor(
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) { }

    // =====================================================================================================================
    // ========================================= INVOICE REPORT METHODS ===========================================
    // =====================================================================================================================
    public getInvoiceSummaryReports(invoiceSummaryReportRequest: InvoiceSummaryReportRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Reports/InvoiceSummary`, invoiceSummaryReportRequest);
        this._api.callApiService<ResponseDTO<InvoiceSummaryReportResponse>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    const invoiceSummaryReports: InvoiceSummaryReportResponse = response.dto;
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), invoiceSummaryReports });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Reporting - Retrieve Invoice Summary' })
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Reporting - Retrieve Invoice Summary' });
            });
    }

    public getInvoiceDetailReport(invoiceDetailReportRequest: InvoiceDetailReportRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Reports/InvoiceDetail`, invoiceDetailReportRequest);
        this._api.callApiService<ResponseDTO<InvoiceDetailReportResponse>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    const invoiceDetailReports: InvoiceDetailReportResponse = response.dto;
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), invoiceDetailReports });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Reporting - Retrieve Invoice Detail' })
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Reporting - Retrieve Invoice Detail' });
            });
    }

    public getInvoiceReportDateTypes(): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Reports/InvoiceDateTypes`);
        this._api.callApiService<ResponseDTO<Array<ReportDropdownTypeSet>>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), invoiceReportDateTypes: response.dto })
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Reports - Get Invoice Date Types` });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Reports - Get Invoice Date Types` });
            }
        )
    }

    public getInvoiceReportGroupTypes(): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Reports/InvoiceGroupTypes`);
        this._api.callApiService<ResponseDTO<Array<ReportDropdownTypeSet>>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), invoiceReportGroupTypes: response.dto });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Reports - Get Invoice Group Types` });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Reports - Get Invoice Group Types` });
            }
        )
    }

    public stashInvoiceSummaryReportParams(invoiceSummaryReportRequest: InvoiceSummaryReportRequest): void {
        this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), invoiceSummaryReportRequest })
    }

    // =====================================================================================================================
    // ========================================= SHIPMENT REPORT METHODS ===========================================
    // =====================================================================================================================

    public getShipmentSummaryReport(shipmentSummaryReportRequest: ShipmentSummaryReportRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Reports/ShipmentSummary`, shipmentSummaryReportRequest);
        this._api.callApiService<ResponseDTO<ShipmentSummaryReportResponse>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    const shipmentSummaryReports: ShipmentSummaryReportResponse = response.dto;
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), shipmentSummaryReports });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Reporting - Retrieve Shipment Summary' })
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Reporting - Retrieve Shipment Summary' });
            });
    }

    public getShipmentDetailReport(shipmentDetailReportRequest: ShipmentDetailReportRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Reports/ShipmentDetail`, shipmentDetailReportRequest);
        this._api.callApiService<ResponseDTO<ShipmentDetailReportResponse>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    const shipmentDetailReports: ShipmentDetailReportResponse = response.dto;
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), shipmentDetailReports });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Reporting - Retrieve Shipment Summary' })
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Reporting - Retrieve Shipment Summary' });
            });

    }

    public getShipmentReportDateTypes(): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Reports/ShipmentDateTypes`);
        this._api.callApiService<ResponseDTO<Array<ReportDropdownTypeSet>>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), shipmentReportDateTypes: response.dto });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Reports - Get Shipment Date Types` });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Reports - Get Shipment Date Types` });
            }
        )
    }

    public getShipmentReportGroupTypes(): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Reports/ShipmentGroupTypes`);
        this._api.callApiService<ResponseDTO<Array<ReportDropdownTypeSet>>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), shipmentReportGroupTypes: response.dto });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Reports - Get Shipment Group Types` });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Reports - Get Shipment Group Types` });
            }
        )
    }

    public stashShipmentSummaryReportParams(shipmentSummaryReportRequest: ShipmentSummaryReportRequest): void {
        this._signalsService.reportingSignal.set({ ...this._signalsService.reportingSignal(), shipmentSummaryReportRequest });
    }
}
