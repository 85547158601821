import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import {Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import * as Constants from '../../../constants/constants';
import * as DateConstants from '../../../constants/datetime.constants';
import * as Defaults from '../../../constants/defaults';
import * as MasterDataConstants from '../../../constants/masterData.constants';
import * as SearchConstants from '../../../constants/searchCriteria';
import { UserHelper } from '../../../helpers/userHelper';
import {unsubscribe} from '../../../helpers/utilities';
import { ContactSearchResult } from '../../../models/contact.searchResult';
import { Country } from '../../../models/country';
import { CustomerMasterData } from '../../../models/customer.masterData';
import { MarkupRateTierOption } from '../../../models/markupRateTierOption';
import { MasterDataContractID } from '../../../models/masterDataContractID';
import { NmfcClass } from '../../../models/nmfcClass';
import { RoleDetailMasterData } from '../../../models/roleDetail.masterData';
import { RoleType } from '../../../models/roleDetailRoleType';
import { SearchRequest } from '../../../models/searchRequest';
import {CreateTemplateRequest} from '../../../models/template';
import { UnitOfMeasure } from '../../../models/unitOfMeasure';
import { ZipSearchResult } from '../../../models/zip.searchResult';
import {CommonDataService} from '../../../services/commonData.service';
import {CustomerService} from '../../../services/customer.service';
import {RoleService} from '../../../services/role.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import { AdminButtonPanelService } from 'app/services/adminButtonPanel.service';
import {RadioValue} from "../../../models/radioValue";
import {SearchService} from "../../../services/search.service";
import {TemplateService} from "../../../services/template.service";
import { NotificationService } from 'app/services/notification.service';

@Component({
    selector: 'app-admin-role',
    styleUrls: ['./admin-role.component.scss'],
    templateUrl: './admin-role.component.html',
})
export class AdminRoleComponent implements OnInit, OnDestroy {
    public currentTsaValue: any;

    public masterDataContractIdList: Array<MasterDataContractID> = [];
    public roleTypes: Array<RoleType>;
    public masterDataCustomersList: Array<CustomerMasterData> = [];
    public roleTabSections: {
        general: string,
        permissions: string,
        quoting: string,
        shipments: string
        defaultsAndDisclaimer: string
    } = MasterDataConstants.ROLE_TAB_SECTIONS;
    public activeRoleTabSection: string = this.roleTabSections.general;
    public permissionDetailTypes = MasterDataConstants.ROLE_PERMISSION_DETAIL_TYPES;
    public permissionDetailValues = MasterDataConstants.ROLE_PERMISSION_DETAIL_VALUES;
    public permissionDetailFields = MasterDataConstants.ROLE_PERMISSION_DETAIL_FIELDS;

    public roleDetailForm: UntypedFormGroup;
    public isDatePickerShown: boolean = false;
    public isSingleDatePickerShown: boolean = false;
    public rateTierDisplayDateFormat: string = DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES;
    public rateTierDisplayDateValidation: RegExp = DateConstants.VALID_DATE_REGEX_MM_DD_YYYY_Slash;
    public defaultDateValue: string = moment().format(this.rateTierDisplayDateFormat);
    public selectedRateTierFormGroup: UntypedFormGroup;
    public nmfcClasses: Array<NmfcClass>;
    public isSelectContactModalShown: boolean = false;
    public isSolicitTemplateNameModalShown = false;
    public contactInput: string;
    public contactField: string;
    public contactType: string;
    public contactShipper = SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER;
    public contactConsignee = SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE;
    public thirdPartyBillToDDL: Array<ContactSearchResult>;
    public roleCustomerBillToContacts: Array<ContactSearchResult> = [];
    public thirdPartyBillToInfo: ContactSearchResult;
    public thirdPartyBillToSelected: number;
    public displayStyleOptions: Array<string> = [Constants.RecordDisplayStyle_Accordion, Constants.RecordDisplayStyle_Wizard];
    public recordCreationTabOptions: Array<string> = [
        Constants.RECORD_SECTIONS.Quote,
        Constants.RECORD_SECTIONS.GeneralRef,
        Constants.RECORD_SECTIONS.Shipper,
        Constants.RECORD_SECTIONS.Consignee,
        Constants.RECORD_SECTIONS.ProductList,
        Constants.RECORD_SECTIONS.CarrierRef,
        Constants.RECORD_SECTIONS.Invoicing,
        Constants.RECORD_SECTIONS.AttachedFiles,
        Constants.RECORD_SECTIONS.Summary
    ];
    public isUserPermissionsExpanded: boolean = false;
    public isGroupPermissionsExpanded: boolean = false;
    public isGlobalPermissionsExpanded: boolean = false;
    public countries: Array<Country>;
    public unitOfMeasures: Array<UnitOfMeasure> = [];
    public selectedUnitOfMeasureCode: string = Defaults.DEFAULT_UNIT_OF_MEASURE;
    public zipSearchModalType: string;
    public isZipSearchModalShown: boolean;
    public paymentTerms: { prepaid: string, collect: string, thirdParty: string } = {
        prepaid: Constants.PAYMENT_TERMS_Prepaid,
        collect: Constants.PAYMENT_TERMS_Collect,
        thirdParty: Constants.PAYMENT_TERMS_ThirdParty,
    };
    public autoClearShipmentsOptions: Array<number> = Constants.AUTOCLEAR_SHIPMENT_OPTIONS;
    public isAdminExternal: boolean;
    public isAdminInternal: boolean;

    //  ================== RATE TIERS CLASS PROPERTIES ==================
    public areHistoricalRateTiersDisplayed: boolean = false;
    public isRateMarkupSearchModalShown: boolean = false;
    public isFuelMarkupSearchModalShown: boolean = false;
    public isAcclMarkupSearchModalShown = false;
    public markupRateTierOptions: Array<MarkupRateTierOption> = [];
    public isDisplayTierSelectionWarningModalVisible: boolean = false;
    public isGapsExistWarningModalVisible: boolean = false;
    public isTierThreeNeedsTwoWarningModalVisible = false;
    public isCustomerRolesModalShown: boolean = false;
    public selectedCustomerName: string = '';

    public isLoseChangesWarningModalVisible = null;
    public okPayload;
    public searchConstants = SearchConstants;
    public yesNoRadioValues: Array<RadioValue> = [{value: false, label: 'No'},{value: true, label: 'Yes'}];

    private roleCustomerValueChangeSubscription: any;
    private executeShipmentSubscription: any;
    private roleFormChangeSubscription: Subscription;
    private routeSubscription: Subscription;

    private roleId;
    private permissionTypes = {
        user: 'userPermissions',
        group: 'groupPermissions',
        global: 'globalPermissions',
    };
    private selectedContactFormGroup: UntypedFormGroup = null;
    private selectedRateTier: UntypedFormGroup;
    private rateTierFuelRateOptions: Array<MarkupRateTierOption> = [];
    private acclOptions = [];

    constructor(
        private _notificationService: NotificationService,
        private _searchService: SearchService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _userHelper: UserHelper,
        private _breadcrumbService: BreadcrumbService,
        private _adminButtonPanelService: AdminButtonPanelService,
        private _templateService: TemplateService,
        private _roleService: RoleService,
        private _commonDataService: CommonDataService,
        private _customerService: CustomerService,
    ) { }

    public ngOnInit() {
        this.roleTypes = this._commonDataService.roleTypes;
        this.nmfcClasses = this._commonDataService.nmfcClasses;
        this.countries = this._commonDataService.countries;
        this.unitOfMeasures = this._commonDataService.unitOfMeasures;
        this.rateTierFuelRateOptions = this._commonDataService.fuelOptions;
        this.acclOptions = this._commonDataService.acclOptions;
        this.markupRateTierOptions = this._commonDataService.rateOptions;
        this.masterDataContractIdList = this._commonDataService.contractIds;

        this.masterDataCustomersList = this._customerService.customers;

        if (this.masterDataContractIdList.length === 0) {
            this._commonDataService.getContractIDs().subscribe();
        }

        this.setupButtonPanel();

        this.routeSubscription = this._route.params.pipe(
            map((params) => {
                this.roleId = params['roleId'];
                this._roleService.getRoleByPath(this.roleId).pipe(
                    map(async (role: RoleDetailMasterData) => {
                        this.roleDetailForm = this._roleService.getRoleFormFromLastRole();

                        this.roleCustomerValueChangeSubscription = this.roleDetailForm.get('roleCustomer').valueChanges.subscribe((custNo: string) => {
                            this.getBillToContacts(custNo);
                            this.setCustomerName();
                        });
                        this.setCustomerName();

                        this.executeShipmentSubscription = this.roleDetailForm.get('executeShipment').valueChanges.subscribe((executeShipmentValue) => {
                            if (executeShipmentValue && !this.roleDetailForm.get('placeToPrintBOL').value) {
                                this.roleDetailForm.get('placeToPrintBOL').setValue(true);
                            }
                        });

                        if (role.roleCustomer) {
                            this._commonDataService.getRoleBillToContacts(role.roleCustomer).pipe(
                                map(list => {
                                    this.thirdPartyBillToDDL = list as Array<ContactSearchResult>;
                                })
                            ).subscribe();
                            this.getBillToContacts(role.roleCustomer);
                        }

                        this.setBreadcrumb(this.roleId);
                        unsubscribe(this.roleFormChangeSubscription);
                        this.activateButtons();
                        this.roleFormChangeSubscription = this.roleDetailForm.valueChanges.subscribe(() => {
                            this.activateButtons();
                        })
                    })
                ).subscribe();
            })
        ).subscribe();

        this.isAdminExternal = this._userHelper.isAdminExternal(); // using to direct hidden/read only fields in UI
        this.isAdminInternal = this._userHelper.isAdminInternal();
    }

    public ngOnDestroy() {
        unsubscribe(this.roleFormChangeSubscription);
        unsubscribe(this.roleCustomerValueChangeSubscription);
        unsubscribe(this.executeShipmentSubscription);
        this._adminButtonPanelService.showSaveCancelButtons(false);
        this._adminButtonPanelService.showCustomButton(false);
    }

    public isNewRole() {
        return !this.roleId || (this.roleId === 'new');
    }

    public saveChanges(bypassDisplayTierWarning = false, bypassGapWarning = false, bypassTierDepenencyWarning = false): void {
        if (!bypassDisplayTierWarning && this.checkIfDisplayTierSelectionRequired()) {
            this.isDisplayTierSelectionWarningModalVisible = true;
            return;
        } else {
            this.isDisplayTierSelectionWarningModalVisible = false;
        }

        if (!bypassGapWarning && (this._roleService.tierGapsExist(this.roleDetailForm.get('levelTwoRateTiers') as UntypedFormArray) ||
            this._roleService.tierGapsExist(this.roleDetailForm.get('levelThreeRateTiers') as UntypedFormArray))) {
            this.isGapsExistWarningModalVisible = true;
            return;
        } else {
            this.isGapsExistWarningModalVisible = false;
        }

        if (!bypassTierDepenencyWarning && !this.tierThreeHasTierTwoDependency()) {
            this.isTierThreeNeedsTwoWarningModalVisible = true;
            return;
        } else {
            this.isTierThreeNeedsTwoWarningModalVisible = false;
        }

        this._adminButtonPanelService.activateSaveButton(false);
        const roleDetail: RoleDetailMasterData = this._roleService.getRoleFromForm(this.roleDetailForm);

        if (this.isNewRole()) {
            this._roleService.createRole(roleDetail).pipe(
                map((createdRole: RoleDetailMasterData) => {
                    this._breadcrumbService.popBreadcrumb();
                    this._roleService.getRolesList().subscribe();
                })
            ).subscribe();
        } else {
            this._roleService.updateRole(roleDetail).pipe(
                map(role => {
                    this._roleService.getRolesList().subscribe();
                    this._roleService.updateLastRole(role);
                    this._roleService.updateForm(this.roleDetailForm);
                })
            ).subscribe();
        }

        if (this.isDisplayTierSelectionWarningModalVisible) {
            this.isDisplayTierSelectionWarningModalVisible = !this.isDisplayTierSelectionWarningModalVisible;
        }
    }

    // ======================================= RATE TIERS METHODS =======================================
    public isActiveTier(rateTier) {
        return this._roleService.isActiveTier(rateTier);
    }

    public isHistoricalTier(rateTier) {
        return this._roleService.isHistoricalTier(rateTier);
    }

    public isValidTier(rateTier) {
        return this._roleService.isValidTier(rateTier);
    }

    public isNewTier(rateTierControl) {
        return this._roleService.isNewTier(rateTierControl);
    }

    public visibleLineExists(tierFormArrayName) {
        const tierFormArray = this.roleDetailForm.get(tierFormArrayName) as UntypedFormArray;
        let tierFormControl;

        for (tierFormControl of tierFormArray.controls) {
            if (!tierFormControl.value.history || this.areHistoricalRateTiersDisplayed) {
                return true;
            }
        }
        return false;
    }

    public nonHistoricalExists(tierFormArrayName, validityCheck = true) {
        const tierFormArray = this.roleDetailForm.get(tierFormArrayName) as UntypedFormArray;
        let tierFormControl;

        for (tierFormControl of tierFormArray.controls) {
            if ((this._roleService.isValidTier(tierFormControl) || !validityCheck) && !this._roleService.isHistoricalTier(tierFormControl)) {
                return true;
            }
        }
        return false;
    }

    public tierThreeHasTierTwoDependency(tierFormControl?, findMore = 0) {
        let currentTierFormControl;
        let nonHistoricalCount = 0;

        if (tierFormControl && !this._roleService.isNewTier(tierFormControl)) {
            return false;
        }

        if (!this.nonHistoricalExists('levelThreeRateTiers', false)) {
            return true;
        }

        const tierFormArray = this.roleDetailForm.get('levelTwoRateTiers') as UntypedFormArray;
        for (currentTierFormControl of tierFormArray.controls) {
            if (!this._roleService.isHistoricalTier(currentTierFormControl)) {
                nonHistoricalCount++;
            }
            if (nonHistoricalCount > findMore) {
                return true;
            }
        }

        return false;
    }

    public addRateTierFormLine(rateTiersArrayName): void {
        const tierOrder = (rateTiersArrayName === 'levelTwoRateTiers') ? 2 : 3;
        this._roleService.addNewTier(this.roleDetailForm['controls'][rateTiersArrayName] as UntypedFormArray, tierOrder);
    }

    public removeRateTier(lineItemIndex: number, rateTiersArrayName): void {
        const rateTiersFormArray = this.roleDetailForm.get(rateTiersArrayName) as UntypedFormArray;
        rateTiersFormArray.removeAt(lineItemIndex);
    }

    public shouldDisplayMarkupLinkBeVisible(): boolean {
        const levelTwoRateTierFormArray: UntypedFormArray = this.roleDetailForm.get('levelTwoRateTiers') as UntypedFormArray;
        const levelThreeRateTierFormArray: UntypedFormArray = this.roleDetailForm.get('levelThreeRateTiers') as UntypedFormArray;

        return !!(levelTwoRateTierFormArray.controls.filter((rt: AbstractControl) => rt.value.history).length) ||
            !!(levelThreeRateTierFormArray.controls.filter((rt: AbstractControl) => rt.value.history).length);
    }

    public showRateMarkupSearchModal(rateTier: UntypedFormGroup): void {
        this.selectedRateTier = rateTier;
        this.isRateMarkupSearchModalShown = true;
    }

    public showAcclMarkupSearchModal(rateTier: UntypedFormGroup): void {
        this.selectedRateTier = rateTier;
        this.isAcclMarkupSearchModalShown = true;
    }

    public showFuelMarkupSearchModal(rateTier: UntypedFormGroup): void {
        this.selectedRateTier = rateTier;
        this.isFuelMarkupSearchModalShown = true;
    }

    public handleSelectedAcclMarkupOption(acclMarkupOption: MarkupRateTierOption): void {
        if (!acclMarkupOption || !this.selectedRateTier) {
            return;
        }

        this.selectedRateTier.get('acclMarkupID').setValue(acclMarkupOption.markupID);
        this.isAcclMarkupSearchModalShown = false;
        this.selectedRateTier = null;
    }

    public handleSelectedRateMarkupOption(rateMarkupOption: MarkupRateTierOption): void {
        if (!rateMarkupOption || !this.selectedRateTier) {
            return;
        }

        this.selectedRateTier.get('rateMarkupID').setValue(rateMarkupOption.markupID);
        this.isRateMarkupSearchModalShown = false;
        this.selectedRateTier = null;
    }

    public handleSelectedFuelMarkupOption(fuelMarkupOption: MarkupRateTierOption): void {
        if (!fuelMarkupOption || !this.selectedRateTier) {
            return;
        }

        this.selectedRateTier.get('fuelMarkupID').setValue(fuelMarkupOption.markupID);
        this.isFuelMarkupSearchModalShown = false;
        this.selectedRateTier = null;
    }

    public getAcclMarkupName(acclMarkupID: number) {
        if (!this.acclOptions || !this.acclOptions.length || !acclMarkupID) {
            return;
        }

        const matchedAccl = this.acclOptions.find((option) => option.markupID === acclMarkupID);
        return matchedAccl ? matchedAccl.markupName : '';
    }

    public getFuelMarkupName(fuelMarkupId: number): string {
        if (!this.rateTierFuelRateOptions || !this.rateTierFuelRateOptions.length || !fuelMarkupId) {
            return;
        }

        const matchedOption: MarkupRateTierOption = this.rateTierFuelRateOptions.find((option: MarkupRateTierOption) => option.markupID === fuelMarkupId);
        return matchedOption ? matchedOption.markupName : '';
    }

    public getRateMarkupName(rateMarkupId: number): string {
        if (!this.markupRateTierOptions || !this.markupRateTierOptions.length || !rateMarkupId) {
            return;
        }
        const matchedOption: MarkupRateTierOption = this.markupRateTierOptions.find((option: MarkupRateTierOption) => option.markupID === rateMarkupId);
        return matchedOption ? matchedOption.markupName : '';
    }

    public removeRateMarkupId(event: Event, rateTier): void {
        event.stopPropagation();

        const rtMarkupIdValue = rateTier.get('rateMarkupID').value;
        rateTier.get('rateMarkupID').setValue(0);
        this.getRateMarkupName(null);
    }

    public removeAcclMarkupId(event: Event, rateTier): void {
        event.stopPropagation();

        const rtMarkupIdValue = rateTier.get('acclMarkupID').value;
        rateTier.get('acclMarkupID').setValue(0);
        this.getRateMarkupName(null);
    }

    public removeFuelMarkupId(event: Event, rateTier): void {
        event.stopPropagation();

        const rtMarkupIdValue = rateTier.get('fuelMarkupID').value;
        rateTier.get('fuelMarkupID').setValue(0);
        this.getRateMarkupName(null);
    }

    public toggleRateTierDisplay(rateTierLineItem: UntypedFormGroup): void {
        if (!rateTierLineItem || !rateTierLineItem.get('displayTier') || !this._roleService.isNewTier(rateTierLineItem)) {
            return;
        }

        const displayTierFormControl: UntypedFormControl = rateTierLineItem.get('displayTier') as UntypedFormControl;
        displayTierFormControl.setValue(!displayTierFormControl.value);
    }

    // ======================================= Date Picker Methods =======================================
    public showSingleDatePicker(rateTierFormGroup: UntypedFormGroup): void {
        if (!rateTierFormGroup) {
            return;
        }

        this.selectedRateTierFormGroup = rateTierFormGroup;
        this.isSingleDatePickerShown = true;
    }

    public hideSingleDatePicker({ selectedDate }) {
        if (selectedDate) {
            this.selectedRateTierFormGroup.get('end').setValue(selectedDate);
        }

        this.isSingleDatePickerShown = false;
    }

    public showDatePicker(rateTierFormGroup: UntypedFormGroup): void {
        if (!rateTierFormGroup) {
            return;
        }

        this.selectedRateTierFormGroup = rateTierFormGroup;
        this.isDatePickerShown = true;
    }

    public hideDatePicker({ selectedDate, selectedEndDate }: { selectedDate: string, selectedEndDate?: string }): void {
        if (selectedDate) {
            this.selectedRateTierFormGroup.get('effective').setValue(selectedDate);
        }

        if (selectedEndDate) {
            this.selectedRateTierFormGroup.get('end').setValue(selectedEndDate);
        }

        this.isDatePickerShown = false;
    }

    // ======================================= UI CHANGE METHODS =======================================
    public setCustomerName(): void {
        if (!this.roleDetailForm || !this.roleDetailForm.get('roleCustomer') || !this.roleDetailForm.get('roleCustomer').value) {
            this.selectedCustomerName = '';
            return;
        }

        const customerId = this.roleDetailForm.get('roleCustomer').value;

        const matchedOption: CustomerMasterData = this.masterDataCustomersList.find((option: CustomerMasterData) => option.custNo === customerId);
        this.selectedCustomerName = matchedOption ? matchedOption.custName : '';
    }

    // ======================================= PERMISSION METHODS =======================================
    public setPermissionToViewIfNone(permissionType: string, selectedPermissionField: string) {
        const permissionTypeFormGroup: UntypedFormGroup = this.roleDetailForm.get(this.permissionTypes[permissionType]) as UntypedFormGroup;

        const selectedPermissionFieldFormControl: UntypedFormControl = permissionTypeFormGroup.get(selectedPermissionField) as UntypedFormControl;
        if (selectedPermissionFieldFormControl.value === this.permissionDetailValues.NONE) {
            this.setPermissionValue(permissionType, selectedPermissionField, this.permissionDetailValues.VIEW);
        }
    }

    public isViewOrEdit(permissionType, permission) {
        const permissionFormGroup: UntypedFormGroup = this.roleDetailForm.get(this.permissionTypes[permissionType]) as UntypedFormGroup;

        const permissionControl: UntypedFormControl = permissionFormGroup.get(permission) as UntypedFormControl;
        return (permissionControl.value === this.permissionDetailValues.EDIT) ||
            (permissionControl.value === this.permissionDetailValues.VIEW);
    }

    public setPermissionValue(permissionType: string, permission: string, value: string): void {
        const permissionFormGroup: UntypedFormGroup = this.roleDetailForm.get(this.permissionTypes[permissionType]) as UntypedFormGroup;

        const permissionFormControl: UntypedFormControl = permissionFormGroup.get(permission) as UntypedFormControl;

        switch (permission) {
            case this.permissionDetailFields.QUOTES:
            case this.permissionDetailFields.SHIPMENTS:
                if ((value === this.permissionDetailValues.EDIT) || (value === this.permissionDetailValues.VIEW)) {
                    this.setPermissionToViewIfNone(permissionType, this.permissionDetailFields.PRODUCTS);
                    this.setPermissionToViewIfNone(permissionType, this.permissionDetailFields.CARRIER);
                    if (permission === this.permissionDetailFields.SHIPMENTS) {
                        this.setPermissionToViewIfNone(permissionType, this.permissionDetailFields.CONTACTS);
                    }
                }
                break;
        }

        permissionFormControl.setValue(value);
    }

    // ======================================= QUOTE =======================================
    public showContactModal(contactType) {
        this.contactType = contactType;
        this.selectedContactFormGroup = this.roleDetailForm.get((contactType === SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE) ? 'defaultConsignee' : 'defaultShipper') as UntypedFormGroup;
        this.contactInput = this.selectedContactFormGroup.get('defaultContactDisplay').value;

        this.isSelectContactModalShown = true;
    }

    public hideContactModal(contactSearchResult: ContactSearchResult): void {
        this.isSelectContactModalShown = false;

        if (!contactSearchResult) {
            return;
        }

        this.selectedContactFormGroup.setValue({
            defaultContactDisplay: `${contactSearchResult.name}, ${contactSearchResult.zip}`,
            defaultContactId: contactSearchResult.contactsid + ''
        });
    }

    public showZipSearchModal(zipType: string): void {
        this.zipSearchModalType = zipType;
        this.isZipSearchModalShown = !this.isZipSearchModalShown;
    }

    public hideZipSearchModal(selectedZipResult: ZipSearchResult): void {
        if (selectedZipResult) {
            if (this.zipSearchModalType === 'origin') {
                this.roleDetailForm.controls.defaultOriginZip.setValue(selectedZipResult.zip_code);
                this.roleDetailForm.controls.defaultOriginCountry.setValue(selectedZipResult.countryname);
            } else {
                this.roleDetailForm.controls.defaultDestZip.setValue(selectedZipResult.zip_code);
                this.roleDetailForm.controls.defaultDestCountry.setValue(selectedZipResult.countryname);
            }
        }
        this.zipSearchModalType = '';
        this.isZipSearchModalShown = false;
    }

    // ======================== TEMPLATE METHODS ========================
    public closeSolicitTemplateNameModal(templateName: string): void {
        if (templateName) {
            // create and save a Role-type template using name provided
            const roleData: RoleDetailMasterData = this._roleService.getRoleFromForm(this.roleDetailForm);
            const roleTemplate = this._templateService.prepareRoleTemplate(roleData);
            const templateReq: CreateTemplateRequest = {
                templateType: MasterDataConstants.TEMPLATE_TYPES_role,
                templateName,
                template: roleTemplate,
                templateGroup: '?'
            };
            this._templateService.createUserTemplate(templateReq).pipe(
                switchMap(() => this._templateService.getUserTemplates())
            ).subscribe();
        }
        this.isSolicitTemplateNameModalShown = false;
    }

    public goToMarkup(id?) {
        if (!this.isLoseChangesWarningModalVisible && this.roleDetailForm.dirty) {
            this.okPayload = id;
            this.isLoseChangesWarningModalVisible = true;
        } else {
            this.isLoseChangesWarningModalVisible = false;
            this._router.navigate(['admin/markups', id]);
        }
    }

    private activateButtons() {
        if (this.roleId && this._roleService.formEqualsLastRole(this.roleDetailForm)) {
            this.roleDetailForm.markAsPristine();
        } else {
            this.roleDetailForm.markAsDirty();
        }

        this._adminButtonPanelService.activateSaveButton(this.roleDetailForm.dirty && this.roleDetailForm.valid);
    }

    private setBreadcrumb(id) {
        let breadcrumbLabel;
        const breadcrumbUrl = 'admin/roles/' + id;

        switch (id) {
            case 'new':
                breadcrumbLabel = 'Create Role';
                break;
            default:
                breadcrumbLabel = this.roleDetailForm.controls.roleName.value;
        }
        this._breadcrumbService.addOrUpdateBreadcrumb({label: breadcrumbLabel, urlValue: breadcrumbUrl});
    }

    private setupButtonPanel() {
        this._adminButtonPanelService.showSaveCancelButtons(true);
        this._adminButtonPanelService.activateSaveButton(false);
        // TODO: Turn on custom button (Save as Template) when functionality is working:
        this._adminButtonPanelService.showCustomButton(false);
        this._adminButtonPanelService.setCustomButtonIcon('fa-floppy-o');
        this._adminButtonPanelService.setCustomButtonLabel('Save As Template');

        this._adminButtonPanelService.register({
            saveEvent: () => {
                this.saveChanges();
            },
            cancelEvent: () => {
                this.lastRoleToForm();
            },
            customEvent: () => {
                this.isSolicitTemplateNameModalShown = true;
            },
        });
    }

    private lastRoleToForm() {
        this._roleService.updateForm(this.roleDetailForm);
        this.roleDetailForm.markAsPristine();
    }

    // ======================================= SETUP METHODS =======================================
    private getBillToContacts(custNo): void {
        if (!custNo) {
            return;
        }

        const billToCustomerSearchObject: SearchRequest = {
            searchCriteria: [
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_CUSTOMER,
                    value: custNo,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                },
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_BILL_TO,
                    value: '1',
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                }
            ]
        };
        this._searchService.processContactSearch(billToCustomerSearchObject)
            .subscribe(
                (response) => {
                    if (response && response.isValid && response.dto && response.dto.searchResults) {
                        if (response.dto.searchResults.length && response.dto.searchResults[0].entities && response.dto.searchResults[0].entities.length) {
                            this.roleCustomerBillToContacts = response.dto.searchResults[0].entities;
                        } else {
                            this._notificationService.notifyWarning({ title: 'Bill To Contact Search', message: 'No Bill To Contacts were found based on your customer' });
                        }
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Get Bill To Contacts' });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Get Bill To Contacts' });
                }
            );
    }

    private isDisplayCheckMetInTierFormArrays(tierFormArrays: Array<UntypedFormArray>) {
        let tierFormGroup;
        let tierFormArray;
        let retVal = true;  // default to showing warning unless we find a display tier checked off
        for (tierFormArray of tierFormArrays) {
            for (tierFormGroup of tierFormArray.controls) {
                if (!this._roleService.isHistoricalTier(tierFormGroup) && (tierFormGroup.get('fuelMarkupID') || tierFormGroup.get('rateMarkupID'))) {
                    if (tierFormGroup.get('displayTier').value) {
                        return false;
                    } else {
                        retVal = true;
                    }
                }
            }
        }
        return retVal;
    }

    private checkIfDisplayTierSelectionRequired() {
        const levelTwoRateTierFormArray: UntypedFormArray = this.roleDetailForm.get('levelTwoRateTiers') as UntypedFormArray;
        const levelThreeRateTierFormArray: UntypedFormArray = this.roleDetailForm.get('levelThreeRateTiers') as UntypedFormArray;

        return this.isDisplayCheckMetInTierFormArrays([levelTwoRateTierFormArray, levelThreeRateTierFormArray]);
    }

}
