import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash-es';
import moment from 'moment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as Constants from '../constants/constants';
import * as DateConstants from '../constants/datetime.constants';
import * as MasterDataConstants from '../constants/masterData.constants';
import {DateTimeHelper} from '../helpers/datetime.helper';
import {clearArray} from '../helpers/utilities';
import {RateTierRole} from '../models/rateTier.role.masterData';
import {RoleMasterData} from '../models/role.masterData';
import {RoleMasterDataPermissionDetail} from '../models/role.masterData.permissionDetail';
import {RoleDetailMasterData} from '../models/roleDetail.masterData';
import { EntityService } from './entity.service';

@Injectable()
export class RoleService {
    public roles = [];
    private lastRole: RoleDetailMasterData;

    constructor(
        private _fb: UntypedFormBuilder,
        private _dateHelper: DateTimeHelper,
        private _entityService: EntityService,
    ) {}

    public getRolesList() {
        return this._entityService.getEntityList<RoleMasterData>('Role').pipe(
            map(list => {
                if (Array.isArray(list)) {
                    clearArray(this.roles);
                    this.roles.push(...list);
                }
            })
        );
    }

    public deleteRole(id) {
        return this._entityService.deleteEntity(id, 'Role');
    }

    public createRole(role) {
        return this._entityService.createEntity<RoleDetailMasterData>(role, 'Role', 'roleID', 'roleName', 'admin/roles');
    }

    public updateRole(role) {
        return this._entityService.updateEntity<RoleDetailMasterData>(role, 'Role', role.roleID, 'roleName');
    }

    public getRole(id) {
        return this._entityService.getEntity<RoleDetailMasterData>('Role', id);
    }

    public getRoleByPath(id) {
        switch (id) {
            case 'new':
                return new Observable(subscriber => {
                    this.lastRole = new RoleDetailMasterData();
                    subscriber.next(_.cloneDeep(this.lastRole));
                    subscriber.complete();
                });

            default:
                return this.getRole(id).pipe(
                    map(role => {
                        if (Object.keys(role).length > 0) {
                            this.lastRole = role as RoleDetailMasterData;
                        }
                        return _.cloneDeep(this.lastRole);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    public updateForm(roleForm: UntypedFormGroup) {
        const sortedRateTiers: {
            rateTiersLevelTwo: Array<RateTierRole>,
            rateTiersLevelThree: Array<RateTierRole>,
        } = this.getSortedRateTiers(this.lastRole.rateTiers ? this.lastRole.rateTiers : null);

        roleForm.controls.roleID.setValue(this.lastRole.roleID);
        roleForm.controls.roleName.setValue(this.lastRole.roleName ? this.lastRole.roleName : '');
        roleForm.controls.roleType.setValue(this.lastRole.roleType ? this.lastRole.roleType : '');
        roleForm.controls.roleCustomer.setValue(this.lastRole.roleCustomer ? this.lastRole.roleCustomer : '');
        roleForm.controls.roleDescription.setValue(this.lastRole.roleDescription ? this.lastRole.roleDescription : '');
        roleForm.controls.tmaAuthorized.setValue(this.lastRole.tmaAuthorized ? this.lastRole.tmaAuthorized : 0);
        this.updateShipmentsFormSection(roleForm);
        this.updateQuotesFormSection(roleForm);
        this.updateDefaultsFormSection(roleForm);
        this.updateFormTier(roleForm.controls.levelTwoRateTiers as UntypedFormArray, sortedRateTiers.rateTiersLevelTwo);
        this.updateFormTier(roleForm.controls.levelThreeRateTiers as UntypedFormArray, sortedRateTiers.rateTiersLevelThree);
        this.updatePermissionFormGroup(roleForm.controls.userPermissions as UntypedFormGroup, this.lastRole.permissions ? this.lastRole.permissions.user : null);
        this.updatePermissionFormGroup(roleForm.controls.groupPermissions as UntypedFormGroup, this.lastRole.permissions ? this.lastRole.permissions.group : null);
        this.updatePermissionFormGroup(roleForm.controls.globalPermissions as UntypedFormGroup, this.lastRole.permissions ? this.lastRole.permissions.global : null);
    }

    public getRoleFormFromLastRole() {
        const sortedRateTiers: {
            rateTiersLevelTwo: Array<RateTierRole>,
            rateTiersLevelThree: Array<RateTierRole>,
        } = this.getSortedRateTiers(this.lastRole.rateTiers ? this.lastRole.rateTiers : null);

        const roleForm: UntypedFormGroup = this._fb.group({
            // ======================================= GENERAL =======================================
            roleID: this.lastRole.roleID,
            roleName: new UntypedFormControl(this.lastRole.roleName ? this.lastRole.roleName : '', {
                validators: [
                    Validators.required
                ]
            }),
            roleType: new UntypedFormControl(this.lastRole.roleType ? this.lastRole.roleType : '', {
                validators: [
                    Validators.required
                ]
            }),
            roleCustomer: new UntypedFormControl(this.lastRole.roleCustomer ? this.lastRole.roleCustomer : ''),
            roleDescription: new UntypedFormControl(this.lastRole.roleDescription ? this.lastRole.roleDescription : ''),
            tmaAuthorized: this.lastRole.tmaAuthorized ? this.lastRole.tmaAuthorized : 0,
            // ======================================= SHIPMENTS =======================================
            executeShipment: this.lastRole.shipments ? !!this.lastRole.shipments.executeShipment : false,
            forwarderFields: this.lastRole.shipments ? !!this.lastRole.shipments.forwarderFields : false,
            insurance: this.lastRole.shipments ? !!this.lastRole.shipments.insurance : false,
            creditCard: this.lastRole.shipments ? !!this.lastRole.shipments.creditCard : false,
            autoTender: this.lastRole.shipments ? !!this.lastRole.shipments.autoTender : false,
            srodTrackingAccl: this.lastRole.shipments ? !!this.lastRole.shipments.srodTrackingAccl : false,
            createShipment: this.lastRole.shipments ? !!this.lastRole.shipments.createShipment : false,
            placeToPrintBOL: this.lastRole.shipments ? !!this.lastRole.shipments.placeToPrintBOL : true,
            lockBillTo: this.lastRole.shipments ? !!this.lastRole.shipments.lockBillTo : false,
            autoClearShipments: this.lastRole.shipments ? this.lastRole.shipments.autoClearShipments : Constants.AUTOCLEAR_SHIPMENT_OPTIONS[0],
            // ======================================= QUOTE =======================================
            displayRates: this.lastRole.quotes ? !!this.lastRole.quotes.displayRates : true,
            APcontract: this.lastRole.quotes ? this.lastRole.quotes.APcontract : '',
            displayCarriers: this.lastRole.quotes ? this.lastRole.quotes.displayCarriers : '',
            excludeCarriers: this.lastRole.quotes ? this.lastRole.quotes.excludeCarriers : '',
            guaranteeRates: this.lastRole.quotes ? !!this.lastRole.quotes.guaranteeRates : true,
            requireCube: this.lastRole.quotes ? !!this.lastRole.quotes.requireCube : false,
            limitCube: this.lastRole.quotes ? !!this.lastRole.quotes.limitCube : false,
            limitCubeValue: this.lastRole.quotes ? this.lastRole.quotes.limitCubeValue : '',
            requireCommodity: this.lastRole.quotes ? !!this.lastRole.quotes.requireCommodity : false,
            showVLSCFS: this.lastRole.quotes ? !!this.lastRole.quotes.showVLSCFS : false,
            showCarrierKPI: this.lastRole.quotes ? !!this.lastRole.quotes.showCarrierKPI : true,
            finalMile: this.lastRole.quotes ? !!this.lastRole.quotes.finalMile : false,
            ratedClass: this.lastRole.quotes ? !!this.lastRole.quotes.ratedClass : false,
            spotRite: this.lastRole.quotes ? !!this.lastRole.quotes.spotRite : false,
            BOLFormat: this.lastRole.quotes ? this.lastRole.quotes.BOLFormat : '',
            BOLRequired: this.lastRole.quotes ? !!this.lastRole.quotes.BOLRequired : true,
            displayBOL: this.lastRole.quotes ? !!this.lastRole.quotes.displayBOL : true,
            displayPO: this.lastRole.quotes ? !!this.lastRole.quotes.displayPO : true,
            POFormat: this.lastRole.quotes ? this.lastRole.quotes.POFormat : '',
            PORequired: this.lastRole.quotes ? !!this.lastRole.quotes.PORequired : true,
            VLSCFS: this.lastRole.quotes ? this.lastRole.quotes.VLSCFS : '',
            // ======================================= DEFAULTS =======================================
            defaultBillTo: this.lastRole.defaults ? this.lastRole.defaults.defaultBillTo : '',
            defaultClass: this.lastRole.defaults ? this.lastRole.defaults.defaultClass : '',
            defaultConsignee: this._fb.group({
                defaultContactDisplay: '',
                defaultContactId: this.lastRole.defaults ? this.lastRole.defaults.defaultConsignee : ''
            }),
            defaultDestCountry: this.lastRole.defaults ? this.lastRole.defaults.defaultDestCountry : '',
            defaultDestZip: this.lastRole.defaults ? this.lastRole.defaults.defaultDestZip : '',
            defaultOriginCountry: this.lastRole.defaults ? this.lastRole.defaults.defaultOriginCountry : '',
            defaultOriginZip: this.lastRole.defaults ? this.lastRole.defaults.defaultOriginZip : '',
            defaultShipper: this._fb.group({
                defaultContactDisplay: '',
                defaultContactId: this.lastRole.defaults ? this.lastRole.defaults.defaultShipper : ''
            }),
            defaultWeight: this.lastRole.defaults ? this.lastRole.defaults.defaultWeight : null,
            editDefaults: this.lastRole.defaults ? !!this.lastRole.defaults.EditDefaults : true,
            hideQuoteDisclaimer: this.lastRole.defaults ? !!this.lastRole.defaults.hideQuoteDisclaimer : false,
            preferredRecordDisplayStyle: this.lastRole.defaults ? this.lastRole.defaults.preferredRecordDisplayStyle : '',
            quoteDisclaimer: this.lastRole.defaults ? this.lastRole.defaults.quoteDisclaimer : '',
            defaultsRoleId: this.lastRole.defaults ? this.lastRole.defaults.RoleID : null,
            defaultRecordTab: this.lastRole.defaults ? this.lastRole.defaults.defaultRecordTab : '',
            defaultAccessorials: this.lastRole.defaults ? this.lastRole.defaults.defaultAccessorials : '',
            defaultCommodity: this.lastRole.defaults ? this.lastRole.defaults.defaultCommodity : '',
            defaultDestType: this.lastRole.defaults ? this.lastRole.defaults.defaultDestType : 'C',
            defaultDirection: this.lastRole.defaults ? this.lastRole.defaults.defaultDirection : 'inbound',
            defaultOriginType: this.lastRole.defaults ? this.lastRole.defaults.defaultOriginType : 'C',
            defaultTSA: this.lastRole.defaults ? this.lastRole.defaults.defaultTSA : null,
            defaultUoM: this.lastRole.defaults ? this.lastRole.defaults.defaultUoM : '',
            paymentTerms: this.lastRole.defaults ? this.lastRole.defaults.paymentTerms : '',
            autoSaveContacts: this.lastRole.defaults ? !!this.lastRole.defaults.autoSaveContacts : false,
            // ======================================= PERMISSION FORMGROUPS =======================================
            userPermissions: this.toRolePermissionDetailFormGroup(this.lastRole.permissions ? this.lastRole.permissions.user : null, true),
            groupPermissions: this.toRolePermissionDetailFormGroup(this.lastRole.permissions ? this.lastRole.permissions.group : null),
            globalPermissions: this.toRolePermissionDetailFormGroup(this.lastRole.permissions ? this.lastRole.permissions.global : null),
            // ======================================= QUOTE RATE TIERS =======================================
            levelTwoRateTiers: this._fb.array(sortedRateTiers.rateTiersLevelTwo.map((tier: RateTierRole) => this.toRateTierLineItemFormGroup(tier))),
            levelThreeRateTiers: this._fb.array(sortedRateTiers.rateTiersLevelThree.map((tier: RateTierRole) => this.toRateTierLineItemFormGroup(tier))),
        });

        roleForm.setValidators([
            (fg: UntypedFormGroup) => {
                if ((fg.controls.roleType.value === 'CUSTOMER') && (!fg.controls.roleCustomer.value)) {
                    return {
                        required: true
                    }
                }

                return null;
            },
            () => {
                if (this.validNewTiers(roleForm.get('levelTwoRateTiers')) && this.validNewTiers(roleForm.get('levelThreeRateTiers'))) {
                    return null;
                } else {
                    return { invalidNonHistoryTiers: true };
                }
            }
        ]);
        return roleForm;
    }

    public updateLastRole(role) {
        this.lastRole = role;
    }

    public getRoleFromForm(roleForm: UntypedFormGroup) {
        const role: RoleDetailMasterData = _.cloneDeep(this.lastRole);
        if (roleForm.controls.roleID.value) {
            role.roleID = roleForm.controls.roleID.value;
        }
        role.roleName = roleForm.controls.roleName.value;
        role.roleType = roleForm.controls.roleType.value;
        role.roleCustomer = roleForm.controls.roleCustomer.value;
        role.roleDescription = roleForm.controls.roleDescription.value;
        role.tmaAuthorized = roleForm.controls.tmaAuthorized.value ? 1 : 0;
        role.shipments = {
            autoClearShipments: roleForm.controls.autoClearShipments.value,
            autoTender: roleForm.controls.autoTender.value ? 1 : 0,
            createShipment: roleForm.controls.createShipment.value ? 1 : 0,
            creditCard: roleForm.controls.creditCard.value ? 1 : 0,
            executeShipment: roleForm.controls.executeShipment.value ? 1 : 0,
            forwarderFields: roleForm.controls.forwarderFields.value ? 1 : 0,
            insurance: roleForm.controls.insurance.value ? 1 : 0,
            placeToPrintBOL: roleForm.controls.placeToPrintBOL.value ? 1 : 0,
            srodTrackingAccl: roleForm.controls.srodTrackingAccl.value ? 1 : 0,
            lockBillTo: roleForm.controls.lockBillTo.value ? 1 : 0
        }
        role.quotes = {
            APcontract: roleForm.controls.APcontract.value,
            BOLFormat: roleForm.controls.BOLFormat.value,
            BOLRequired: roleForm.controls.BOLRequired.value ? 1 : 0,
            displayBOL: roleForm.controls.displayBOL.value ? 1 : 0,
            displayCarriers: roleForm.controls.displayCarriers.value,
            displayPO: roleForm.controls.displayPO.value ? 1 : 0,
            displayRates: roleForm.controls.displayRates.value ? 1 : 0,
            excludeCarriers: roleForm.controls.excludeCarriers.value,
            finalMile: roleForm.controls.finalMile.value ? 1 : 0,
            guaranteeRates: roleForm.controls.guaranteeRates.value ? 1 : 0,
            limitCube: roleForm.controls.limitCube.value ? 1 : 0,
            limitCubeValue: roleForm.controls.limitCubeValue.value,
            POFormat: roleForm.controls.POFormat.value,
            PORequired: roleForm.controls.PORequired.value ? 1 : 0,
            ratedClass: roleForm.controls.ratedClass.value ? 1 : 0,
            requireCommodity: roleForm.controls.requireCommodity.value ? 1 : 0,
            requireCube: roleForm.controls.requireCube.value ? 1 : 0,
            showCarrierKPI: roleForm.controls.showCarrierKPI.value ? 1 : 0,
            VLSCFS: roleForm.controls.VLSCFS.value,
            spotRite: roleForm.controls.spotRite.value ? 1 : 0,
            showVLSCFS: roleForm.controls.showVLSCFS.value ? 1 : 0,
        }
        role.defaults = {
            defaultAccessorials: roleForm.controls.defaultAccessorials.value,
            defaultBillTo: roleForm.controls.defaultBillTo.value,
            defaultClass: roleForm.controls.defaultClass.value,
            defaultCommodity: roleForm.controls.defaultCommodity.value,
            defaultConsignee: roleForm.controls.defaultConsignee.value.defaultContactId,
            defaultDestCountry: roleForm.controls.defaultDestCountry.value,
            defaultDestZip: roleForm.controls.defaultDestZip.value,
            defaultDestType: roleForm.controls.defaultDestType.value,
            defaultDirection: roleForm.controls.defaultDirection.value,
            defaultOriginCountry: roleForm.controls.defaultOriginCountry.value,
            defaultOriginType: roleForm.controls.defaultOriginType.value,
            defaultOriginZip: roleForm.controls.defaultOriginZip.value,
            defaultRecordTab: roleForm.controls.defaultRecordTab.value,
            defaultShipper: roleForm.controls.defaultShipper.value.defaultContactId,
            defaultTSA: roleForm.controls.defaultTSA.value,
            defaultUoM: roleForm.controls.defaultUoM.value,
            defaultWeight: roleForm.controls.defaultWeight.value,
            EditDefaults: roleForm.controls.editDefaults.value ? 1 : 0,
            hideQuoteDisclaimer: roleForm.controls.hideQuoteDisclaimer.value ? 1 : 0,
            preferredRecordDisplayStyle: roleForm.controls.preferredRecordDisplayStyle.value,
            quoteDisclaimer: roleForm.controls.quoteDisclaimer.value,
            paymentTerms: roleForm.controls.paymentTerms.value,
            autoSaveContacts: roleForm.controls.autoSaveContacts.value,
        }
        role.permissions = {
            group: this.permissionFromFormGroup(roleForm.controls.groupPermissions as UntypedFormGroup),
            global: this.permissionFromFormGroup(roleForm.controls.globalPermissions as UntypedFormGroup),
            user: this.permissionFromFormGroup(roleForm.controls.userPermissions as UntypedFormGroup),
        };
        role.rateTiers = this.tierArrayFromFormArrays(roleForm.controls.levelTwoRateTiers, roleForm.controls.levelThreeRateTiers);
        return role;
    };

    public formEqualsLastRole(roleForm: UntypedFormGroup) {
        return (this.lastRole.roleName === roleForm.controls.roleName.value) &&
            (this.lastRole.roleType === roleForm.controls.roleType.value) &&
            (this.lastRole.roleCustomer === roleForm.controls.roleCustomer.value) &&
            (this.lastRole.roleDescription === roleForm.controls.roleDescription.value) &&
            (this.lastRole.tmaAuthorized === (roleForm.controls.tmaAuthorized.value ? 1 : 0)) &&
            (this.lastRole.shipments.autoClearShipments === roleForm.controls.autoClearShipments.value) &&
            (this.lastRole.shipments.autoTender === (roleForm.controls.autoTender.value ? 1 : 0)) &&
            (this.lastRole.shipments.createShipment === (roleForm.controls.createShipment.value ? 1 : 0)) &&
            (this.lastRole.shipments.creditCard === (roleForm.controls.creditCard.value ? 1 : 0)) &&
            (this.lastRole.shipments.executeShipment === (roleForm.controls.executeShipment.value ? 1 : 0)) &&
            (this.lastRole.shipments.forwarderFields === (roleForm.controls.forwarderFields.value ? 1 : 0)) &&
            (this.lastRole.shipments.insurance === (roleForm.controls.insurance.value ? 1 : 0)) &&
            (this.lastRole.shipments.placeToPrintBOL === (roleForm.controls.placeToPrintBOL.value ? 1 : 0)) &&
            (this.lastRole.shipments.lockBillTo === (roleForm.controls.lockBillTo.value ? 1 : 0)) &&
            (this.lastRole.shipments.srodTrackingAccl === (roleForm.controls.srodTrackingAccl.value ? 1 : 0)) &&
            (this.lastRole.quotes.APcontract === roleForm.controls.APcontract.value) &&
            (this.lastRole.quotes.BOLFormat === roleForm.controls.BOLFormat.value) &&
            (this.lastRole.quotes.BOLRequired === (roleForm.controls.BOLRequired.value ? 1 : 0)) &&
            (this.lastRole.quotes.displayBOL === (roleForm.controls.displayBOL.value ? 1 : 0)) &&
            (this.lastRole.quotes.displayCarriers === roleForm.controls.displayCarriers.value) &&
            (this.lastRole.quotes.displayPO === (roleForm.controls.displayPO.value ? 1 : 0)) &&
            (this.lastRole.quotes.displayRates === (roleForm.controls.displayRates.value ? 1 : 0)) &&
            (this.lastRole.quotes.excludeCarriers === roleForm.controls.excludeCarriers.value) &&
            (this.lastRole.quotes.finalMile === (roleForm.controls.finalMile.value ? 1 : 0)) &&
            (this.lastRole.quotes.guaranteeRates === (roleForm.controls.guaranteeRates.value ? 1 : 0)) &&
            (this.lastRole.quotes.limitCube === (roleForm.controls.limitCube.value ? 1 : 0)) &&
            (this.lastRole.quotes.limitCubeValue === roleForm.controls.limitCubeValue.value) &&
            (this.lastRole.quotes.POFormat === roleForm.controls.POFormat.value) &&
            (this.lastRole.quotes.PORequired === (roleForm.controls.PORequired.value ? 1 : 0)) &&
            (this.lastRole.quotes.ratedClass === (roleForm.controls.ratedClass.value ? 1 : 0)) &&
            (this.lastRole.quotes.requireCommodity === (roleForm.controls.requireCommodity.value ? 1 : 0)) &&
            (this.lastRole.quotes.requireCube === (roleForm.controls.requireCube.value ? 1 : 0)) &&
            (this.lastRole.quotes.showCarrierKPI === (roleForm.controls.showCarrierKPI.value ? 1 : 0)) &&
            (this.lastRole.quotes.VLSCFS === roleForm.controls.VLSCFS.value) &&
            (this.lastRole.quotes.spotRite === (roleForm.controls.spotRite.value ? 1 : 0)) &&
            (this.lastRole.quotes.showVLSCFS === (roleForm.controls.showVLSCFS.value ? 1 : 0)) &&
            (this.lastRole.defaults.defaultAccessorials === roleForm.controls.defaultAccessorials.value) &&
            (this.lastRole.defaults.defaultBillTo === roleForm.controls.defaultBillTo.value) &&
            (this.lastRole.defaults.defaultClass === roleForm.controls.defaultClass.value) &&
            (this.lastRole.defaults.defaultCommodity === roleForm.controls.defaultCommodity.value) &&
            (this.lastRole.defaults.defaultConsignee === roleForm.controls.defaultConsignee.value.defaultContactId) &&
            (this.lastRole.defaults.defaultDestCountry === roleForm.controls.defaultDestCountry.value) &&
            (this.lastRole.defaults.defaultDestZip === roleForm.controls.defaultDestZip.value) &&
            (this.lastRole.defaults.defaultDestType === roleForm.controls.defaultDestType.value) &&
            (this.lastRole.defaults.defaultDirection === roleForm.controls.defaultDirection.value) &&
            (this.lastRole.defaults.defaultOriginCountry === roleForm.controls.defaultOriginCountry.value) &&
            (this.lastRole.defaults.defaultOriginType === roleForm.controls.defaultOriginType.value) &&
            (this.lastRole.defaults.defaultOriginZip === roleForm.controls.defaultOriginZip.value) &&
            (this.lastRole.defaults.defaultRecordTab === roleForm.controls.defaultRecordTab.value) &&
            (this.lastRole.defaults.defaultShipper === roleForm.controls.defaultShipper.value.defaultContactId) &&
            (this.lastRole.defaults.defaultTSA === roleForm.controls.defaultTSA.value) &&
            (this.lastRole.defaults.defaultUoM === roleForm.controls.defaultUoM.value) &&
            (this.lastRole.defaults.defaultWeight === roleForm.controls.defaultWeight.value) &&
            (this.lastRole.defaults.EditDefaults === (roleForm.controls.editDefaults.value ? 1 : 0)) &&
            (this.lastRole.defaults.hideQuoteDisclaimer === (roleForm.controls.hideQuoteDisclaimer.value ? 1 : 0)) &&
            (this.lastRole.defaults.preferredRecordDisplayStyle === roleForm.controls.preferredRecordDisplayStyle.value) &&
            (this.lastRole.defaults.quoteDisclaimer === roleForm.controls.quoteDisclaimer.value) &&
            (this.lastRole.defaults.paymentTerms === roleForm.controls.paymentTerms.value) &&
            (this.lastRole.defaults.autoSaveContacts === roleForm.controls.autoSaveContacts.value) &&
            this.permissionEqualsFormGroup(this.lastRole.permissions.group, roleForm.controls.groupPermissions.value) &&
            this.permissionEqualsFormGroup(this.lastRole.permissions.global, roleForm.controls.globalPermissions.value) &&
            this.permissionEqualsFormGroup(this.lastRole.permissions.user, roleForm.controls.userPermissions.value) &&
            this.rateTiersEqualFormArrays(this.lastRole.rateTiers, roleForm.controls.levelTwoRateTiers.value, roleForm.controls.levelThreeRateTiers.value);
    }

    public isNewTier(tierFormControl) {
        return !(tierFormControl.value.tierID) && !(tierFormControl.value.history);
    }

    public isValidTier(tierFormControl: UntypedFormControl) {
        const tierValues = tierFormControl.value;

        return (!!tierValues.rateMarkupID || !!tierValues.fuelMarkupID || !!tierValues.acclMarkupID) && (
                (tierValues.end === DateConstants.DATE_VALUE_INDEFINITELY_display) ||
                moment(tierValues.end, this._dateHelper.getDisplayDateFormat()).isValid());
    }

    public isHistoricalTier(tierFormControl: UntypedFormControl) {
        const tierValues = tierFormControl.value;
        if (tierValues.history) {
            return true;
        }

        if (tierValues.end === DateConstants.DATE_VALUE_INDEFINITELY_display) {
            return false;
        } else {
            return (moment().isAfter(moment(tierValues.end, this._dateHelper.getDisplayDateFormat()), 'day'));
        }
    }

    public isActiveTier(tierFormControl: UntypedFormControl) {
        const tierValues = tierFormControl.value;
        if (tierValues.history || !this.isValidTier(tierFormControl)) {
            return false;
        }

        if (moment().isSameOrAfter(moment(tierValues.effective, this._dateHelper.getDisplayDateFormat()), 'day')) {
            if (tierValues.end === DateConstants.DATE_VALUE_INDEFINITELY_display) {
                return true;
            } else {
                return (moment().isSameOrBefore(moment(tierValues.end, this._dateHelper.getDisplayDateFormat()), 'day'));
            }
        }
    }

    public addNewTier(formTiers: UntypedFormArray, tierOrder: number) {
        const rateTierLineItemFormGroup = this.toRateTierLineItemFormGroup(null);
        rateTierLineItemFormGroup.get('tierOrder').setValue(tierOrder);
        formTiers.insert(0, rateTierLineItemFormGroup);
    }

    public validNewTiers(tierFormArray) {
        let tierFormControl;
        if (tierFormArray) {
            for (tierFormControl of tierFormArray.controls) {
                if (this.isNewTier(tierFormControl) && !this.isValidTier(tierFormControl)) {
                    return false;
                }
            }
        }
        return true;
    }

    public tierGapsExist(tierFormArray) {
        let tierArray = [];
        let tierFormControl;
        let prevTierFormControl;
        let prevEnd;
        let tierEffective;

        for (tierFormControl of tierFormArray.controls) {
            if (!this.isHistoricalTier(tierFormControl)) {
                tierArray.push(tierFormControl);
            }
        }

        tierArray = tierArray.sort((a, b) => {
            const aEffective = moment(a.value.effective, this._dateHelper.getDisplayDateFormat());
            const bEffective = moment(b.value.effective, this._dateHelper.getDisplayDateFormat());
            return aEffective.diff(bEffective, 'days');
        });

        for (tierFormControl of tierArray) {
            if (!prevTierFormControl) {
                if (moment().isBefore(moment(tierFormControl.value.effective, this._dateHelper.getDisplayDateFormat()), 'days')) {
                    return true;
                }
                prevTierFormControl = tierFormControl;
            } else {
                if (prevTierFormControl.value.end === DateConstants.DATE_VALUE_INDEFINITELY_display) {
                    return false;
                }
                prevEnd = moment(prevTierFormControl.value.end, this._dateHelper.getDisplayDateFormat());
                tierEffective = moment(tierFormControl.value.effective, this._dateHelper.getDisplayDateFormat());
                if (tierEffective.diff(prevEnd, 'days') > 1) {
                    return true;
                }
            }
        }

        return false;
    }

    private getSortedRateTiers(rateTiers: Array<RateTierRole>): { rateTiersLevelTwo: Array<RateTierRole>, rateTiersLevelThree: Array<RateTierRole> } {
        let rateTiersLevelTwo;
        let rateTiersLevelThree;

        if (!rateTiers) {
            return {
                rateTiersLevelTwo: [],
                rateTiersLevelThree: []
            }
        } else {
            rateTiersLevelTwo = this.sortRateTiers(rateTiers.filter((rateTier: RateTierRole) => rateTier.tierOrder === 2));
            rateTiersLevelThree = this.sortRateTiers(rateTiers.filter((rateTier: RateTierRole) => rateTier.tierOrder === 3));
        }

        return {
            rateTiersLevelTwo: rateTiersLevelTwo ? rateTiersLevelTwo : [],
            rateTiersLevelThree: rateTiersLevelThree ? rateTiersLevelThree : [],
        };
    }

    private sortRateTiers(rateTiers: Array<RateTierRole>): Array<RateTierRole> {
        const sortedRateTiers: Array<RateTierRole> = rateTiers.sort((a: RateTierRole, b: RateTierRole) => a.history ? 1 : -1);

        return sortedRateTiers;
    }

    private apiDateToString(rateTierDate: string): string {
        if (!rateTierDate) {
            return '';
        }

        if (rateTierDate === DateConstants.DATE_VALUE_INDEFINITELY) {
            return DateConstants.DATE_VALUE_INDEFINITELY_display;
        }

        return this._dateHelper.getFormattedDisplayDate(rateTierDate, DateConstants.DATE_FORMAT_yyyymmdd);
    }

    private toRateTierLineItemFormGroup(rateTier: RateTierRole): UntypedFormGroup {
        const form: UntypedFormGroup = this._fb.group({
            changeDate: rateTier ? rateTier.changeDate : '',
            tierLabel: rateTier ? rateTier.tierLabel : '',
            tierOrder: rateTier ? rateTier.tierOrder : null,
            effective: new UntypedFormControl(
                rateTier ? this.apiDateToString(rateTier.effective) : moment().format(DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES), {
                    updateOn: 'blur',
                    validators: [
                        this._dateHelper.dateValueValidator()
                    ]
                }),
            end: new UntypedFormControl(
                rateTier ? this.apiDateToString(rateTier.end) : DateConstants.DATE_VALUE_INDEFINITELY_display, {
                    updateOn: 'blur',
                    validators: [
                        this._dateHelper.dateValueValidator()
                    ]
                }),
            displayTier: rateTier ? !!rateTier.displayTier : false,
            rateOn: rateTier ? rateTier.rateOn : '',
            rateOnSRCustNo: rateTier ? rateTier.rateOnSRCustNo : '',
            roleID: rateTier ? rateTier.roleID : null,
            fuelMarkupID: rateTier ? rateTier.fuelMarkupID : '',
            acclMarkupID: rateTier ? rateTier.acclMarkupID : '',
            rateMarkupID: rateTier ? rateTier.rateMarkupID : '',
            rateOnTierID: rateTier ? rateTier.rateOnTierID : '',
            history: rateTier ? !!rateTier.history : false,
        });

        form.addControl('tierID', rateTier ? new UntypedFormControl(rateTier.tierID) : new UntypedFormControl());
        return form;
    }

    private updateShipmentsFormSection(roleForm: UntypedFormGroup) {
        roleForm.controls.executeShipment.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.executeShipment : false);
        roleForm.controls.forwarderFields.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.forwarderFields : false);
        roleForm.controls.insurance.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.insurance : false);
        roleForm.controls.creditCard.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.creditCard : false);
        roleForm.controls.autoTender.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.autoTender : false);
        roleForm.controls.srodTrackingAccl.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.srodTrackingAccl : false);
        roleForm.controls.createShipment.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.createShipment : false);
        roleForm.controls.placeToPrintBOL.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.placeToPrintBOL : true);
        roleForm.controls.lockBillTo.setValue(this.lastRole.shipments ? !!this.lastRole.shipments.lockBillTo : false);
        roleForm.controls.autoClearShipments.setValue(this.lastRole.shipments ? this.lastRole.shipments.autoClearShipments : Constants.AUTOCLEAR_SHIPMENT_OPTIONS[0]);
    }

    private updateQuotesFormSection(roleForm: UntypedFormGroup) {
        roleForm.controls.displayRates.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.displayRates : true);
        roleForm.controls.APcontract.setValue(this.lastRole.quotes ? this.lastRole.quotes.APcontract : '');
        roleForm.controls.displayCarriers.setValue(this.lastRole.quotes ? this.lastRole.quotes.displayCarriers : '');
        roleForm.controls.excludeCarriers.setValue(this.lastRole.quotes ? this.lastRole.quotes.excludeCarriers : '');
        roleForm.controls.guaranteeRates.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.guaranteeRates : true);
        roleForm.controls.requireCube.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.requireCube : false);
        roleForm.controls.limitCube.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.limitCube : false);
        roleForm.controls.limitCubeValue.setValue(this.lastRole.quotes ? this.lastRole.quotes.limitCubeValue : '');
        roleForm.controls.requireCommodity.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.requireCommodity : false);
        roleForm.controls.showVLSCFS.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.showVLSCFS : false);
        roleForm.controls.showCarrierKPI.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.showCarrierKPI : true);
        roleForm.controls.finalMile.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.finalMile : false);
        roleForm.controls.ratedClass.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.ratedClass : false);
        roleForm.controls.spotRite.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.spotRite : false);
        roleForm.controls.BOLFormat.setValue(this.lastRole.quotes ? this.lastRole.quotes.BOLFormat : '');
        roleForm.controls.BOLRequired.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.BOLRequired : true);
        roleForm.controls.displayBOL.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.displayBOL : true);
        roleForm.controls.displayPO.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.displayPO : true);
        roleForm.controls.POFormat.setValue(this.lastRole.quotes ? this.lastRole.quotes.POFormat : '');
        roleForm.controls.PORequired.setValue(this.lastRole.quotes ? !!this.lastRole.quotes.PORequired : true);
        roleForm.controls.VLSCFS.setValue(this.lastRole.quotes ? this.lastRole.quotes.VLSCFS : '');
    }

    private updateDefaultsFormSection(roleForm) {
        roleForm.controls.defaultBillTo.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultBillTo : '');
        roleForm.controls.defaultClass.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultClass : '');
        roleForm.controls.defaultClass.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultClass : '');
        roleForm.controls.defaultConsignee.controls.defaultContactDisplay.setValue('');
        roleForm.controls.defaultConsignee.controls.defaultContactId.setValue((this.lastRole.defaults && this.lastRole.defaults.defaultConsignee) ? this.lastRole.defaults.defaultConsignee : '');
        roleForm.controls.defaultDestCountry.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultDestCountry : '');
        roleForm.controls.defaultDestZip.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultDestZip : '');
        roleForm.controls.defaultOriginCountry.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultOriginCountry : '');
        roleForm.controls.defaultOriginZip.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultOriginZip : '');
        roleForm.controls.defaultShipper.controls.defaultContactDisplay.setValue('');
        roleForm.controls.defaultShipper.controls.defaultContactId.setValue((this.lastRole.defaults && this.lastRole.defaults.defaultShipper) ? this.lastRole.defaults.defaultShipper : '');
        roleForm.controls.defaultWeight.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultWeight : null);
        roleForm.controls.editDefaults.setValue(this.lastRole.defaults ? !!this.lastRole.defaults.EditDefaults : true);
        roleForm.controls.hideQuoteDisclaimer.setValue(this.lastRole.defaults ? !!this.lastRole.defaults.hideQuoteDisclaimer : false);
        roleForm.controls.preferredRecordDisplayStyle.setValue(this.lastRole.defaults ? this.lastRole.defaults.preferredRecordDisplayStyle : '');
        roleForm.controls.quoteDisclaimer.setValue(this.lastRole.defaults ? this.lastRole.defaults.quoteDisclaimer : '');
        roleForm.controls.defaultsRoleId.setValue(this.lastRole.defaults ? this.lastRole.defaults.RoleID : null);
        roleForm.controls.defaultRecordTab.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultRecordTab : '');
        roleForm.controls.defaultAccessorials.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultAccessorials : '');
        roleForm.controls.defaultCommodity.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultCommodity : '');
        roleForm.controls.defaultDestType.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultDestType : 'C');
        roleForm.controls.defaultDirection.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultDirection : 'inbound');
        roleForm.controls.defaultOriginType.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultOriginType : 'C');
        roleForm.controls.defaultTSA.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultTSA : null);
        roleForm.controls.defaultUoM.setValue(this.lastRole.defaults ? this.lastRole.defaults.defaultUoM : '');
        roleForm.controls.paymentTerms.setValue(this.lastRole.defaults ? this.lastRole.defaults.paymentTerms : '');
        roleForm.controls.autoSaveContacts.setValue(this.lastRole.defaults ? !!this.lastRole.defaults.autoSaveContacts : false);
    }

    private updatePermissionFormGroup(permissionsForm: UntypedFormGroup, permission: RoleMasterDataPermissionDetail) {
        permissionsForm.controls.quotes.setValue((permission && permission.quotes) ? permission.quotes : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.quote);
        permissionsForm.controls.shipments.setValue((permission && permission.shipments) ? permission.shipments : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.shipment);
        permissionsForm.controls.invoices.setValue((permission && permission.invoices) ? permission.invoices : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.invoice);
        permissionsForm.controls.contacts.setValue((permission && permission.contacts) ? permission.contacts : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.contact);
        permissionsForm.controls.products.setValue((permission && permission.products) ? permission.products : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.product);
        permissionsForm.controls.carrier.setValue((permission && permission.carrier) ? permission.carrier : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.carrier);
        permissionsForm.controls.customers.setValue((permission && permission.customers) ? permission.customers : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.customers);
        permissionsForm.controls.users.setValue((permission && permission.users) ? permission.users : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.users);
        permissionsForm.controls.markups.setValue((permission && permission.markups) ? permission.markups : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.markups);
        permissionsForm.controls.roles.setValue((permission && permission.roles) ? permission.roles : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.roles);
    }

    private clearFormArray(formArray: UntypedFormArray) {
        while (formArray.length !== 0) {
            formArray.removeAt(0)
        }
    }

    private updateFormTier(formArray: UntypedFormArray, tiers) {
        this.clearFormArray(formArray);
        tiers.map((tier: RateTierRole) => formArray.push(this.toRateTierLineItemFormGroup(tier)));
    }

    private toRolePermissionDetailFormGroup(permissionDetail: RoleMasterDataPermissionDetail, isUserPermission = false): UntypedFormGroup {
        if (!permissionDetail) {
            return this._fb.group({
                quotes: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.quote,
                shipments: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.shipment,
                invoices: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.invoice,
                contacts: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.contact,
                products: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.product,
                carrier: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.carrier,
                customers: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.customers,
                users: (isUserPermission) ? MasterDataConstants.ROLE_PERMISSION_DETAIL_VALUES.VIEW : MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.users,
                markups: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.markups,
                roles: MasterDataConstants.ROLE_PERMISSION_DETAIL_DEFAULTS.roles
            });
        }

        return this._fb.group({
            quotes: permissionDetail.quotes,
            shipments: permissionDetail.shipments,
            invoices: permissionDetail.invoices,
            contacts: permissionDetail.contacts,
            products: permissionDetail.products,
            carrier: permissionDetail.carrier,
            customers: permissionDetail.customers,
            users: permissionDetail.users,
            markups: permissionDetail.markups,
            roles: permissionDetail.roles
        });
    }

    private tierArrayFromFormArrays(levelTwoRateTiers, levelThreeRateTiers) {
        return [
            ...(levelTwoRateTiers ? levelTwoRateTiers.controls : []),
            ...(levelThreeRateTiers ? levelThreeRateTiers.controls : [])
        ].map((rateTier: AbstractControl) => this.tierFromFormGroup(rateTier as UntypedFormGroup))
            .filter((rateTier: RateTierRole) => rateTier);
    }

    private tierFromFormGroup(tierFormGroup: UntypedFormGroup): RateTierRole {
        const tierId: number = tierFormGroup.controls.tierID.value;
        const roleId: number = tierFormGroup.controls.roleID.value;

        if ((tierId === null) && !tierFormGroup.controls.rateMarkupID.value && !tierFormGroup.controls.fuelMarkupID.value && !tierFormGroup.controls.acclMarkupID.value) {
            return null;
        }

        const tier: RateTierRole = {
            tierLabel: tierFormGroup.controls.tierLabel.value,
            tierOrder: tierFormGroup.controls.tierOrder.value,
            effective: this.toAPIDate(tierFormGroup.controls.effective.value),
            end: this.toAPIDate(tierFormGroup.controls.end.value),
            rateMarkupID: tierFormGroup.controls.rateMarkupID.value,
            displayTier: tierFormGroup.controls.displayTier.value,
            rateOn: tierFormGroup.controls.rateOn.value,
            rateOnSRCustNo: tierFormGroup.controls.rateOnSRCustNo.value,
            fuelMarkupID: tierFormGroup.controls.fuelMarkupID.value,
            acclMarkupID: tierFormGroup.controls.acclMarkupID.value,
            rateOnTierID: tierFormGroup.controls.rateOnTierID.value,
            history: tierFormGroup.controls.history.value ? 1 : 0,
        };

        if (tierId) {
            tier.tierID = tierId;
        }
        if (roleId) {
            tier.roleID = roleId;
        }

        return tier;
    }

    private toAPIDate(formDate: string): string {
        if (!formDate) {
            return '';
        }

        if (formDate.toLocaleLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLocaleLowerCase()) {
            return MasterDataConstants.MARKUP_DATE_VALUE_indefinitely;
        }

        return this._dateHelper.prepareDate(formDate, DateConstants.DATE_FORMAT_yyyymmdd);
    }

    private permissionFromFormGroup(permissionsFormGroup: UntypedFormGroup) {
        if (!permissionsFormGroup) {
            return null;
        }

        return {
            contacts: permissionsFormGroup.controls.contacts.value,
            customers: permissionsFormGroup.controls.customers.value,
            products: permissionsFormGroup.controls.products.value,
            roles: permissionsFormGroup.controls.roles.value,
            shipments: permissionsFormGroup.controls.shipments.value,
            markups: permissionsFormGroup.controls.markups.value,
            quotes: permissionsFormGroup.controls.quotes.value,
            users: permissionsFormGroup.controls.users.value,
            carrier: permissionsFormGroup.controls.carrier.value,
            invoices: permissionsFormGroup.controls.invoices.value
        };
    }

    private permissionEqualsFormGroup(permission: RoleMasterDataPermissionDetail, permissionFormValue) {
        return (permission.contacts === permissionFormValue.contacts) &&
            (permission.customers === permissionFormValue.customers) &&
            (permission.products === permissionFormValue.products) &&
            (permission.roles === permissionFormValue.roles) &&
            (permission.shipments === permissionFormValue.shipments) &&
            (permission.markups === permissionFormValue.markups) &&
            (permission.quotes === permissionFormValue.quotes) &&
            (permission.users === permissionFormValue.users) &&
            (permission.carrier === permissionFormValue.carrier) &&
            (permission.invoices === permissionFormValue.invoices);
    }

    private matchTiers(sourceTiers: Array<RateTierRole>, searchTiers) {
        let searchTier;
        let matchingTier: RateTierRole;

        for (searchTier of searchTiers) {
            if (((searchTier.tierID === undefined) || (searchTier.tierID === null)) &&
                (searchTier.rateMarkupFormValueChangesSubscription || searchTier.fuelMarkupID || searchTier.acclMarkupID)) {
                return false;
            }
            matchingTier = sourceTiers.find((tier) => tier.tierID === searchTier.tierID);
            if (!matchingTier ||
                (matchingTier.tierLabel !== searchTier.tierLabel) ||
                (matchingTier.rateOnTierID !== ((searchTier.rateOnTierID === null) ? undefined : searchTier.rateOnTierID)) ||
                (matchingTier.fuelMarkupID !== ((searchTier.fuelMarkupID === null) ? undefined : searchTier.fuelMarkupID)) ||
                (matchingTier.acclMarkupID !== ((searchTier.acclMarkupID === null) ? undefined : searchTier.acclMarkupID)) ||
                (matchingTier.displayTier !== (searchTier.displayTier ? 1 : 0)) ||
                (matchingTier.effective !== this.toAPIDate(searchTier.effective)) ||
                (matchingTier.end !== this.toAPIDate(searchTier.end))) {
                return false;
            }
        }

        return true;
    }

    private rateTiersEqualFormArrays(rateTiers: Array<RateTierRole>, levelTwoTiersFormValue, levelThreeTiersFormValue) {
        if (rateTiers.length !== (levelTwoTiersFormValue.length + levelThreeTiersFormValue.length)) {
            return false;
        }

        return this.matchTiers(rateTiers, levelTwoTiersFormValue) && this.matchTiers(rateTiers, levelThreeTiersFormValue);
    }
}
