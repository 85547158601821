export class User {
    autoSaveContacts: boolean;
    ccBillingAddress1: string;
    ccBillingAddress2: string;
    ccBillingCity: string;
    ccBillingName: string;
    ccFirstName: string;
    ccLastName: string;
    ccBillingSt: string;
    ccBillingZip: string;
    customer: string; // Customer
    defaultAccessorials?: string;
    defaultClass: string;
    defaultCommodity: string;
    defaultConsignee: string;
    defaultDestType: string;
    defaultDestZip: string;
    defaultDirection: string;
    defaultOriginType: string;
    defaultOriginZip: string;
    defaultRecordTab: string;
    defaultShipper: string;
    defaultTSA: number;
    defaultUoM: string;
    email: string;
    firstname: string;
    lastlogin?: string;
    lastname: string;
    name: string;
    phone: string;
    paymentTerms: string;
    rolePaymentTerms: string;
    lockBillTo: number;
    preferredRecordDisplayStyle?: string; // Wizard, Accordion
    userID?: number;
    active: number; // Y, N
    userlogin: string;
    usertype?: string;
    roleID: number;

    // when Impersonating
    Actualcustomer?: string;
    ActualuserID?: number;

    // other expected fields?
    shouldSolicitSaveQuote?: string;

    // here for compatibility with Mock Login feature
    password?: string;
    lockedOut: number;
    integrationTokenExists?: number;
    isAuthorizedApiUser?: number;
    shipmentValue: number = 0.0;
}
