import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {catchError, map} from 'rxjs/operators';

import * as MDConstants from '../constants/masterData.constants';
import {UserHelper} from '../helpers/userHelper';
import { ResponseDTO } from '../models/responseDto';
import {ShipmentUnified} from '../models/shipmentUnified';
import { CreateTemplateRequest, CreateTemplateResponse, Template } from '../models/template';
import { ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST } from '../services/api.service';
import {ShipmentService} from '../services/shipment.service';
import {SignalsService} from "../services/signals.service";
import {RoleDetailMasterData} from "../models/roleDetail.masterData";
import {NotificationService} from "./notification.service";

@Injectable()
export class TemplateService {

    constructor(
        private _api: ApiService,
        private _signalsService: SignalsService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _userHelper: UserHelper,
        private _shipmentService: ShipmentService,
    ) { }

    public getUserTemplates() {
        const loadTemplatesReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Templates/`);
        return this._api.callApiService<ResponseDTO<Array<Template>>>(loadTemplatesReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    this._signalsService.templatesSignal.set(response.dto);
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Load User Templates' });
                throw err;
            })
        );
    }

    public getTemplateById(id: number) {
        const getTemplateReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Templates/Template/${id}`);
        return this._api.callApiService<ResponseDTO<Template>>(getTemplateReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `Apply User Template`
                });
                throw err;
            })
        );
    }

    public createUserTemplate(req: CreateTemplateRequest) {
        const createTemplateReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Templates/`, req);
        return this._api.callApiService<ResponseDTO<CreateTemplateResponse>>(createTemplateReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    this._notificationService.notifySuccess({ title: 'Templates', message: `'${req.templateName}' saved to user templates.` });
                    return;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Create User Template' });
                throw err;
            })
        );
    }

    public applyUserTemplateNavigation(id: number, templateType: string): void {
        // navigate to appropriate page, then apply template data
        switch (templateType) {
            case MDConstants.TEMPLATE_TYPES_quote:
            case MDConstants.TEMPLATE_TYPES_shipment:
                this.getTemplateById(id).pipe(
                    map(template => {
                        let shipmentUnified: ShipmentUnified;
                        if (template.templateType === MDConstants.TEMPLATE_TYPES_quote) {
                            shipmentUnified = new ShipmentUnified();
                            // Not applying defaults first mmeans using three lines below:
                            this._shipmentService.transformQuoteTemplateToShipmentUnified(template.template.template, shipmentUnified);
                            if (template.insurance) {
                                this._shipmentService.lastInsurance = template.insurance;
                            }
                            this._shipmentService.lastShipmentUnified = shipmentUnified;
                            this._router.navigate(['shipments/' + this._userHelper.getUserCustomer() + '/template']);
                            //  Below is code in case we want to apply defaults first (delete three lines above to use below):
                            /*
                            const contactObservables = this._shipmentService.applyDefaultsToShipmentUnified(shipmentUnified);
                            if (contactObservables.length > 0) {
                                forkJoin(contactObservables).pipe(
                                    map(() => {
                                        this._shipmentService.transformQuoteTemplateToShipmentUnified(template.template.template, shipmentUnified);
                                        this._shipmentService.lastShipmentUnified = shipmentUnified;
                                        this._router.navigate(['shipments/' + this._userHelper.getUserCustomer() + '/template']);
                                    }),
                                    catchError(err => EMPTY)
                                ).subscribe();
                            } else {
                                this._shipmentService.transformQuoteTemplateToShipmentUnified(template.template.template, shipmentUnified);
                                this._shipmentService.lastShipmentUnified = shipmentUnified;
                                this._router.navigate(['shipments/' + this._userHelper.getUserCustomer() + '/template']);
                            }
                             */
                        } else {
                            if (template.insurance) {
                                this._shipmentService.lastInsurance = template.insurance;
                            } else {
                                this._shipmentService.lastInsurance = undefined;
                            }
                            this._shipmentService.lastShipmentUnified = template.template.template;
                            this._router.navigate(['shipments/' + this._userHelper.getUserCustomer() + '/template']);
                        }
                        this._signalsService.updateAppState({ 'floater.clipboardCollapsed': true });
                    })
                ).subscribe();
                break;

            case MDConstants.TEMPLATE_TYPES_role:
                this._router.navigate(['admin', 'roles', 'createRole']).then(() => {
                    this.applyUserTemplateData(id);
                });
                break;

            default:
                this._notificationService.notifyError({ title: 'Template', message: `Invalid template type [${templateType}] provided.` });
                return;
        }
    }

    private applyUserTemplateData(id: number): void {
        const getTemplateReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Templates/Template/${id}`);
        this._api.callApiService<ResponseDTO<Template>>(getTemplateReq).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this.applyTemplate(response.dto);
                    this._signalsService.updateAppState({ 'floater.clipboardCollapsed': true });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Apply User Template' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Apply User Template' });
            });
    }

    public deleteUserTemplate(templateId: number): void {
        const deleteTemplateReq = new HttpRequest(REQUEST_TYPE_DELETE, `MasterData/Templates/${templateId}`);
        this._api.callApiService<{ isValid: boolean, messages: Array<any> }>(deleteTemplateReq).subscribe(
            (response) => {
                if (response && response.isValid) {
                    this._notificationService.notifySuccess({ title: `Delete Template`, message: `You have successfully deleted this template` });
                    this.getUserTemplates().subscribe();
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Delete Template' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Delete Template' });
            });
    }

    public prepareRoleTemplate(roleData: RoleDetailMasterData): object {
        return roleData;
    }

    public applyTemplate(t: Template): void {
        if (!t) {
            this._notificationService.notifyError({ title: 'Template Error', message: `Invalid Template provided, unable to apply template.` });
            return;
        }

        switch (t.templateType) {
            // Quotes and Shipments handled via path.
            case MDConstants.TEMPLATE_TYPES_quote:
            case MDConstants.TEMPLATE_TYPES_shipment:
                return;

            case MDConstants.TEMPLATE_TYPES_role:
                this.applyRoleTemplate(t);
                return;

            default:
                this._notificationService.notifyError({ title: 'Template Error', message: `Invalid Template Type provided [${t.templateType}]` });
                return;
        }
    }

    private applyRoleTemplate(t: Template): void {
        const roleData: RoleDetailMasterData = t.template;
        if (roleData) {
        }
    }
}
