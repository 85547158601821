import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {LoginSettings} from '../../../models/loginSettings';
import {QuotedRate} from '../../../models/quotedRate';
import {SignalsService} from "../../../services/signals.service";

@Component({
    selector: 'app-quote-selection-modal',
    templateUrl: './app-quote-selection-modal.component.html',
    styleUrls: ['./app-quote-selection-modal.component.scss']
})

export class AppQuoteSelectionModalComponent implements OnInit {
    @Input()
    public rates: Array<QuotedRate>;
    @Input()
    public title = 'Please select a carrier.';
    @Input()
    public lowestCostRateId;
    @Input()
    public fastestDeliveryRateId;
    @Input()
    public instantSelect = false;
    @Input()
    public insurance;

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public selectedRate: EventEmitter<QuotedRate> = new EventEmitter<QuotedRate>();

    public ratesToShow: Array<QuotedRate>;
    public isCarrierKPIShown;
    public isRatedClassShown;
    public isTerminalDetailsModalVisible = false;
    public lastRateClicked: QuotedRate;

    constructor(private _signalsService: SignalsService) { }

    @HostListener('document:keydown.escape', ['$event']) clicked(event) {
        event.preventDefault();
        if (this.isTerminalDetailsModalVisible) {
            this.isTerminalDetailsModalVisible = false;
            return;
        }
        this.cancel.emit();
    }

    public ngOnInit() {
        const userSettings: LoginSettings = this._signalsService.loginSettingsSignal();

        if (userSettings && userSettings.permissions && userSettings.permissions.length) {
            this.isCarrierKPIShown = userSettings.permissions[0].showCarrierKPI;
            this.isRatedClassShown = userSettings.permissions[0].ratedClass;
        }
    }

    public showTerminalDetails($event: Array<any>) {
        this.ratesToShow = $event as Array<QuotedRate>;
        this.isTerminalDetailsModalVisible = true;
    }

    public selected($event: any) {
        this.lastRateClicked = $event as QuotedRate;
        if (this.instantSelect && this.lastRateClicked) {
            this.isTerminalDetailsModalVisible = false;
            this.selectedRate.emit(this.lastRateClicked);
        }
    }

    public cancelClicked() {
        this.isTerminalDetailsModalVisible = false;
        this.cancel.emit();
    }

    public selectClicked() {
        if (this.lastRateClicked) {
            this.isTerminalDetailsModalVisible = false;
            this.selectedRate.emit(this.lastRateClicked);
        }
    }
}
