import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {QuotedRate} from '../../models/quotedRate';
import {BookingOption} from '../../models/unifiedShipmentObject';
import {QuickQuoteService} from '../../services/quickQuote.service';
import {ShipmentService} from '../../services/shipment.service';

@Component({
    selector: 'app-carrier-option-details',
    styleUrls: ['./app-carrier-option-details.component.scss'],
    templateUrl: './app-carrier-option-details.component.html',
})
export class AppCarrierOptionDetailsComponent implements OnChanges {
    @Input()
    public carrierOption: BookingOption;
    @Input()
    public quotedRate: QuotedRate;
    @Input()
    public isRatedClassShown;
    @Input()
    public insuranceCharge = 0;

    public lineHaul = 0;
    public fuel = 0;
    public extra = 0;
    public final = 0;
    public currency = '';

    constructor(private _quickQuoteService: QuickQuoteService,
                private _shipmentService: ShipmentService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        let result: any;

        if (this.carrierOption) {
            result = this._quickQuoteService.loadCosts(this.carrierOption);
        } else {
            result = this._shipmentService.loadCosts(this.quotedRate);
        }

        this.final = result.total + this.insuranceCharge;
        this.currency = result.currency;
        this.extra = result.extra;
        this.fuel = result.fuel;
        this.lineHaul = result.lineHaul;
    }

    public getService() {
        if (this.carrierOption) {
            return (this.carrierOption.interlineDelivery ? 'Interline': 'Direct');
        } else {
            return (this.quotedRate.relation.toUpperCase() === 'I' ? 'Interline' : 'Direct');
        }
    }

    public getTimeInTransit() {
        let days = 0;
        if (this.carrierOption) {
            if (this.carrierOption.totalTransit) {
                days = this.carrierOption.totalTransit.value;
            }
        } else if (this.quotedRate) {
            if (this.quotedRate.days) {
                days = this.quotedRate.days;
            }
        }

        return (days === 1) ? `${days} day` : `${days} days`;
    }

    public isCtns() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.scac === 'CTNS';
        } else {
            return this.quotedRate.scac === 'CTNS';
        }
    }

    public isGuarenteedDelivery() {
        if (this.carrierOption) {
            return this.carrierOption.guaranteedDelivery;
        } else {
            return this.quotedRate.guarantee;
        }
    }

    public densityCarrier() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.densityCarrier;
        } else {
            return this.quotedRate.densityCarrier === 'Y' ? 1 : 0;
        }
    }

    public densityClass() {
        if (this.carrierOption) {
            return this.carrierOption.densityClass.code;
        } else {
            return this.quotedRate.ratedClass;
        }
    }

    public serviceType() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.type;
        } else {
            return this.quotedRate.service.name;
        }
    }

}
