<div class="detailRow">
    <div class="labelCol">
        Charges
    </div>
    <app-shipping-costs [insuranceCost]="insuranceCost" class="costsBox" [uso]="quickQuote" ></app-shipping-costs>
</div>

<div class="detailRow">
    <div class="labelCol">
        Service
    </div>
    <div class="dataCol">
        {{quickQuote.bookingSelection.interlineDelivery ? 'Interline' : 'Direct'}}
    </div>
</div>

<div class="detailRow">
    <div class="labelCol">
        Transit Time
    </div>
    <div class="dataCol">
        {{getTimeInTransit()}}
    </div>
</div>

<div class="detailRow">
    <div class="labelCol">
        Type
    </div>
    <div class="dataCol">
        {{quickQuote.bookingSelection.LSP.type}}
    </div>
</div>

<div class="detailRow">
    <div class="labelCol">
        ETA
    </div>
    <div class="dataCol">
        <span *ngIf="getEta(); let eta">{{eta | checkDate}} (This is just an estimate)</span>
    </div>
</div>
