import { AccessorialForShipment } from './accessorialForShipment';
import { COD } from './cod';
import { Document } from './document';
import { Insurance } from './insurance';
import { Product } from './product';
import { PurchaseOrder } from './purchaseOrder';
import { QuotesResponse } from './quotesResponse';
import { Shipment } from './shipment';

export class ShipmentUnified {
  Quote: QuotesResponse = new QuotesResponse();
  Main: Shipment = new Shipment();
  Products: Array<Product> = [];
  Accessorial: Array<AccessorialForShipment> = [];
  Cod: COD = new COD();
  PurchaseOrders: Array<PurchaseOrder> = [];
  Documents: Array<Document> = [];
}
