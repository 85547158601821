import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Injector, Input, Output, ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {map} from 'rxjs/operators';
import {ZipSearchResult} from '../../models/zip.searchResult';
import {SearchService} from "../../services/search.service";
import {SignalsService} from "../../services/signals.service";

@Component({
    selector: 'app-zip-search',
    styleUrls: ['./app-zip-search.component.scss'],
    templateUrl: './app-zip-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppZipSearchComponent,
            multi: true
        }
    ]
})
export class AppZipSearchComponent implements ControlValueAccessor, AfterViewInit {
    @Input()
    public modalTitle: string = '';
    @Input()
    public readOnly: boolean = false;
    @Input()
    public countryForSearch: string = '';
    @Output()
    public busy = new EventEmitter<boolean>();
    @Output()
    public dialogShown = new EventEmitter();
    @ViewChild('zipElement', { static: true }) private focusElement: ElementRef;
/*  It is not desired to have focus of search element for searching purposes via keyboard, so commented this
    out for now, in case that is desired in future.
    @HostBinding('tabindex') tabindex = 0;
    @HostListener('keydown.space', ['$event']) showZipModalOnSpacebar(e) {
        if (this.zipElement.nativeElement !== document.activeElement) {
            e.preventDefault();
            this.isZipSearchModalShown = true;
        }
    }
*/
    public isZipSearchModalShown = false;
    public zipCode = '';
    private value: ZipSearchResult = new ZipSearchResult();
    private control: UntypedFormControl;

    constructor( private _searchService: SearchService,
                 public _signalsService: SignalsService,
                 private injector: Injector ) {
    }

    public ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.control = ngControl.control as UntypedFormControl;
        } else {
            console.log('Zip Search Component is missing form control binding.');
        }
    }

    public writeValue(value: ZipSearchResult) {
        if (!value) {
            this.value = new ZipSearchResult();
        } else {
            this.value = value;
        }
        this.zipCode = (value.zip_code) ? value.zip_code : '';
        setTimeout(() => {
            this.inputChanged(this.zipCode);
        }, 0);
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public hideZipSearchModal(selectedZipResult: ZipSearchResult): void {
        if (selectedZipResult) {
            this.value = selectedZipResult;
            this.valueChanged(this.value);
            this.zipCode = selectedZipResult.zip_code;
        }
        this.showDialog(false);
    }

    public inputChanged(value) {
        if (!this._signalsService.skipZipSearchSignal()) {
            this.busy.emit(true);
            if (value) {
                this._searchService.zipSearchUSDefault(value, this.countryForSearch).pipe(
                    map((response) => {
                        if (response && response.isValid && response.dto &&
                            response.dto.searchResults &&
                            response.dto.searchResults.length &&
                            response.dto.searchResults[0].entities &&
                            response.dto.searchResults[0].entities.length) {
                            this.value = response.dto.searchResults[0].entities[0];
                            this.valueChanged(this.value);
                            this.zipCode = this.value.zip_code;
// RTG: If the database is ever updated to be more valuable for zip code data
// the below could be turned on to allow the zip search component to self validate,
// but for now it is left off
//                    this.control.setErrors(null);
                        } else {
                            this.value = new ZipSearchResult();
                            this.value.zip_code = value;
                            this.zipCode = value;
                            this.value.countryname = this.countryForSearch;
                            this.valueChanged(this.value);
// RTG: If the database is ever updated to be more valuable for zip code data
// the below could be turned on to allow the zip search component to self validate,
// but for now it is left off
//                    this.control.setErrors({'invalidZipCode': true});
                        }
                        this.busy.emit(false);
                    })
                ).subscribe();
            } else {
                this.value = new ZipSearchResult();
                this.zipCode = value;
                this.value.zip_code = value;
                this.value.countryname = this.countryForSearch;
                this.valueChanged(this.value);
                this.busy.emit(false);
// RTG: If the database is ever updated to be more valuable for zip code data
// the below could be turned on to allow the zip search component to self validate,
// but for now it is left off
//            this.control.setErrors({'invalidZipCode': true});
            }
        } else {
            this._signalsService.skipZipSearchSignal.set(false);
        }
    }

    public showDialog(val) {
        this.dialogShown.emit(val);
        this.isZipSearchModalShown = val;
        this.focusElement.nativeElement.focus();
    }

    private valueChanged = (_: any) => {
    };
}
