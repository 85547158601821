import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgModel, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import * as Constants from '../../../constants/constants';
import {CommonDataService} from '../../../services/commonData.service';
import {InsuranceService} from '../../../services/insurance.service';
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {Subscription} from "rxjs";
import {QuotedRate} from "../../../models/quotedRate";
import {BookingOption} from "../../../models/unifiedShipmentObject";
import {validateMoney} from "../../../validators/money.validator";

@Component({
    selector: 'app-insurance-quoting-modal',
    templateUrl: './insurance-quoting-modal.component.html',
    styleUrls: ['./insurance-quoting-modal.component.scss']
})
export class InsuranceQuotingModalComponent implements OnInit, OnDestroy {
    @Input()
    public carrier: QuotedRate;
    @Input()
    public bookingOption: BookingOption;
    @Input()
    public shipmentValue;
    @Output()
    public updateInsurancePurchase = new EventEmitter<any>();
    @ViewChild('shipmentValueModel', { static: false }) shipmentValueModel: NgModel;

    public insuranceCharge: number = null;
    public declaredShipmentValue: number = null;
    public shipmentCost: number = null;
    public declareShipmentValueForm: UntypedFormGroup;
    public totalShipmentCost: number;
    private subs = new Subscription();

    constructor(
        private _commonDataService: CommonDataService,
        private _insuranceService: InsuranceService,
    ) {}

    public async ngOnInit() {
        if (this.shipmentValue) {
            this.declaredShipmentValue = this.shipmentValue;
        }
        document.getElementById('termsOfAgreementContainer').innerHTML = this._commonDataService.insuranceTermsOfAgreement;

        if (this.carrier) {
            this.shipmentCost = this.carrier.finalcharge ?? 0;
        } else if (this.bookingOption) {
            this.shipmentCost = this.bookingOption.rateDetails[0].rates[0].rate.amount;
        } else {
            this.shipmentCost = 0;
        }

        this.totalShipmentCost = this.shipmentCost;

        this.declareShipmentValueForm = new UntypedFormGroup({
            declaredShipmentValue: new UntypedFormControl((this.declaredShipmentValue) ? this.declaredShipmentValue : '', [
                Validators.required,
                validateMoney,
            ])
        });

        this.subs.add(this.declareShipmentValueForm.get('declaredShipmentValue').valueChanges.pipe(
            tap(() => this.insuranceCharge = undefined),
            debounceTime(500),
            distinctUntilChanged(),
            map(() => {
                this.declaredShipmentValue = +this.declareShipmentValueForm.get('declaredShipmentValue').value
                this.requestInsuranceQuotedValue();
            })
        ).subscribe());

        if (this.shipmentValue) {
            await this.requestInsuranceQuotedValue();
        }
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public async requestInsuranceQuotedValue() {
        if (this.declareShipmentValueForm.invalid) {
            return;
        }
        this.declaredShipmentValue = this.declareShipmentValueForm.value.declaredShipmentValue;
        this.shipmentValue = this.declareShipmentValueForm.value.declaredShipmentValue;
        this.insuranceCharge = await this._insuranceService.getInsuranceCharge(this.declareShipmentValueForm.value.declaredShipmentValue).toPromise();
        this.totalShipmentCost = this.shipmentCost + this.insuranceCharge;
    }

    public purchaseInsurance(): void {
        this.updateInsurancePurchase.emit({
            declaredShipmentValue: this.declaredShipmentValue,
            insuranceCharge: this.insuranceCharge
        })
    }

    public closeModal(): void {
        this.updateInsurancePurchase.emit(null);
    }

    public getClassFor(controlName: string) {
        let classes: any = { normal: true };
        let control = this.declareShipmentValueForm.get(controlName);

        if (control) {
            classes.error = control.invalid;
            classes.normal = control.valid;
        }
        return classes;
    }
}
