<div *ngIf="lowestCostCarrier || fastestCarrier" class="csBestCarriersPanel">
    <div *ngIf="lowestCostCarrier" class="csBestCarrier">
        <ng-container *ngIf="bookingOptions">
            <app-best-carrier (infoClick)="doInfoClick([lowestCostCarrier])"
                              [isCarrierKPIShown]="isCarrierKPIShown" (selected)="selectOption($event)"
                              [bestCarrierOption]="lowestCostCarrier"
                              [buttonText]="'Best Price'"></app-best-carrier>
        </ng-container>
        <ng-container *ngIf="!bookingOptions">
            <app-best-carrier (infoClick)="doInfoClick([lowestCostCarrier])"
                              [isCarrierKPIShown]="isCarrierKPIShown" (selected)="selectOption($event)"
                              [rate]="lowestCostCarrier"
                              [buttonText]="'Best Price'"></app-best-carrier>
        </ng-container>
    </div>
    <div *ngIf="fastestCarrier" class="csBestCarrier">
        <ng-container *ngIf="bookingOptions">
            <app-best-carrier (infoClick)="doInfoClick([fastestCarrier])"
                              [isCarrierKPIShown]="isCarrierKPIShown" (selected)="selectOption($event)"
                              [bestCarrierOption]="fastestCarrier"
                              [buttonText]="'Fastest Delivery'"></app-best-carrier>
        </ng-container>
        <ng-container *ngIf="!bookingOptions">
            <app-best-carrier (infoClick)="doInfoClick([fastestCarrier])"
                              [isCarrierKPIShown]="isCarrierKPIShown" (selected)="selectOption($event)"
                              [rate]="fastestCarrier"
                              [buttonText]="'Fastest Delivery'"></app-best-carrier>
        </ng-container>
    </div>
</div>

<div class="csAllCarrierPanel">
    <div class="csCarrierHeaderLine">
        <div class="csCarrierIconColumn">Carrier</div>
        <div class="csCarrierDetailsLines">
            <div class="csCarrierHeaderForData">
                <div class="serviceColumn">Service</div>
                <div class="guarenteeColumn">Gua.</div>
                <div class="timeTransitColumn">Transit</div>
                <div class="densityColumn">Density</div>
                <div *ngIf="isRatedClassShown" class="classColumn">Class</div>
                <div class="typeColumn">Type</div>
                <div class="currencyColumn">Currency</div>
                <div class="lineHaulColumn">Line Haul</div>
                <div class="fuelColumn">Fuel</div>
                <div class="extraColumn">Extra</div>
                <div class="finalColumn">Final</div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="bookingOptions">
        <div class="csCarrierLine" *ngFor="let groupedOption of groupedOptions">
            <div class="csCarrierIconColumn">
                <!-- Remove false && below to re-enable stars rating display for users. -->
                <app-carrier-option-icon (infoClick)="doInfoClick(groupedOption)"
                                         [textStyling]="{'font-size': '0.75em'}"
                                         [imgStyling]="{'max-height': '35px'}"
                                         [isCarrierKPIShown]="false && isCarrierKPIShown"
                                         [carrierOption]="groupedOption[0]"
                                         [showInfoIcon]="true"
                                         infoTooltip="Click for Terminal Details"></app-carrier-option-icon>
            </div>
            <div class="csCarrierDetailsLines">
                <app-carrier-option-details [insuranceCharge]="insuranceCharge" [isRatedClassShown]="isRatedClassShown" [ngClass]="{ 'selectedCarrier': selectedOption === bookingOption }" class="csCarrierDetailLine" (click)="selectOption(bookingOption)" [carrierOption]="bookingOption" *ngFor="let bookingOption of groupedOption"></app-carrier-option-details>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!bookingOptions">
        <div class="csCarrierLine" *ngFor="let groupedRate of groupedRates">
            <div class="csCarrierIconColumn">
                <!-- Remove false && below to re-enable stars rating display for users. -->
                <app-carrier-option-icon (infoClick)="doInfoClick(groupedRate)"
                                         [textStyling]="{'font-size': '0.75em'}"
                                         [imgStyling]="{'max-height': '35px'}"
                                         [isCarrierKPIShown]="false && isCarrierKPIShown"
                                         [rate]="groupedRate[0]"
                                         [showInfoIcon]="true"
                                         infoTooltip="Click for Terminal Details"></app-carrier-option-icon>
            </div>
            <div class="csCarrierDetailsLines">
                <app-carrier-option-details [insuranceCharge]="insuranceCharge" [isRatedClassShown]="isRatedClassShown" [ngClass]="{ 'selectedCarrier': selectedOption === rate }" class="csCarrierDetailLine" (click)="selectOption(rate)" [quotedRate]="rate" *ngFor="let rate of groupedRate"></app-carrier-option-details>
            </div>
        </div>
    </ng-container>
</div>
