import {Component, Input, OnChanges} from '@angular/core';
import * as _ from 'lodash-es';
import moment from 'moment';
import * as Constants from '../../constants/constants';
import * as DateConstants from '../../constants/datetime.constants';
import {
    Accessorial,
    Address,
    HandlingUnit,
    Manifest,
    PackingUnit,
    Product,
    Stop,
    USO
} from '../../models/unifiedShipmentObject';
import {QuickQuoteService} from "../../services/quickQuote.service";

@Component({
    selector: 'app-quote-summary',
    styleUrls: ['./app-quote-summary.component.scss'],
    templateUrl: './app-quote-summary.component.html',
})
export class AppQuoteSummaryComponent implements OnChanges {
    @Input()
    public quickQuote: USO;
    @Input()
    public insuranceCharge = 0;

    public dimensions = '';
    public shipDate = '';
    public origin = '';
    public destination = '';
    public weight = '';
    public cubicLabel = '';
    public cubicValue = '';
    public pallets = '';
    public class = '';
    public densityClass = '';
    public accessorials = '';
    public distance = '';

    constructor() {}

    public ngOnChanges(): void {
        this.updateView();
    }

    private updateView() {
        const originStop: Stop = _.find(this.quickQuote.route, (s: Stop) => s.stopID === 1);
        const destinationStop: Stop = _.find(this.quickQuote.route, (s: Stop) => s.stopID === 2);

        this.shipDate = '';
        if (originStop) {
            if (originStop.window && originStop.window.date) {
                this.shipDate = moment(originStop.window.date).format(DateConstants.UNIFORM_DATE_DISPLAY.format);
            }
        }

        this.origin = this.getAddress(originStop);
        this.destination = this.getAddress(destinationStop);
        this.class = this.getClass(this.quickQuote.manifest);
        this.accessorials = this.getAccessorials(this.quickQuote.accessorials);
        this.pallets = this.getPallets(this.quickQuote.manifest);

        this.distance = '';
        if (this.quickQuote.totalDistance && this.quickQuote.totalDistance.value) {
            this.distance = `${this.quickQuote.totalDistance.value} ${this.quickQuote.totalDistance.uom}`;
        }

        this.densityClass = '';
        if (this.quickQuote.bookingSelection && this.quickQuote.bookingSelection.densityClass) {
            this.densityClass = this.quickQuote.bookingSelection.densityClass.name.toString();
        }

        this.weight = '';
        if (this.quickQuote.totalWeight) {
            this.weight = this.quickQuote.totalWeight.value.toString();
            this.weight += (this.quickQuote.totalWeight.uom === Constants.UNIT_OF_MEASURE_Imperial) ? ' lbs' : ' kg';
        }

        this.cubicValue = '';
        if (this.quickQuote.totalVolume.value) {
            if (this.quickQuote.totalVolume.uom === Constants.UNIT_OF_MEASURE_Imperial) {
                this.cubicLabel = 'Cubic Feet';
                this.cubicValue = (this.quickQuote.totalVolume.value).toFixed(2);
            } else {
                this.cubicLabel = 'Cubic Meters';
                this.cubicValue = (this.quickQuote.totalVolume.value).toFixed(2);
            }
        }

        this.dimensions = this.getDimensions(this.quickQuote.manifest);
    }

    private getPallets(manifest: Array<Manifest>) {
        let m: Manifest;
        let hu: HandlingUnit;
        let palletsString = '';

        for (m of manifest) {
            if (m.handlingUnits) {
                for (hu of m.handlingUnits) {
                    if (hu.quantity) {
                        palletsString += (palletsString) ? ', ' + hu.quantity : hu.quantity;
                    }
                }
            }
        }

        return palletsString;
    }

    private getDimensions(manifest: Array<Manifest>) {
        let m: Manifest;
        let dimensionsString = ''
        for (m of manifest) {
            if (m.dimensions && m.dimensions.length && m.dimensions.width && m.dimensions.height) {
                if (dimensionsString.length) {
                    dimensionsString += ', ';
                }
                dimensionsString += m.dimensions.length + 'x' + m.dimensions.width + 'x' + m.dimensions.height;
            }
        }
        return dimensionsString;
    }

    private getAccessorials(accessorials: Array<Accessorial>) {
        let a: Accessorial;
        let accessorialsString = '';

        for (a of accessorials) {
            accessorialsString += (accessorialsString) ? ', ' + a.name : a.name;
        }
        return accessorialsString;
    }

    private getClass(manifest: Array<Manifest>) {
        let m: Manifest;
        let hu: HandlingUnit;
        let pu: PackingUnit;
        let p: Product;
        let classString = '';

        for (m of manifest) {
            if (m.handlingUnits) {
                for (hu of m.handlingUnits) {
                    if (hu.packingUnits) {
                        for (pu of hu.packingUnits) {
                            if (pu.products) {
                                for (p of pu.products) {
                                    if (p.freightClass && p.freightClass.code) {
                                        classString += (classString) ? ', ' + p.freightClass.name : p.freightClass.name;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return classString;
    }

    private getAddress(stop: Stop) {
        let address: Address;
        let addressString = '';

        if (stop && stop.location && stop.location.address) {
            address = stop.location.address;
            if (address.city) {
                addressString += address.city;
            }
            if (address.state) {
                addressString += (addressString.length) ? ', ' + address.state : address.state;
            }
            if (address.postalCode) {
                addressString += (addressString.length) ? ' ' + address.postalCode : address.postalCode;
            }
        }

        return addressString;
    }
}
