import {
    AfterViewInit,
    Component, ElementRef, Input, ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import moment from 'moment';
import * as DateTimeConstants from '../../constants/datetime.constants';

@Component({
    selector: 'app-time',
    styleUrls: ['./app-time.component.scss'],
    templateUrl: './app-time.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppTimeComponent,
            multi: true
        }
    ]
})
export class AppTimeComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('minElem') minElem: ElementRef;
    @ViewChild('hourElem') hourElem: ElementRef;
    @ViewChild('meridianElem') meridianElem: ElementRef;

    @Input()
    public military = false;

    public value = '00:00:00';
    public hour;
    public minute;
    public meridian;

    constructor() {
    }

    ngAfterViewInit(): void {
        this.valueToModel();
    }

    public valueToModel() {
        let timeParts;
        let time;
        let timeString;
        let meridian;

        if (this.military) {
            timeParts = this.value.split(':');

            this.hour = timeParts[0];
            this.minute = timeParts[1];
        } else {
            timeString = moment(this.value, DateTimeConstants.TIME_FORMAT_24_HOUR_MINUTE_SECOND).format(DateTimeConstants.TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN_WITH_SPACE);
            time = timeString.split(' ')[0];
            timeParts = time.split(':');
            meridian = timeString.split(' ')[1];

            this.hour = timeParts[0];
            this.minute = timeParts[1];
            this.meridian = meridian;
        }
    }

    public modelToValue() {
        let newVal;

        if (this.military) {
            newVal = moment((this.hour === '' ? '00' : this.hour) + ':' + (this.minute === '' ? '00' : this.minute), DateTimeConstants.TIME_FORMAT_24_HOUR_MINUTE_SECOND).format(DateTimeConstants.TIME_FORMAT_24_HOUR_MINUTE_SECOND);
        } else {
            newVal = moment((this.hour === '' ? '00' : this.hour) + ':' + (this.minute === '' ? '00' : this.minute) + this.meridian, DateTimeConstants.TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN).format(DateTimeConstants.TIME_FORMAT_24_HOUR_MINUTE_SECOND);
        }

        if (newVal === 'Invalid date') {
            newVal = '00:00:00';
        }

        this.value = newVal;
        this.valueChanged(this.value);
    }

    public writeValue(value: string) {
        this.value = value;
        this.valueToModel();
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public changeHour($event) {
        if ($event.key === 'ArrowUp') {
            this.adjustHour(+1);
        } else if ($event.key === 'ArrowDown') {
            this.adjustHour(-1);
        }
    }

    public changeMinute($event: KeyboardEvent) {
        if ($event.key === 'ArrowUp') {
            this.adjustMinute(+1);
        } else if ($event.key === 'ArrowDown') {
            this.adjustMinute(-1);
        }
    }

    public changeMeridian($event: KeyboardEvent) {
        if ($event.key === 'ArrowUp') {
            this.meridian = 'PM';
        } else if ($event.key === 'ArrowDown') {
            this.meridian = 'AM';
        } else if ($event.key === ' ') {
            if (this.meridian.toUpperCase()[0] === 'A') {
                this.meridian = 'PM';
            } else {
                this.meridian = 'AM';
            }
        }
        this.modelToValue();
    }

    public adjustHour(inc = +1) {
        const max = (this.military) ? 23 : 12;
        const min = (this.military) ? 0 : 1;
        let hour;
        if (this.hour === '') {
            hour = 0;
        } else {
            hour = parseInt(this.hour, 10);
        }
        if (inc > 0) {
            if (hour < max) {
                hour++;
            }
        } else {
            if (hour > min) {
                hour--;
            }
        }
        this.hour = hour.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        this.modelToValue();
    }

    public adjustMinute(inc = +1) {
        const max = 59;
        const min = 0;
        let minute;
        if (this.minute === '') {
            minute = 0;
        } else {
            minute = parseInt(this.minute, 10);
        }
        if (inc > 0) {
            if (minute < max) {
                minute++;
            }
        } else {
            if (minute > min) {
                minute--;
            }
        }
        this.minute = minute.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        this.modelToValue();
    }

    public hourChanged($event) {
        const prevHour = this.hour;

        if ((this.military) ? $event.match(/^($|[0-9]|[01][0-9]|[2][0-3])$/) : $event.match(/^($|[0-9]|[0][0-9]|[1][0-2])$/)) {
            this.hour = $event;
        } else {
            this.hour = prevHour;
            this.hourElem.nativeElement.value = prevHour;  // have to update the element since value didn't actually
                                                           // change and angular won't update the view if no value change.
        }
        this.modelToValue();
    }

    public minuteChanged($event) {
        const prevMin = this.minute;

        if ($event.match(/^($|[0-9]|[0-5][0-9])$/)) {
            this.minute = $event;
        } else {
            this.minute = prevMin;
            this.minElem.nativeElement.value = prevMin;  // have to update the element since value didn't actually
                                                         // change and angular won't update the view if no value change.
        }
        this.modelToValue();
    }

    public meridianChanged($event) {
        const prevMeridian = this.meridian;

        if ($event.match(/^($|[Aa]|[Pp]|[Aa][Mm]|[Pp][Mm])$/)) {
            this.meridian = $event;
        } else {
            this.meridian = prevMeridian;
            this.meridianElem.nativeElement.value = prevMeridian;  // have to update the element since value didn't actually
                                                                   // change and angular won't update the view if no value change.
        }
        this.modelToValue();
    }

    private valueChanged = (_: any) => {
    };
}
