import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import * as _ from 'lodash-es';
import moment from 'moment';
import {Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {DATE_FORMAT_yyyymmdd} from '../../../../constants/datetime.constants';
import * as DateConstants from '../../../../constants/datetime.constants';
import {DateTimeHelper} from '../../../../helpers/datetime.helper';
import {clearArray, unsubscribe} from '../../../../helpers/utilities';
import {MarkupsService} from '../../../../services/markups.service';
import {BreadcrumbService} from "../../../../services/breadcrumb.service";
import {AdminButtonPanelService} from "../../../../services/adminButtonPanel.service";

@Component({
    selector: 'app-admin-markup-carriers',
    templateUrl: './admin-markup-carriers.component.html',
    styleUrls: ['./admin-markup-carriers.component.scss']
})
export class AdminMarkupCarriersComponent implements OnInit, OnDestroy {
    public markupId;
    public exceptionIndex;
    public markupForm: UntypedFormGroup;
    public activeItems = [];
    public pastItems = [];
    public exceptionGroup: UntypedFormGroup;
    public isAddActiveItemsModalShown = false;
    public isDatePickerShown = false;
    public markupDisplayDateFormat: string = DateConstants.UNIFORM_DATE_DISPLAY.format;
    public markupDisplayDateValidation: RegExp = DateConstants.UNIFORM_DATE_DISPLAY.validation;
    public selectedItemDateFormGroup: UntypedFormGroup;
    public isCarrier;
    private markupFormChangeSubscription: Subscription;
    private activeIndexToItemIndex: Map<number, number> = new Map<number, number>();

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _breadcrumbService: BreadcrumbService,
        private _adminButtonPanelService: AdminButtonPanelService,
        private _markupsService: MarkupsService,
        private _dateHelper: DateTimeHelper,
    ) {}

    public ngOnDestroy(): void {
        this._breadcrumbService.popBreadcrumb();
        unsubscribe(this.markupFormChangeSubscription);
    }

    public ngOnInit(): void {
        this._adminButtonPanelService.showSaveCancelButtons(true);

        this._adminButtonPanelService.register({
            saveEvent: () => {
                this.save();
            },
            cancelEvent: () => {
                this.navigateBackToMarkup();
            },
        });

        this.isCarrier = this._router.url.includes('/admin/markups/carrier/');

        this._activatedRoute.params.pipe(
            take(1),
            map((route: Params) => {
                this.exceptionIndex = this._activatedRoute.snapshot.paramMap.get('exceptionIndex');
                this.markupId = this._activatedRoute.snapshot.paramMap.get('markupId');
                this.markupForm = this._markupsService.getLastMarkupForm();

                if (!this.exceptionIndex || !this.markupId || !this.markupForm) {
                    this._router.navigate(['admin/markups']);
                    return;
                }

                this.markupForm = _.cloneDeep(this.markupForm);
                this.setActiveAndPastItems();
                this.markupFormChangeSubscription = this.markupForm.valueChanges.subscribe((info) => {
                    this.setActiveAndPastItems();
                    this.activateButtons();
                });

                if (this.isCarrier) {
                    this._breadcrumbService.addBreadcrumb([
                        {
                            label: 'Carriers',
                            urlValue: 'admin/markups/carrier/' + this.markupId + '/' + this.exceptionIndex
                        }
                    ]);
                } else {
                    this._breadcrumbService.addBreadcrumb([
                        {
                            label: 'Accessorials',
                            urlValue: 'admin/markups/accls/' + this.markupId + '/' + this.exceptionIndex
                        }
                    ]);
                }
            })
        ).subscribe();
    }

    public showDatePicker(exceptionLineItemIndex: number): void {
        this.selectedItemDateFormGroup = this.activeItems[exceptionLineItemIndex];
        this.isDatePickerShown = true;
    }

    public hideDatePicker({selectedDate, selectedEndDate}: { selectedDate: string, selectedEndDate?: string }): void {
        if (selectedDate) {
            this.selectedItemDateFormGroup.get('effective').setValue(selectedDate);
        }

        if (selectedEndDate) {
            this.selectedItemDateFormGroup.get('end').setValue(selectedEndDate);
        }
        this.isDatePickerShown = false;
    }

    public doAddItemsModal(): void {
        this.isAddActiveItemsModalShown = true;
    }

    public removeExceptionItem(exceptionLineItemIndex: number): void {
        const itemsFormArray: UntypedFormArray = this.exceptionGroup.get(this.selectFieldName()) as UntypedFormArray;

        itemsFormArray.removeAt(this.activeIndexToItemIndex.get(exceptionLineItemIndex));
        this.setActiveAndPastItems();
    }

    public hideAddActiveItemsModal(activeItems?): void {
        const itemsFormArray: UntypedFormArray = this.exceptionGroup.get(this.selectFieldName()) as UntypedFormArray;
        let item;
        let activeItem;

        this.isAddActiveItemsModalShown = false;

        for (activeItem of activeItems) {
            item = {};
            item.SCAC = activeItem.code;
            item.code = activeItem.code;
            item.effective = this._dateHelper.prepareDate(moment().format(this.markupDisplayDateFormat), DATE_FORMAT_yyyymmdd);
            item.end = this._dateHelper.prepareDate(DateConstants.DATE_VALUE_INDEFINITELY_display, DATE_FORMAT_yyyymmdd);
            if (this.isCarrier) {
                itemsFormArray.push(this._markupsService.carrierToFormGroup(item));
            } else {
                itemsFormArray.push(this._markupsService.accessorialToFormGroup(item));
            }
        }

        this.setActiveAndPastItems();
    }

    private activateButtons() {
        if (this.isCarrier) {
            if (this.markupForm.controls.markupID.value && this._markupsService.getLastMarkup().exceptions[this.exceptionIndex] &&
                this._markupsService.carriersEqual(
                    this.markupForm.controls.exceptions.value[this.exceptionIndex].exceptionCarriers,
                    this._markupsService.getLastMarkup().exceptions[this.exceptionIndex].exceptionCarriers)) {
                this.markupForm.markAsPristine();
            } else {
                this.markupForm.markAsDirty();
            }
        } else {
            if (this.markupForm.controls.markupID.value && this._markupsService.getLastMarkup().exceptions[this.exceptionIndex] &&
                this._markupsService.acclsEqual(
                    this.markupForm.controls.exceptions.value[this.exceptionIndex].exceptionAccessorials,
                    this._markupsService.getLastMarkup().exceptions[this.exceptionIndex].exceptionAccessorials)) {
                this.markupForm.markAsPristine();
            } else {
                this.markupForm.markAsDirty();
            }
        }

        this._adminButtonPanelService.activateSaveButton(this.markupForm.dirty && this.activeItemsValid());
    }

    private activeItemsValid() {
        let item;

        for (item of (this.exceptionGroup.controls[this.selectFieldName()] as UntypedFormArray).controls) {
            if (item.invalid) {
                return false;
            }
        }
        return true;
    }

    private save() {
        this._markupsService.setLastMarkupForm(this.markupForm);
        this.navigateBackToMarkup();
    }

    private navigateBackToMarkup(): void {
        this._router.navigate(['admin/markups', this.markupId]);
    }

    private setActiveAndPastItems(): void {
        let item;
        let idx = 0;

        this.activeIndexToItemIndex.clear();
        clearArray(this.activeItems);
        clearArray(this.pastItems);
        this.exceptionGroup = ((this.markupForm.controls.exceptions as UntypedFormArray).at(this.exceptionIndex) as UntypedFormGroup);
        for (item of (this.exceptionGroup.controls[this.selectFieldName()] as UntypedFormArray).controls) {
            if (this.isPastItem(item)) {
                this.pastItems.push(item);
            } else {
                this.activeIndexToItemIndex.set(this.activeItems.length, idx);
                this.activeItems.push(item);
            }
            idx++;
        }
    }

    private isPastItem(item: UntypedFormGroup): boolean {
        const todayMoment: moment.Moment = moment();
        let endDateMoment: moment.Moment;

        if (!item.controls.end.value) {
            return false;
        }

        if (item.controls.end.value === DateConstants.DATE_VALUE_INDEFINITELY_display) {
            endDateMoment = moment(DateConstants.DATE_VALUE_INDEFINITELY, DateConstants.DATE_FORMAT_yyyymmdd);
        } else {
            endDateMoment = moment(item.controls.end.value, DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES);
        }

        return !todayMoment.isSameOrBefore(endDateMoment, 'day');
    }

    private selectFieldName() {
        return (this.isCarrier) ? 'exceptionCarriers' : 'exceptionAccessorials';
    }
}
