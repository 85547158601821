import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash-es';
import {of} from 'rxjs';
import {Subscription} from 'rxjs';
import {map, switchMap, shareReplay} from 'rxjs/operators';
import * as Constants from '../../constants/constants';
import * as DateConstants from '../../constants/datetime.constants';
import {AccessorialForQuote} from '../../models/accessorialForQuote';
import {AccessorialGroup} from '../../models/accessorialGroup';
import {Country} from '../../models/country';
import {Stop, USO} from '../../models/unifiedShipmentObject';
import {ZipSearchResult} from '../../models/zip.searchResult';
import {CommonDataService} from '../../services/commonData.service';
import {PATH_CHANGE, PATH_ENTRY, PATH_NEW, QuickQuoteService} from '../../services/quickQuote.service';
import {NotificationService} from "../../services/notification.service";
import * as RecordCreationConstants from '../../constants/recordCreation.constants';
import { ContactSearchResult } from 'app/models/contact.searchResult';
import {SignalsService} from "../../services/signals.service";
import {ContactImpl, WindowImpl} from "../../models/usoImpl";

@Component({
    selector: 'app-quick-quote',
    styleUrls: ['./quick-quote.component.scss'],
    templateUrl: './quick-quote.component.html',
})
export class QuickQuoteComponent implements OnInit, OnDestroy {
    public displayDateFormat: string = DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES;
    public displayDateValidation: RegExp = DateConstants.VALID_DATE_REGEX_MM_DD_YYYY_Slash;
    public unitOfMeasure;
    public equipmentTypes = [];
    public countries = [];
    public unitOfMeasures = [];
    public availableAccessorials = [];
    public originCountry: Country = new Country();
    public destinationCountry: Country = new Country();
    public quickQuoteForm: UntypedFormGroup;
    public disableBookingOptions = true;
    public dialogUp;
    public isOkCancelModalVisible = false;
    public zipOriginBusy = false;
    public zipDestinationBusy = false;
    public acclGroups: Array<AccessorialGroup>;
    public insuranceOn = false;
    public isInsuranceModalShown = false;
    public shipmentValueFromForm;
    public recordCreationConstants = RecordCreationConstants;
    private quickQuoteId;
    private subs = new Subscription();

    constructor(private _route: ActivatedRoute,
                private _commonDataService: CommonDataService,
                public  _quickQuoteService: QuickQuoteService,
                private _notificationService: NotificationService,
                private _router: Router,
                public _signalsService: SignalsService,
                ) { }

    public getCountryLabel(country: Country) {
        return (country) ? country.countryCode : undefined;
    }

    public async ngOnInit() {
        const naEquipmentType: any = {};
        naEquipmentType.description = 'N/A';
        naEquipmentType.id = 0;
        this.equipmentTypes = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.equipmentTypes));
        this.equipmentTypes.unshift(naEquipmentType);
        this.countries = await this._commonDataService.loadedPromise(this._commonDataService.countries);
        this.unitOfMeasures = await this._commonDataService.loadedPromise(this._commonDataService.unitOfMeasures)
        this.unitOfMeasure = _.find(this.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === Constants.UNIT_OF_MEASURE_Imperial);
        this.availableAccessorials = await this._commonDataService.loadedPromise(this._commonDataService.availableAccessorials);
        this.acclGroups = await this._commonDataService.loadedPromise(this._commonDataService.accessorialGroups);

        const quickQuoteForm$ = this._route.params.pipe(
            switchMap((params) => {
                this.quickQuoteId = params['quickQuoteId'];
                if ((this.quickQuoteId === PATH_CHANGE) || ((this.quickQuoteId === PATH_ENTRY) &&
                    !this._quickQuoteService.getLastQuickQuote())) {
                    this._router.navigate(['quick-quote', PATH_NEW]);
                    return;
                }
                return this._quickQuoteService.getQuickQuoteByPath(this.quickQuoteId);
            }),
            switchMap(() => {
                if (!this.quickQuoteForm) {
                    this.quickQuoteForm = this._quickQuoteService.getQuickQuoteFormFromLastQuickQuote();
                } else {
                    this._quickQuoteService.updateForm(this.quickQuoteForm);
                }
                this.setInsuranceInput();
                this.unitOfMeasure = _.find(this.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === this.quickQuoteForm.controls.unitOfMeasure.value.unitOfMeasureCode);
                this.quickQuoteForm.controls.unitOfMeasure.setValue(this.unitOfMeasure);
                this.activateButtons();

                return of(this.quickQuoteForm);
            }),
            shareReplay()
        );

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.get('origin').valueChanges),
            map(() => this.zipChanged('originCountry', 'origin'))
        ).subscribe());

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.get('destination').valueChanges),
            map(() => this.zipChanged('destinationCountry', 'destination'))
        ).subscribe());

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.get('originCountry').valueChanges),
            map(() => this.countryChanged('originCountry', 'origin'))
        ).subscribe());

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.get('destinationCountry').valueChanges),
            map(() => this.countryChanged('destinationCountry', 'destination'))
        ).subscribe());

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.valueChanges),
            map(() => this.activateButtons())
        ).subscribe());

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.get('lines').valueChanges),
            map(() => this.enforceHazmatAccessorial())
        ).subscribe());

        this.subs.add(quickQuoteForm$.pipe(
            switchMap(() => this.quickQuoteForm.get('accessorials').valueChanges),
            map(() => this.enforceHazmatAccessorial(true))
        ).subscribe());
    }

    private setInsuranceInput() {
        if (this._quickQuoteService.lastShipmentValue) {
            this.insuranceOn = true;
            setTimeout(() => {}, 0);
        } else {
            this.quickQuoteForm.get('shipmentValue').disable();
        }
    }

    public countryChanged(countryDDName, zipSearchName) {
        const countryDDValue: Country = this.quickQuoteForm.get(countryDDName).value;
        const zipSearchValue: ZipSearchResult = this.quickQuoteForm.get(zipSearchName).value;
        const newZipSearchValue = new ZipSearchResult();
        this[countryDDName] = countryDDValue;
        newZipSearchValue.countryname = (countryDDValue) ? countryDDValue.countryCode : undefined;
        newZipSearchValue.zip_code = zipSearchValue.zip_code;
        if (zipSearchValue.countryname !== ((countryDDValue) ? countryDDValue.countryCode : undefined)) {
            zipSearchValue.countryname = (countryDDValue) ? countryDDValue.countryCode : undefined;
            this.quickQuoteForm.get(zipSearchName).setValue(newZipSearchValue);
        }
    }

    public zipChanged(countryDDName, zipSearchName) {
        const zipSearchValue: ZipSearchResult = this.quickQuoteForm.get(zipSearchName).value;
        const countryDDValue: Country = this.quickQuoteForm.get(countryDDName).value;
        this[countryDDName] = _.find(this.countries, (country: Country) => country.countryCode === zipSearchValue.countryname);
        if (!this[countryDDName] || ((countryDDValue ? countryDDValue.countryCode : undefined) !== this[countryDDName].countryCode)) {
            this.quickQuoteForm.get(countryDDName).setValue(this[countryDDName]);
        }
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public doGetQuote() {
        let quote;

        if (this.quickQuoteForm.valid) {
            if (this.quickQuoteForm.get('shipmentValue')?.enabled) {
                this.shipmentValueFromForm = this.quickQuoteForm.get('shipmentValue').value;
                this.isInsuranceModalShown = true;
                return;
            }
            quote = this._quickQuoteService.getQuoteFromForm(this.quickQuoteForm);
            this.getBookingOptions(quote);
        }
    }

    public getBookingOptions(quote) {
        this._quickQuoteService.getBookingOptions(quote).pipe(
            map((response: USO) => {
                this._quickQuoteService.updateLastQuote(response);
                this._router.navigate(['quick-quote-display', PATH_ENTRY]);
            })
        ).subscribe();
    }

    public getQuote() {
        if (this.zipOriginBusy || this.zipDestinationBusy) {
            setTimeout(() => {
                this.getQuote();
            }, 50);
        } else {
            this.doGetQuote();
        }
    }

    public clearQuote(showOkCancelModal = true) {
        if (showOkCancelModal) {
            this.isOkCancelModalVisible = true;
            return;
        } else {
            this.isOkCancelModalVisible = false;
            this._quickQuoteService.clearLastQuote();
            if (this.quickQuoteId === PATH_NEW) {
                this._quickQuoteService.updateForm(this.quickQuoteForm);
            }
            this._router.navigate(['quick-quote', PATH_NEW]);
        }
    }

    public uomChanged(newUom) {
        this.quickQuoteForm.controls.unitOfMeasure.setValue(newUom);
    }

    public checkForEnter($event: KeyboardEvent) {
        if ((!this.dialogUp) && ($event.keyCode === 13)) {
            this.getQuote();
        }
    }

    private activateButtons() {
        setTimeout(() => {
            this.disableBookingOptions = this.quickQuoteForm.invalid;
        }, 10);
    }

    private addAccessorialToForm(accessorial: AccessorialForQuote) {
        const linesControl: UntypedFormArray = this.quickQuoteForm.get('accessorials') as UntypedFormArray;
        const accessorials: Array<AccessorialForQuote> = linesControl.value;
        let acc: AccessorialForQuote;

        for (acc of accessorials) {
            if (acc.code === accessorial.code) {
                return false;
            }
        }

        accessorials.push(accessorial);
        this.quickQuoteForm.get('accessorials').setValue(accessorials);
        return true;
    }

    private enforceHazmatAccessorial(notifyOnEnforcement = false) {
        let line;
        let accessorial: AccessorialForQuote;

        for (line of this.quickQuoteForm.value.lines) {
            if (line.hazmat) {
                accessorial = _.find(this.availableAccessorials, (acc: AccessorialForQuote) => acc.code === 'HAZMAT');
                if (this.addAccessorialToForm(accessorial) && notifyOnEnforcement) {
                    this._notificationService.notifyWarning({ title: 'Hazmat Enforcement', message: `Hazmat accessorial required for ${line.name}.` });
                }
                break;
            }
        }
    }

    public toggleInsurance(val: boolean) {
        if (val) {
            this.quickQuoteForm.get('shipmentValue')?.enable();
        } else {
            this.quickQuoteForm.get('shipmentValue').setValue('');
            this.quickQuoteForm.get('shipmentValue')?.disable();
            this._quickQuoteService.lastInsuranceCharge = 0;
            this._quickQuoteService.lastShipmentValue = 0;
        }
        this.insuranceOn = val;
    }

    public async closeInsuranceQuotingModal(insInfo) {
        let quote;

        this.isInsuranceModalShown = false;
        if (insInfo) {
            this._quickQuoteService.lastShipmentValue = insInfo.declaredShipmentValue;
            this.quickQuoteForm.get('shipmentValue').setValue(insInfo.declaredShipmentValue);
            this._quickQuoteService.lastInsuranceCharge = insInfo.insuranceCharge;
            quote = this._quickQuoteService.getQuoteFromForm(this.quickQuoteForm);
            this.getBookingOptions(quote);
        }
    }

    private vlscfsContactTransform(contact: ContactSearchResult) {
        const zipResult: ZipSearchResult = new ZipSearchResult();
        zipResult.window = new WindowImpl();
        zipResult.contact = new ContactImpl();

        zipResult.name = contact.name;
        zipResult.addressLines = [contact.address1, contact.address2];
        zipResult.city = contact.city;
        zipResult.state = contact.state;
        zipResult.zip_code = '' + contact.zip;
        zipResult.countryname = contact.country;
        zipResult.type = contact.commercialresidential;
        zipResult.appointmentRequired = !!contact.callappt;
        zipResult.window.times = [{ startTime: contact.hoursopen, endTime: contact.hoursclose }];
        zipResult.contact.fax.phoneNumber = contact.fax;
        zipResult.contact.phoneWork.phoneNumber = contact.phone;
        zipResult.contact.email = contact.email;

        return zipResult;
    }

    public contactChanged(contact: ContactSearchResult, contactType: string) {
        this._signalsService.skipZipSearchSignal.set(true);
        const control = (contactType == RecordCreationConstants.VANGUARD_CFS_CONTACT_TYPE.ORIGIN) ? this.quickQuoteForm.controls.origin : this.quickQuoteForm.controls.destination;
        control.setValue(this.vlscfsContactTransform(contact));
    }
}
