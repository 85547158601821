import { Injectable } from '@angular/core';

import * as SearchConstants from '../constants/searchCriteria';
import { ContactSearchResult } from '../models/contact.searchResult';
import { Favorite } from '../models/favorite';
import { FavoriteRequest } from '../models/favoriteRequest';
import { InvoiceSearchResult } from '../models/invoice.searchResult';
import { ProductSearchResult } from '../models/product.searchResult';
import { QuoteSearchResult } from '../models/quote.searchResult';
import { SearchRequest } from '../models/searchRequest';
import { ShipmentSearchResult } from '../models/shipment.searchResult';
import { UserSearchResult } from '../models/user.searchResult';
import {SignalsService} from "./signals.service";
import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from "./api.service";
import {ResponseDTO} from "../models/responseDto";
import {NotificationService} from "./notification.service";

@Injectable()
export class FavoritesService {
    constructor(
        private _signalsService: SignalsService,
        private _api: ApiService,
        private _notificationService: NotificationService,
    ) { }

    public getUserFavorites(): Array<Favorite> {
        return this._signalsService.favoritesSignal();
    }

    public getFavorite(entityType: string, entity: any): Favorite {
        const userFavorites = this.getUserFavorites();
        const favoriteId = this.getFavoriteIdentifier(entityType, entity);
        return userFavorites.find((fav: Favorite) => fav.favoriteType === entityType && fav.favoriteIdentifier === favoriteId);
    }

    public getFavoriteIdentifier(entityType: string, entity: any): string {
        let favoriteIdentifier: string;

        switch (entityType) {
            case SearchConstants.SEARCH_CRITERIA_entityType.SHIPMENTS:
                const shipment = entity as ShipmentSearchResult;
                favoriteIdentifier = `${shipment.id}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.INVOICES:
                const invoice = entity as InvoiceSearchResult;
                favoriteIdentifier = `${invoice.id}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS:
                const contact = entity as ContactSearchResult;
                favoriteIdentifier = `${contact.contactsid}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_PRODUCTS:
                const product = entity as ProductSearchResult;
                favoriteIdentifier = `${product.productid}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.QUOTES:
                const quote = entity as QuoteSearchResult;
                favoriteIdentifier = `${quote.id}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.USERS:
                const user = entity as UserSearchResult;
                favoriteIdentifier = `${user.userid}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.SEARCH:
                const search = entity as SearchRequest;
                favoriteIdentifier = JSON.stringify(search);
                break;

            default:
                break;
        }

        return favoriteIdentifier;
    }

    public getDefaultFavoriteName(entityType: string, entity: any): string {
        let defaultFavoriteName: string;

        switch (entityType) {
            case SearchConstants.SEARCH_CRITERIA_entityType.SHIPMENTS:
                const shipment = entity as ShipmentSearchResult;
                defaultFavoriteName = `Shipment-${shipment.bol}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.INVOICES:
                const invoice = entity as InvoiceSearchResult;
                defaultFavoriteName = `Invoice-${invoice.id}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS:
                const contact = entity as ContactSearchResult;
                defaultFavoriteName = `Contact-${contact.name}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_PRODUCTS:
                const product = entity as ProductSearchResult;
                defaultFavoriteName = `Product-${product.name}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.QUOTES:
                const quote = entity as QuoteSearchResult;
                defaultFavoriteName = `Quote-${quote.carrier}-${quote.id}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.USERS:
                const user = entity as UserSearchResult;
                defaultFavoriteName = `User-${user.name}`;
                break;

            case SearchConstants.SEARCH_CRITERIA_entityType.SEARCH:
                const search = entity as SearchRequest;
                defaultFavoriteName = JSON.stringify(search);
                break;

            default:
                break;
        }

        return defaultFavoriteName;
    }

    public getDefaultFavoriteDescription(entityType: string, entity: any): string {
        return '';
    }

    public isFavorite(entityType: string, entity: any): boolean {
        const matchedFavorite = this.getFavorite(entityType, entity);

        if (matchedFavorite) {
            return true;
        }
        return false;
    }

    public getFavoriteNumber(entityType: string, entity: any): number {
        const matchedFavorite = this.getFavorite(entityType, entity);
        if (matchedFavorite) {
            return matchedFavorite.favoriteNo;
        }

        return null;
    }

    public prepareFavoriteRequest(entityType: string, entity: any, favoriteName?: string, favoriteDescription?: string): FavoriteRequest {
        const favoriteId = this.getFavoriteIdentifier(entityType, entity);
        const defaultFavoriteName = this.getDefaultFavoriteName(entityType, entity);
        const defaultFavoriteDescription = this.getDefaultFavoriteDescription(entityType, entity);

        return {
            favoriteIdentifier: favoriteId,
            favoriteType: entityType,
            favoriteName: favoriteName || defaultFavoriteName,
            favoriteDescription: favoriteDescription || defaultFavoriteDescription
        };
    }

    public loadFavorites(): void {
        const loadFavoritesReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Favorites`);
        this._api.callApiService<ResponseDTO<Array<Favorite>>>(loadFavoritesReq).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this._signalsService.favoritesSignal.set(response.dto);
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Load User Favorites' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Load User Favorites' });
            });
    }

    public addFavorite(favoriteToAdd: FavoriteRequest): void {
        const addFavoriteReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Favorites`, [favoriteToAdd]);
        this._api.callApiService<ResponseDTO<Array<Favorite>>>(addFavoriteReq).subscribe(
            (response) => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    this._signalsService.addUserFavorite(response.dto);
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Add Favorite' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Add Favorite' });
            });
    }

    public addMultipleFavorites(arrayOfFavorites: Array<FavoriteRequest>): void {
        const addMultipleFavoritesReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Favorites`, arrayOfFavorites);
        this._api.callApiService<ResponseDTO<Array<Favorite>>>(addMultipleFavoritesReq).subscribe(
            (response) => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    this._signalsService.addUserFavorite(response.dto);
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Add Favorites' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Add Favorites' });
            });
    }

    public removeFavorite(favoriteNo: number): void {
        const removeFavoriteReq = new HttpRequest(REQUEST_TYPE_DELETE, `MasterData/Favorites/${favoriteNo}`);
        this._api.callApiService<ResponseDTO<any>>(removeFavoriteReq).subscribe(
            (response) => {
                if (response && response.isValid) {
                    this.loadFavorites();
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Remove Favorite' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Remove Favorite' });
            });
    }

    public removeMultipleFavorites(arrayOfFavoriteNos: Array<{ favoriteNo: number }>): void {
        const removeMultipleFavoritesReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Favorites/DeleteMultiple`, arrayOfFavoriteNos);
        this._api.callApiService<ResponseDTO<any>>(removeMultipleFavoritesReq).subscribe(
            (response) => {
                if (response && response.isValid) {
                    this.loadFavorites();
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Remove Multiple Favorites' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Remove Multiple Favorites' });
            });
    }
}
