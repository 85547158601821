import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as _ from 'lodash-es';
import {clearArray} from '../../helpers/utilities';
import {AccessorialForQuote} from '../../models/accessorialForQuote';
import {AccessorialGroup} from '../../models/accessorialGroup';
import {CommonDataService} from '../../services/commonData.service';

const DISPLAY_GROUP_CODE = 'DISPLAY';
const ALL_ACCESSORIALS_GROUP_CODE = 'ALLACCESSORIALS';

@Component({
    selector: 'app-accessorials',
    styleUrls: ['./app-accessorials.component.scss'],
    templateUrl: './app-accessorials.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppAccessorialsComponent,
            multi: true
        }
    ]
})
export class AppAccessorialsComponent implements ControlValueAccessor, OnInit {
    @Input()
    public groups: Array<AccessorialGroup> = [];
    @Input()
    public tabindex = 0;
    @Output()
    public dialogShown = new EventEmitter();

    public alwaysShow = [];
    public shownList = [];
    public value: Array<AccessorialForQuote> = [];
    public alwaysShowSet: Set<string> = new Set<string>();
    public valueMap: Map<string, AccessorialForQuote> = new Map<string, AccessorialForQuote>();
    public availableAccessorialsMap: Map<string, AccessorialForQuote> = new Map<string, AccessorialForQuote>();
    public showNumberModal = false;
    public showAccessorialModal = false;
    public payload: any;
    public groupShown: AccessorialGroup;
    public allAccessorialsGroup: AccessorialGroup;

    private availableAccessorials;

    constructor(private _commonDataService: CommonDataService) {}

    public ngOnInit() {
        let accessorial: AccessorialForQuote;
        let group: AccessorialGroup;
        let acclCode: string;

        for (group of this.groups) {
            if (this.isDisplayGroup(group.code)) {
                for (acclCode of group.accls) {
                    if (!this.alwaysShowSet.has(acclCode)) {
                        this.alwaysShowSet.add(acclCode);
                        this.alwaysShow.push(acclCode);
                    }
                }
            } else if (this.isAllAccessorialsGroup(group.code)) {
                this.allAccessorialsGroup = group;
            }
        }

        this.availableAccessorials = _.cloneDeep(this._commonDataService.availableAccessorials);
        for (accessorial of this.availableAccessorials) {
            this.availableAccessorialsMap.set(accessorial.code, accessorial);
        }

        for (accessorial of this.value) {
            this.valueMap.set(accessorial.code, this.availableAccessorialsMap.get(accessorial.code));
        }
        this.buildShownList();
    }

    public writeValue(value) {
        let accessorial: AccessorialForQuote;
        let mappedAccessorial: AccessorialForQuote;

        if (value !== null) {
            this.value = _.cloneDeep(value);
            this.valueMap.clear();
            this.buildShownList();
            for (accessorial of value) {
                mappedAccessorial = this.availableAccessorialsMap.get(accessorial.code);
                if (mappedAccessorial && (accessorial.value !== undefined)) {
                    mappedAccessorial.value = accessorial.value;
                }
                this.valueMap.set(accessorial.code, mappedAccessorial);
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = (value: any) => {
            fn(value);
        };
    }

    public registerOnTouched(fn: any): void {
    }

    public buildShownList() {
        let accessorialCode;
        let accessorial;

        clearArray(this.shownList);
        for (accessorial of this.value) {
            if (!this.alwaysShowSet.has(accessorial.code)) {
                this.alwaysShowSet.add(accessorial.code);
                this.alwaysShow.push(accessorial.code)
            }
        }

        for (accessorialCode of this.alwaysShow) {
            if (this.availableAccessorialsMap.has(accessorialCode)) {
                this.shownList.push(this.availableAccessorialsMap.get(accessorialCode));
            }
        }
    }

    public checkboxChanged(code, val: any) {
        if (!val) {
            this.deleteAccessorial(code);
        } else {
            this.checkAccessorial(code);
        }
        this.valueChanged(this.value);
    }

    public changeAccessorialValue(name) {
        const accessorial = this.availableAccessorialsMap.get(name);

        this.payload = accessorial;
        this.showNumberModal = true;
        this.dialogShown.emit(true);
    }

    public hideNumberModal($event) {
        let idx;
        let accessorial: AccessorialForQuote;
        if ($event) {
            accessorial = this.availableAccessorialsMap.get($event.code);
            idx = _.findIndex(this.value, (val) => val.code === $event.code);
            if (idx === -1) {
                idx = this.value.length;
                this.value.push(_.cloneDeep(accessorial));
                this.valueMap.set(accessorial.code, accessorial);
            }
            accessorial.value = +$event.value;
            this.value[idx].value = +$event.value;
            this.valueChanged(this.value);
        }
        this.showNumberModal = false;
        this.dialogShown.emit(false);
    }

    public cancelNumberModal($event) {
        if ($event.value === undefined) {
            this.deleteAccessorial($event.code);
        }
        this.showNumberModal = false;
    }

    public accessorialSelectorCancelled() {
        this.showAccessorialModal = false;
        this.dialogShown.emit(false);
    }

    public receiveAccessorials($event) {
        let availAccessorial: AccessorialForQuote;
        let accessorial: AccessorialForQuote;
        const valueMapAdds = [];
        const nonGroupAccessorials: Array<AccessorialForQuote> = [];

        if ($event !== null) {
            for (accessorial of this.value) {
                if (!_.find(this.groupShown.accls, (code) => code === accessorial.code)) {
                    nonGroupAccessorials.push(accessorial);
                }
            }

            clearArray(this.value);
            this.valueMap.clear();

            for (accessorial of nonGroupAccessorials) {
                this.value.push(accessorial);
                this.valueMap.set(accessorial.code, accessorial);
            }

            for (accessorial of $event) {
                if (accessorial.valueRequired) {
                    availAccessorial = this.availableAccessorialsMap.get(accessorial.code);
                    availAccessorial.value = accessorial.accessorialValue;
                    accessorial.value = accessorial.accessorialValue;
                    delete accessorial.accessorialValue;
                }
                this.value.push(accessorial);
                valueMapAdds.push(accessorial);
            }
        }
        this.showAccessorialModal = false;
        this.dialogShown.emit(false);
        this.buildShownList();

        for (accessorial of valueMapAdds) {
            this.valueMap.set(accessorial.code, accessorial);
        }

        this.valueChanged(this.value);
    }

    public showDialog(val, group: AccessorialGroup) {
        this.groupShown = group;
        this.dialogShown.emit(val);
        this.showAccessorialModal = true;
    }

    public getAccessorialListFromGroup(groupShown: AccessorialGroup) {
        const list = new Array<AccessorialForQuote>();
        let acclCode;
        let accl: AccessorialForQuote;

        if (groupShown?.accls) {
            for (acclCode of groupShown.accls) {
                accl = this.availableAccessorialsMap.get(acclCode);
                if (accl) {
                    list.push(accl);
                }
            }
        }

        return list;
    }

    public isDisplayGroup(code) {
        return code === DISPLAY_GROUP_CODE;
    }

    public isAllAccessorialsGroup(code) {
        return code === ALL_ACCESSORIALS_GROUP_CODE;
    }

    public getAllAccessorialsGroup() {
        return _.find(this.groups, group => group.code === ALL_ACCESSORIALS_GROUP_CODE);
    }

    private valueChanged = (__: any) => {};

    private deleteAccessorial(code) {
        const idx = _.findIndex(this.value, (val) => val.code === code);
        if (idx !== -1) {
            this.value.splice(idx, 1);
        }
        this.valueMap.delete(code);
    }

    private checkAccessorial(code) {
        const accessorial = this.availableAccessorialsMap.get(code);
        let idx = _.findIndex(this.value, (val) => val.code === code);

        if (idx === -1) {
            idx = this.value.length;
            this.value.push(_.cloneDeep(accessorial));
        }

        if (accessorial.valueRequired) {
            this.value[idx].value = accessorial.value;
            if (accessorial.value === undefined) {
                this.payload = accessorial;
                this.showNumberModal = true;
            }
        }
        this.valueMap.set(code, this.availableAccessorialsMap.get(code));
    }
}
