<div class="tabContainer">
    <app-tab-row tabindex="0" *ngIf="activeTab !== -1" class="tabsRow" [activeTab]="activeTab" (activatedTab)="activeTab = $event.index" [grayTabs]="false" [useArrows]="true" [useDownArrow]="true" [tabs]="tabs"></app-tab-row>
</div>

<div class="shipmentContainer" *ngIf="shipmentUnifiedForm">

    <form class="shipmentForm" [formGroup]="shipmentUnifiedForm">
        <div *ngIf="activeTab !== -1" class="headerRow">
            <div class="bolCol">
                <div class="bolLabel">BOL<span style="font-weight: normal" class="required">*</span></div>
                <input [ngClass]="getClassFor('bol')" class="bolInput" formControlName="bol" />
            </div>
            <div class="pickupCol">
                <div class="dateLabel">Pickup<span class="required">*</span></div>
                <app-date (dialogShown)="dialogUp = $event" [ngClass]="getClassFor('pickupDate')" class="dateInput" title="Pickup Date" formControlName="pickupDate" [displayDateFormat]="displayDateFormat" [allowIndefinately]="false" [displayDateValidation]="displayDateValidation"></app-date>
            </div>
            <div class="carrierIconCol">
                <ng-container *ngIf="getSelectedCarrier(); let carrier">
                    <img class="carrierLogo" src="{{ carrier.carrierLogoURL }}" alt="{{ carrier?.name }} logo">
                </ng-container>
            </div>
            <div class="buttonsCol">
                <app-button tabindex="0" (click)="addInsurance()" [buttonColor]="'yellow'" [inactive]="!!shipmentUnifiedForm.get('insurance').value">Add Insurance</app-button>
                <app-button tabindex="0" (click)="getQuote()" >{{getSelectedCarrier() ? 'Requote' : 'Quote'}}</app-button>
                <app-button tabindex="0" (click)="save()" [buttonColor]="'green'" [inactive]="saveInactive()">Save</app-button>
                <app-button *ngIf="_signalsService.loginSettingsSignal().permissions[0].shipments_execute" tabindex="0" (click)="validateShipment()" [buttonColor]="'red'" [inactive]="!getSelectedCarrier() || !saveInactive()">Place Shipment</app-button>
                <app-button [menu]="moreMenu" [menuLeft]="true" [buttonColor]="'white'" (click)="handleMenuButton($event)">More <i class="btn-icon fa fa-caret-down" aria-hidden="true"></i></app-button>
            </div>
        </div>

        <div *ngIf="this._shipmentService.lastShipmentIsPlaced()" class="preHeaderRow">
            <div class="stepperCol">
                <app-stepper style="font-size: 1em; width: 100%;" [labelsTop]="true" [showCircleText]="false" [steps]="steps" [value]="getStepForStatus(_shipmentService.lastShipmentUnified.Main.status)" [completeSteps]="true">
                    <ng-template #stepLabelRenderer let-i>
                        <span style="white-space: nowrap">{{getStepLabelForStatus(_shipmentService.lastShipmentUnified.Main.status, i)}}<span *ngIf="needWarningIcon(_shipmentService.lastShipmentUnified.Main.status, i)">
                                &nbsp;<i class="fa fa-exclamation-triangle triangle"></i>
                            </span>
                        </span>
                    </ng-template>
                </app-stepper>
            </div>
        </div>
        <div *ngIf="activeTab === -1" class="headerRow">
            <div class="bolStaticCol">
                <div class="bolLabel">BOL</div>
                <div class="bolStatic">{{_shipmentService.lastShipmentUnified.Main.BOL}}</div>
            </div>
            <div class="reactiveDateCol">
                {{getReactiveDate()}}
            </div>
            <div class="placedButtonsCol">
                <app-button tabindex="0" (click)="printBOL()"  [buttonColor]="'white'">Print BOL</app-button>
                <app-button tabindex="0" (click)="printSummary()" [buttonColor]="'white'">Print Summary</app-button>
                <app-button [menu]="moreMenu" [menuLeft]="true" [buttonColor]="'white'" (click)="handleMenuButton($event)">More <i class="btn-icon fa fa-caret-down" aria-hidden="true"></i></app-button>
            </div>
        </div>

        <div [style.display]="(activeTab !== 0) ? 'none' : 'flex'" class="tabDiv">
            <div class="shipmentFirstRow">
                <app-contact [showSecondAddressLine]="true" [highlightInvalids]="showInvalid" [showVlscfs]="!!_signalsService.loginSettingsSignal().permissions[0].ShowVLSCFS" class="shipmentContact" formControlName="shipper"></app-contact>
                <app-contact [showSecondAddressLine]="true" [highlightInvalids]="showInvalid" [showVlscfs]="!!_signalsService.loginSettingsSignal().permissions[0].ShowVLSCFS" class="shipmentContact" [contactType]="searchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE" formControlName="consignee"></app-contact>
                <app-accessorials class="shipmentAccessorials" formControlName="accessorials" [groups]="acclGroups"></app-accessorials>
            </div>
            <div class="shipmentSecondRow">
                <div class="shipmentSecondRowItems">
                    <app-checkbox [labelAfter]="true" [label]="'TSA Only'" formControlName="tsaOnly"></app-checkbox>
                </div>
                <div class="shipmentSecondRowItems">
                    <div class="equipmentLabel">Equipment</div>
                    <app-list class="equipmentDropdown" [list]="equipmentTypes" formControlName="equipment"></app-list>
                </div>
                <div class="shipmentSecondRowItems">
                    <div class="dateLabel">Delivery</div>
                    <app-date (dialogShown)="dialogUp = $event" class="dateInput" title="Delivery Date" formControlName="deliveryDate" [allowEmpty]="true" [displayDateFormat]="displayDateFormat" [allowIndefinately]="false" [displayDateValidation]="displayDateValidation"></app-date>
                </div>
            </div>
            <div class="shipmentThirdRow">
                <app-product-lines [highlightInvalids]="showInvalid" [readOnly]="activeTab === -1" [quickQuoteDisplay]="false" [unitOfMeasure]="unitOfMeasure" (unitOfMeasureChanged)="updateUom($event)" formControlName="productLines"></app-product-lines>
            </div>
        </div>
        <div [style.display]="(activeTab !== 1) ? 'none' : 'flex'" class="tabDiv">
            <div class="referencesFirstRow">
                <div class="referencesLeft">
                    <app-radio-group [justify]="'center'" [allowClear]="false" [values]="[{value: 'inbound', label: 'Inbound'}, {value: 'outbound', label: 'Outbound'}]" formControlName="direction" ></app-radio-group>
                    <div>Special Instructions:</div>
                    <textarea class="commentsArea" formControlName="specialInstructions"></textarea>
                    <app-drop-value [inputRestyle]="{flex: 2}" [listRestyle]="{flex: 1, 'border-style': 'solid', 'border-width': '1px', 'margin-right': '5px'}" [labels]="['Pickup Number', 'Master BOL', 'IT #', 'Container #', 'Reference #']" formControlName="miscFields"></app-drop-value>
                </div>
                <div class="referencesMid">
                    <div class="header3">Payment Terms/Bill To</div>
                    <app-radio-group formControlName="paymentTerms" [allowClear]="false" [justify]="'center'" [isDisabled]="lockBillTo"
                                     [values]="[{value: constants.PAYMENT_TERMS_Prepaid, label: 'Prepaid' }, {value: constants.PAYMENT_TERMS_Collect, label: 'Collect'}, {value: constants.PAYMENT_TERMS_ThirdParty, label: 'Third Party'}]">
                    </app-radio-group>
                    <div class="billToContainer">
                        <app-list [isDisabled]="lockBillTo" [getLabel]="getBillToLabel" [getKey]="getBillToLabel" [list]="billToList" formControlName="billTo" ></app-list>
                        <div *ngIf="shipmentUnifiedForm.value.billTo && (shipmentUnifiedForm.value.billTo?.name !== this.naBillTo.name)">
                            <div class="billToDiv" >
                                {{shipmentUnifiedForm.value.billTo.name}}<br />
                                {{shipmentUnifiedForm.value.billTo.address1}}<br />
                                <span *ngIf="shipmentUnifiedForm.value.billTo.address2">{{shipmentUnifiedForm.value.billTo.address2}}<br /></span>
                                {{shipmentUnifiedForm.value.billTo.city}},&nbsp;{{shipmentUnifiedForm.value.billTo.state}}&nbsp;{{shipmentUnifiedForm.value.billTo.zip}}
                            </div>
                        </div>
                    </div>
                    <app-insurance [carrier]="getSelectedCarrier()" class="insurance" formControlName="insurance"></app-insurance>
                </div>
                <div class="referencesRight">
                    <app-files class="filesScrolling" [showSaveButton]="false" (requestUpdate)="updateDocument($event)" (requestDelete)="deleteDocument($event)" (requestUpload)="uploadDocuments($event)" (requestDownload)="downloadDocument($event)" [files]="getFiles()" ></app-files>
                </div>
            </div>
            <div [style.display]="(isHazmatShipment) ? 'flex' : 'none'" class="referencesSecondRow">
                <div class="referencesSecondRowItems">
                    <div class="bolded">Emergency Contact:&nbsp;</div><div><input formControlName="emergencyContact" /></div>
                </div>
                <div class="referencesSecondRowItems">
                    <div class="bolded">Emergency Phone No:&nbsp;</div><div><input formControlName="emergencyPhone" /></div>
                </div>
            </div>
            <div class="referencesThirdRow">
                <div class="header3">Purchase Order Info.</div>
                <app-purchase-order-lines formControlName="purchaseOrderLines" ></app-purchase-order-lines>
            </div>
        </div>

        <div [style.display]="(activeTab !== 2) ? 'none' : 'flex'" class="tabDiv">
            <div class="carrierFirstRow">
                <div class="carrierLeft">
                    <div class="carrierLeftRow">
                        <div class="carrierLabels" style="font-weight: bold">Carrier</div>
                        <div class="carrierInputs">
                            <ng-container *ngIf="getSelectedCarrier(); else noCarrierSelected; let carrier">
                                <img class="carrierLogo" src="{{ carrier.carrierLogoURL }}" alt="{{ carrier?.name }} logo">
                            </ng-container>
                            <ng-template #noCarrierSelected>No Carrier Selected</ng-template>
                        </div>
                    </div>
                    <div class="carrierLeftRow">
                        <div class="carrierLabels">PRO #</div>
                        <input class="carrierInputs" formControlName="proNo" />
                    </div>
                    <div class="carrierLeftRow">
                        <div class="carrierLabels">Trailer #</div>
                        <input class="carrierInputs" formControlName="trailerNo" />
                    </div>
                    <div class="carrierLeftRow">
                        <div class="carrierLabels">Seal #</div>
                        <input class="carrierInputs" formControlName="sealNo" />
                    </div>
                    <div  class="carrierLeftRow">
                        <div class="carrierLabels">Carrier Quote #</div>
                        <input class="carrierInputs" formControlName="carrierQuoteNo" />
                    </div>
                </div>
                <div class="carrierMid">
                    <div class="bolded">
                        Quote # &nbsp; <span *ngIf="getSelectedCarrier(); else elseNoQuote">{{lastQuoteResponse.quoteId}}</span>
                        <ng-template #elseNoQuote>No Quote Generated</ng-template>
                    </div>
                    <app-shipping-costs *ngIf="+(this._signalsService.loginSettingsSignal().permissions[0].shipments)" class="shippingCosts" [currency]="currency" [insuranceCost]="getInsuranceCharge()" [selectedRate]="getSelectedCarrier()" [showDetails]="showCostDetails"></app-shipping-costs>
                </div>
                <div class="carrierRight">
                    <div class="addressLabel"><span *ngIf="!getSelectedCarrier()">No&nbsp;</span>Origin Terminal</div>
                    <app-address *ngIf="getSelectedCarrier(); let rate" class="address" [rateTerminal]="rate.originterminal" [showName]="false" [shipmentSelection]="shipmentAddressSelection.originTerminal" ></app-address>
                    <div class="addressLabel"><span *ngIf="!getSelectedCarrier()">No&nbsp;</span>Destination Terminal</div>
                    <app-address *ngIf="getSelectedCarrier(); let rate" class="address" [rateTerminal]="rate.destinationterminal" [showName]="false" [shipmentSelection]="shipmentAddressSelection.destinationTerminal" ></app-address>
                </div>
            </div>
        </div>
        <div [style.display]="(activeTab !== -1) ? 'none' : 'flex'" class="tabDiv">
            <div class="placedFirstRow">
                <div class="placedLeft">
                    <div *ngIf="_shipmentService.lastShipmentUnified.Main.Pro_Number" class="placedInnerRow">
                        <div class="placedLabels">Pro #</div>
                        <div class="placedData">{{_shipmentService.lastShipmentUnified.Main.Pro_Number}}</div>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedLabels">Shipper</div>
                        <app-address class="placedData" [showPhones]="false" [shipment]="_shipmentService.lastShipmentUnified.Main" [shipmentSelection]="shipmentAddressSelection.shipper" ></app-address>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedLabels">Consignee</div>
                        <app-address class="placedData" [showPhones]="false" [shipment]="_shipmentService.lastShipmentUnified.Main" [shipmentSelection]="shipmentAddressSelection.consignee" ></app-address>
                    </div>
                    <div class="placedInnerRow" *appVar="getPickupDate(_shipmentService.lastShipmentUnified.Main) as dateInfo">
                        <div class="placedLabels">{{dateInfo.text}}</div>
                        <div class="placedData">{{dateInfo.date}}</div>
                    </div>
                </div>
                <div class="placedMid">
                    <div class="placedInnerRow">
                        <div class="placedLabels">Bill To</div>
                        <app-address class="placedData" [shipmentSelection]="shipmentAddressSelection.billTo" [shipment]="_shipmentService.lastShipmentUnified.Main"></app-address>
                    </div>
                    <div class="placedInnerRow bolded">
                        <div class="placedLabels">Quote #</div>
                        <div class="placedData">{{_shipmentService.lastShipmentUnified.Quote.quoteId}}</div>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedLabels">Charges</div>
                        <app-shipping-costs class="placedData shippingCosts" [currency]="currency" [shipmentUnified]="_shipmentService.lastShipmentUnified" [insuranceCost]="getInsuranceCharge()" [showDetails]="showCostDetails"></app-shipping-costs>
                    </div>
                    <div class="placedInnerRow" *appVar="getDeliveryDate(_shipmentService.lastShipmentUnified.Main) as dateInfo">
                        <div class="placedLabels">{{dateInfo.text}}</div>
                        <div class="placedData">{{dateInfo.date}}</div>
                    </div>
                    <div *ngIf="_shipmentService.lastShipmentUnified.Main.signature" class="placedInnerRow">
                        <div class="placedLabels">Signed For By</div>
                        <div class="placedData">{{_shipmentService.lastShipmentUnified.Main.signature}}</div>
                    </div>
                </div>
                <div class="placedRight">
                    <div class="placedInnerRow">
                        <div class="placedData">
                            <ng-container *ngIf="getSelectedCarrier(); else noCarrierSelected; let carrier">
                                <img class="carrierLogo" src="{{ carrier.carrierLogoURL }}" alt="{{ carrier?.name }} logo">
                            </ng-container>
                            <ng-template #noCarrierSelected>No Carrier</ng-template>
                        </div>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedData terminalTitles">Origin Terminal</div>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedData terminalAddress address">
                            <ng-container *ngIf="getSelectedCarrier(); let rate">
                                <app-address [rateTerminal]="rate.originterminal" [showName]="false" [shipmentSelection]="shipmentAddressSelection.originTerminal" ></app-address>
                            </ng-container>
                        </div>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedData terminalTitles">Destination Terminal</div>
                    </div>
                    <div class="placedInnerRow">
                        <div class="placedData terminalAddress address">
                            <ng-container *ngIf="getSelectedCarrier(); let rate">
                                <app-address [rateTerminal]="rate.destinationterminal" [showName]="false" [shipmentSelection]="shipmentAddressSelection.destinationTerminal" ></app-address>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="placedSecondRow">
                <app-product-lines [readOnly]="true" [quickQuoteDisplay]="false" [unitOfMeasure]="unitOfMeasure" (unitOfMeasureChanged)="updateUom($event)" formControlName="placedProductLines"></app-product-lines>
            </div>
            <div class="placedThirdRow">
                <app-files class="filesPlaced" [showSaveButton]="false" (requestUpdate)="updateDocument($event)" (requestDelete)="deleteDocument($event)" (requestUpload)="uploadDocuments($event)" (requestDownload)="downloadDocument($event)" [files]="getFiles()" ></app-files>
            </div>
        </div>
    </form>
</div>

<div class="tabContainer">
    <app-be-advised></app-be-advised>
</div>

<app-quote-selection-modal *ngIf="isQuoteSelectionVisible" [insurance]="this.shipmentUnifiedForm.get('insurance').value" [instantSelect]="true" [lowestCostRateId]="lowestCostRateId" [fastestDeliveryRateId]="fastestDeliveryRateId" [rates]="rates" (selectedRate)="selectedRate($event)" (cancel)="isQuoteSelectionVisible = false" ></app-quote-selection-modal>

<app-place-shipment-modal *ngIf="isPlaceShipmentModalVisible" (closePlaceShipmentModal)="placeShipment($event)"
                          [shipment]="_shipmentService.lastShipmentUnified"
                          [insurance]="_shipmentService.lastInsurance"></app-place-shipment-modal>

<app-ok-cancel-modal *ngIf="isQuoteReRateWarningShown" [title]="'Quote Rating Needed'"
                     [message]="'You have changed information that requires a new carrier selection.'"
                     [additionalMessage]="'Updates to your quote will generate a new quote number.'" [okPayload]=""
                     [suppressCancelButton]="false"
                     (onCancel)="declineQuoteReRating()"
                     (onOk)="acceptQuoteReRating()"></app-ok-cancel-modal>

<app-shipment-notes *ngIf="isShipmentNotesFloaterVisible" [userName]="userName"
                    [shipmentNotes]="shipmentNotes"></app-shipment-notes>

<app-solicit-template-name-modal *ngIf="isTemplateNameModalVisible"
                                 (closeSolicitTemplateNameModal)="closeTemplateNameModal($event)"></app-solicit-template-name-modal>

<app-ok-cancel-modal *ngIf="showBOLWarning" [title]="'Save Shipment Needed'"
                     [message]="'You have changed information that requires the shipment be saved to print a BOL.'"
                     [additionalMessage]="'Please click SAVE SHIPMENT and try to PRINT BOL again.'" [okPayload]=""
                     [suppressCancelButton]="true"
                     (onOk)="showBOLWarning = false;" (onCancel)="showBOLWarning = false;"></app-ok-cancel-modal>

<app-insurance-quoting-modal *ngIf="isInsuranceModalShown" [carrier]="getSelectedCarrier()" [shipmentValue]="getShipmentValueForModal()" (updateInsurancePurchase)="closeInsuranceQuotingModal($event)"></app-insurance-quoting-modal>
