import { HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {NmfcSearchResult} from '../models/nmfc.searchResult';
import {ResponseDTO} from '../models/responseDto';
import {ApiService, REQUEST_TYPE_GET} from './api.service';
import {SignalsService} from "./signals.service";
import {NotificationService} from "./notification.service";

@Injectable()
export class NmfcSearchService {

    constructor(
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) {}

    public nmfcSearch(nmfcSearchQuery: string) {
        return this._api.callApiService<ResponseDTO<Array<NmfcSearchResult>>>(new HttpRequest(REQUEST_TYPE_GET, `MasterData/NMFC/search/${nmfcSearchQuery}`)).pipe(
            map(response => {
                if (response.isValid) {
                    this._signalsService.loadNmfcSearchResults(response.dto);
                    return response.dto as Array<NmfcSearchResult>;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Search - NMFC` });
                throw err;
            })
        );
    }

}
