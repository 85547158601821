import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash-es';
import {Dictionary} from 'lodash-es';
import {QuotedRate} from '../../models/quotedRate';
import {BookingOption} from '../../models/unifiedShipmentObject';

@Component({
    selector: 'app-carrier-select',
    styleUrls: ['./app-carrier-select.component.scss'],
    templateUrl: './app-carrier-select.component.html',
})
export class AppCarrierSelectComponent implements OnInit {
    @Input()
    public bookingOptions: Array<BookingOption>;
    @Input()
    public rates: Array<QuotedRate>;
    @Input()
    public lowestCostRateId: number;
    @Input()
    public fastestDeliveryRateId: number;
    @Input()
    public isCarrierKPIShown;
    @Input()
    public isRatedClassShown;
    @Input()
    public insuranceCharge = 0;
    @Output()
    public selected = new EventEmitter<BookingOption|QuotedRate>();
    @Output()
    public showCarrierDetails = new EventEmitter<Array<BookingOption|QuotedRate>>();

    public lowestCostCarrier;
    public fastestCarrier;
    public groupedOptions: Array<Array<BookingOption>>;
    public groupedRates: Array<Array<QuotedRate>>;
    public selectedOption: BookingOption|QuotedRate;

    constructor() {}

    public ngOnInit(): void {
        let bookingOption: BookingOption;
        let rate: QuotedRate;

        if (this.bookingOptions) {
            for (bookingOption of this.bookingOptions) {
                if (bookingOption.bestPriceOption || (this.bookingOptions.length === 1)) {
                    this.lowestCostCarrier = bookingOption;
                }
                if (bookingOption.bestTransitOption || (this.bookingOptions.length === 1)) {
                    this.fastestCarrier = bookingOption;
                }
                if (bookingOption.bookingStatus === 'selected') {
                    this.selectedOption = bookingOption;
                }
            }
        } else {
            for (rate of this.rates) {
                if (rate.rateId === this.lowestCostRateId) {
                    this.lowestCostCarrier = rate;
                }
                if (rate.rateId === this.fastestDeliveryRateId) {
                    this.fastestCarrier = rate;
                }
            }
        }

        if (this.bookingOptions) {
            this.groupedOptions = this.getGroupedOptions(this.bookingOptions);
        } else {
            this.groupedRates = this.getGroupedRates(this.rates);
        }
    }

    public selectOption(bookingOption: BookingOption|QuotedRate) {
        this.selectedOption = bookingOption;
        this.selected.emit(bookingOption);
    }

    public doInfoClick(bookingOptions: Array<BookingOption|QuotedRate>) {
        this.showCarrierDetails.emit(bookingOptions);
    }

    private getGroupedOptions(bookingOptions: Array<BookingOption>) {
        const optionsGrouped: Dictionary<BookingOption[]> = _.groupBy(bookingOptions, (o: BookingOption) => o.LSP.scac);
        const optionsFlat: Array<BookingOption[]> = [];

        Object.keys(optionsGrouped).forEach((scac) => {
            optionsFlat.push(optionsGrouped[scac]);
        });
        return optionsFlat;
    }

    private getGroupedRates(rates: Array<QuotedRate>) {
        const ratesGrouped: Dictionary<QuotedRate[]> = _.groupBy(rates, (o: QuotedRate) => o.scac);
        const ratesFlat: Array<QuotedRate[]> = [];

        Object.keys(ratesGrouped).forEach((scac) => {
            ratesFlat.push(ratesGrouped[scac]);
        });
        return ratesFlat;
    }
}
