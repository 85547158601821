import {Component, OnDestroy, OnInit} from '@angular/core';
import moment from 'moment';

import {FilteredSearchResultFieldValue} from 'app/models/filteredSearchResultFieldValue';
import * as DateConstants from '../../../constants/datetime.constants';
import * as SearchConstants from '../../../constants/searchCriteria';
import {DateTimeHelper} from '../../../helpers/datetime.helper';
import {SearchRequest} from '../../../models/searchRequest';
import {SearchResultTypeSet} from '../../../models/searchResultType';
import {SearchState} from '../../../models/searchState';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../services/search.service";

@Component({
    selector: 'app-entity-filter',
    templateUrl: './entity-filter.component.html',
    styleUrls: ['./entity-filter.component.scss']
})
export class EntityFilterComponent implements OnInit, OnDestroy {
    public searchResponse: SearchState;
    private searchSubscription: any;
    public currentUser;
    private displayDataSubscription: any;
    public displayData;
    public showModal = false;
    public modalField = null;
    public entityTypes: Array<string> = [];
    public entityTypeConstants = SearchConstants.SEARCH_CRITERIA_entityType;
    public searchCriteriaValueConstants = SearchConstants.SEARCH_CRITERIA_value;
    public entityDateFields: Array<string> = [];

    public isDatePickerShown: boolean = false;
    public entityFilterDateFormatPattern: string = DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES;
    public entityFilterDateValidationFormatPattern: RegExp = DateConstants.VALID_DATE_REGEX_MM_DD_YYYY_Slash;
    public defaultDatePickerDate: string;
    private selectedDateField: FilteredSearchResultFieldValue;
    private search$;
    private displayData$;

    constructor(
        private _searchService: SearchService,
        private _dateTimeHelper: DateTimeHelper,
        private _signalsService: SignalsService,
    ) {
        this.searchResponse = this._signalsService.searchStateSignal();
        this.search$ = toObservable(_signalsService.searchStateSignal);
        this.displayData$ = toObservable(this._signalsService.displaySignal);
    }

    public ngOnInit() {
        this.displayDataSubscription = this.displayData$.subscribe(displayData => {
            this.displayData = displayData;
        });

        this.searchSubscription = this.search$.subscribe((search: SearchState) => {
            this.searchResponse = search;

            if (this.searchResponse && this.searchResponse.filterTerm && this.displayData) {
                this.entityDateFields = this.displayData['dateFields'][this.searchResponse.filterTerm];
            }

            if (this.searchResponse.filterTerm && !this.searchResponse.filteredSearchResults.fieldValues) {
                this.createFilteredSearchResultsFieldValues();
            }

            this.entityTypes = [];

            this.searchResponse.searchResults.forEach((searchResultType: SearchResultTypeSet) => {
                this.entityTypes.push(searchResultType.entityType);
            });
        });

        this.currentUser = this._signalsService.userSignal();
    }

    public ngOnDestroy(): void {
        this.displayDataSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
    }

    public selectEntityFilter(entity): void {
        this._searchService.setEntityFilter(entity);
    }

    public clearEntityFilter(): void {
        this._searchService.setEntityFilter('');
        this._searchService.processGlobalSearch(this.searchResponse.searchRequest);
    }

    private createFilteredSearchResultsFieldValues(): void {
        const entityFilterFields = this.displayData.searchEntityFilters[this.searchResponse.filterTerm];
        const entityDateFields = this.entityDateFields;
        this._searchService.createFilteredSearchResultsFieldValues({entityFilterFields, entityDateFields})
    }

    public updateFilteredSearchResults(): void {
        this._searchService.updateFilteredSearchResults();
        this.searchResponse = this._signalsService.searchStateSignal();

        const searchRequest: SearchRequest = this.searchResponse ? this.searchResponse.searchRequest : null;
        const entityFilter: string = this.searchResponse ? this.searchResponse.filterTerm : '';

        if (!searchRequest || !entityFilter) {
            return;
        }

        this._searchService.processPaginatedGlobalSearch(searchRequest, entityFilter);
    }

    public removeFieldQuery(field: FilteredSearchResultFieldValue): void {
        field.query = '';

        this.updateFilteredSearchResults();
    }

    public displayModal(field: FilteredSearchResultFieldValue): void {
        this.modalField = field;
        this.showModal = true;
    }

    public closeModal(): void {
        this.showModal = false;
        this.updateFilteredSearchResults();
    }

    public showDatePicker(field: FilteredSearchResultFieldValue): void {
        this.selectedDateField = field;
        const firstDateValue = field.uniqueValues[0];
        this.defaultDatePickerDate = moment(field.uniqueValues[0]).format(DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES);
        this.isDatePickerShown = true;
    }

    public hideDatePicker({selectedDate, selectedEndDate}: { selectedDate: string, selectedEndDate?: string }): void {
        if (selectedDate && selectedEndDate) {
            this.receiveDateRangeFromDatePicker(selectedDate, selectedEndDate);
        } else if (selectedDate) {
            this.receiveDateFromDatePicker(selectedDate);
        }

        this.isDatePickerShown = false;
    }

    private receiveDateFromDatePicker(selectedDate: string): void {
        this.selectedDateField.query = this._dateTimeHelper.prepareISO_8601Date(selectedDate);
        this.updateFilteredSearchResults();
    }

    private receiveDateRangeFromDatePicker(startDate: string, endDate: string): void {
        const formattedStartDate: string = this._dateTimeHelper.prepareISO_8601Date(startDate);
        const formattedEndDate: string = this._dateTimeHelper.prepareISO_8601Date(endDate);

        this.selectedDateField.pattern = SearchConstants.SEARCH_CRITERIA_pattern.RANGE;
        this.selectedDateField.start = formattedStartDate;
        this.selectedDateField.end = formattedEndDate;
        this.selectedDateField.query = `${startDate} to ${endDate}`;

        this.updateFilteredSearchResults();
    }

    public toggleEntityFilterCheckbox(field: FilteredSearchResultFieldValue): void {
        if (!field.query || field.query === SearchConstants.SEARCH_CRITERIA_value.FALSE) {
            field.query = SearchConstants.SEARCH_CRITERIA_value.TRUE;
        } else {
            this.removeFieldQuery(field);
        }

        this.updateFilteredSearchResults();
    }

}
