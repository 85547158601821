import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {QuotesResponse} from '../models/quotesResponse';
import {REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from '../services/api.service';

const quoteResponse: QuotesResponse = {
    shipmentId: 66746479,
    origin: {city: 'Kennesaw', zipCode: '30144', state: 'GA', country: 'US'},
    originType: 'C',
    originCallAppt: false,
    destination: {city: 'Marietta', zipCode: '30062', state: 'GA', country: 'US'},
    destinationType: 'C',
    destinationCallAppt: false,
    detail: [
        {
            hazmat_packingGroup: '',
            Unit_Type: 0,
            hazmat_subClass: '',
            hazmat: 0,
            Length: 0,
            Width: 0,
            Cube: 10,
            class: 70,
            Height: 0,
            hazmat_class: '',
            name: '',
            description2: '',
            PCF: 0,
            description: '',
            Pkg_Type: 0,
            Product: 0,
            Qty: 0,
            hazmat_number: '',
            nmfc: '',
            stackable: 0,
            loadingMeters: 0,
            Weight: 1000,
            Units: 0,
            Detail_ID: 45,
            productId: 500
        }
    ],
    accl: [],
    miles: 9,
    pcf: 1,
    cube: 1,
    error: 0,
    // bestPriceRateId: 1201,
    // fastestDeliveryRateId: 1202,
    carriers: [
        {
            carrierLogoURL: '',
            ratedClass: 100,
            currency: 'USD',
            tsa: true,
            originterminal: {
                zip: '30168.0',
                code: 'WAT',
                phone800: '',
                city: 'AUSTELL',
                address: '750 AQUILA WAY',
                phone: '770-433-5600',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'WEST ATLANTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30168.0',
                code: 'WAT',
                phone800: '',
                city: 'AUSTELL',
                address: '750 AQUILA WAY',
                phone: '770-433-5600',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'WEST ATLANTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 121.89,
            comment: 'Exception for Woodlands only:  $25.00 charge for Saturday delivery when requested  by Woodlands to Airfreight Atlanta, Inc., 5158 Kennedy Drive, Forest Park, GA  30297',
            name: 'SOUTHEASTERN FRT LNS',
            guarantee: false,
            asc: {total: 15.0, charges: [{code: 'COD', amount: 15.0, name: 'COD'}]},
            rateId: 196455024,
            scac: 'SEFL',
            fsc: 6.99,
            initialcharge: 99.9,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 0,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30736.0',
                code: '2CT',
                phone800: '',
                city: 'RINGGOLD',
                address: '349 ROADWAY DR',
                phone: '706-861-8673',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'CHATTANOOGA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30736.0',
                code: '2CT',
                phone800: '',
                city: 'RINGGOLD',
                address: '349 ROADWAY DR',
                phone: '706-861-8673',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'CHATTANOOGA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 132.74,
            comment: '',
            name: 'HOLLAND MOTOR EXPRESS',
            guarantee: false,
            asc: {total: 15.0, charges: [{code: 'COD', amount: 15.0, name: 'COD'}]},
            rateId: 196455027,
            scac: 'HMES',
            fsc: 7.7,
            initialcharge: 110.04,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 100,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30144.0',
                code: 'MAR',
                phone800: '',
                city: 'KENNESAW',
                address: '3001 RUTLEDGE ROAD NW',
                phone: '770-919-2181',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'MARIETTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30144.0',
                code: 'MAR',
                phone800: '',
                city: 'KENNESAW',
                address: '3001 RUTLEDGE ROAD NW',
                phone: '770-919-2181',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'MARIETTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 145.7,
            comment: 'Contact:  Customer Service at www.customercare@estes-express.com or phone number 804-353-1900, ext. 2500',
            name: 'ESTES EXP LINES',
            guarantee: false,
            asc: {total: 15.0, charges: [{code: 'COD', amount: 15.0, name: 'COD'}]},
            rateId: 196455017,
            scac: 'EXLA',
            fsc: 8.55,
            initialcharge: 122.15,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 100,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30336.0',
                code: 'AVFUL',
                phone800: '',
                city: 'ATLANTA',
                address: '5887 FULTON INDUSTRIAL BLVD',
                phone: '800-283-7488',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA WEST (FUL)'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30336.0',
                code: 'AVFUL',
                phone800: '',
                city: 'ATLANTA',
                address: '5887 FULTON INDUSTRIAL BLVD',
                phone: '800-283-7488',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA WEST (FUL)'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 149.16,
            comment: '',
            name: 'AVERITT EXPRESS',
            guarantee: false,
            asc: {total: 25.0, charges: [{code: 'COD', amount: 25.0, name: 'COD'}]},
            rateId: 196455023,
            scac: 'AVRT',
            fsc: 8.12,
            initialcharge: 116.04,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 100,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30336.0',
                code: 'WAA',
                phone800: '',
                city: 'ATLANTA',
                address: '1800 WESTGATE PARKWAY',
                phone: '404-363-3245',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'WEST ATLANTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30336.0',
                code: 'WAA',
                phone800: '',
                city: 'ATLANTA',
                address: '1800 WESTGATE PARKWAY',
                phone: '404-363-3245',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'WEST ATLANTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 150.52,
            comment: '',
            name: 'AAA COOPER TRANSPORT',
            guarantee: false,
            asc: {total: 60.0, charges: [{code: 'COD', amount: 60.0, name: 'COD'}]},
            rateId: 196455026,
            scac: 'AACT',
            fsc: 5.92,
            initialcharge: 84.6,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 92,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30294.0',
                code: 'ATL',
                phone800: '',
                city: 'ELLENWOOD',
                address: '2765 ANVIL BLOCK ROAD',
                phone: '404-635-0045',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA  GA (46)'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30294.0',
                code: 'ATL',
                phone800: '',
                city: 'ELLENWOOD',
                address: '2765 ANVIL BLOCK ROAD',
                phone: '404-635-0045',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA  GA (46)'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 163.49,
            comment: '',
            name: 'SAIA MOTOR FREIGHT LINE LLC',
            guarantee: false,
            asc: {total: 15.0, charges: [{code: 'COD', amount: 15.0, name: 'COD'}]},
            rateId: 196455029,
            scac: 'SAIA',
            fsc: 9.71,
            initialcharge: 138.77,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 100,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30121.0',
                code: 'NRG',
                phone800: '',
                city: 'CARTERSVILLE',
                address: '21 SMILEY INGRAM RD SE',
                phone: '770-387-9447',
                state: 'GA',
                address1: 'XPO LOGISTICS FREIGHT, INC.',
                fax: '',
                name: 'ROME GA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30121.0',
                code: 'NRG',
                phone800: '',
                city: 'CARTERSVILLE',
                address: '21 SMILEY INGRAM RD SE',
                phone: '770-387-9447',
                state: 'GA',
                address1: 'XPO LOGISTICS FREIGHT, INC.',
                fax: '',
                name: 'ROME GA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 166.4,
            comment: '',
            name: 'CON WAY FREIGHT',
            guarantee: false,
            asc: {total: 25.0, charges: [{code: 'COD', amount: 25.0, name: 'COD'}]},
            rateId: 196455018,
            scac: 'CNWY',
            fsc: 9.25,
            initialcharge: 132.15,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 0,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30736.0',
                code: '2CT',
                phone800: '',
                city: 'RINGGOLD',
                address: '349 ROADWAY DR',
                phone: '706-861-8673',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'CHATTANOOGA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30736.0',
                code: '2CT',
                phone800: '',
                city: 'RINGGOLD',
                address: '349 ROADWAY DR',
                phone: '706-861-8673',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'CHATTANOOGA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 167.74,
            comment: '',
            name: 'HOLLAND MOTOR EXPRESS - Guaranteed',
            guarantee: false,
            asc: {
                total: 50.0,
                charges: [{code: 'COD', amount: 15.0, name: 'COD'}, {
                    code: 'COD',
                    amount: 35.0,
                    name: 'Guarantee Standard'
                }]
            },
            rateId: 196455025,
            scac: 'HMES',
            fsc: 7.7,
            initialcharge: 110.04,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 92,
            currency: 'USD',
            tsa: true,
            originterminal: {
                zip: '30294.0',
                code: 'ATL',
                phone800: '',
                city: 'ELLENWOOD',
                address: '2765 ANVIL BLOCK ROAD',
                phone: '404-635-0045',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA  GA (46)'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30294.0',
                code: 'ATL',
                phone800: '',
                city: 'ELLENWOOD',
                address: '2765 ANVIL BLOCK ROAD',
                phone: '404-635-0045',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA  GA (46)'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 184.84,
            comment: '',
            name: 'SAIA MOTOR FREIGHT LINE LLC - Guaranteed',
            guarantee: false,
            asc: {
                total: 36.35,
                charges: [{code: 'COD', amount: 15.0, name: 'COD'}, {
                    code: 'COD',
                    amount: 21.35,
                    name: 'Guarantee Standard'
                }]
            },
            rateId: 196455022,
            scac: 'SAIA',
            fsc: 9.71,
            initialcharge: 138.77,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 100,
            currency: 'USD',
            tsa: true,
            originterminal: {
                zip: '30336.0',
                code: 'WAA',
                phone800: '',
                city: 'ATLANTA',
                address: '1800 WESTGATE PARKWAY',
                phone: '404-363-3245',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'WEST ATLANTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30336.0',
                code: 'WAA',
                phone800: '',
                city: 'ATLANTA',
                address: '1800 WESTGATE PARKWAY',
                phone: '404-363-3245',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'WEST ATLANTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 201.52,
            comment: '',
            name: 'AAA COOPER TRANSPORT - Guaranteed',
            guarantee: false,
            asc: {
                total: 111.0,
                charges: [{code: 'COD', amount: 60.0, name: 'COD'}, {
                    code: 'COD',
                    amount: 51.0,
                    name: 'Guarantee Standard'
                }]
            },
            rateId: 196455021,
            scac: 'AACT',
            fsc: 5.92,
            initialcharge: 84.6,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 92,
            currency: 'USD',
            tsa: true,
            originterminal: {
                zip: '30060.0',
                code: '402.0',
                phone800: '',
                city: 'MARIETTA',
                address: '1892 AIRPORT INDUSTRIAL PARK DR',
                phone: '770-952-9341',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30060.0',
                code: '402.0',
                phone800: '',
                city: 'MARIETTA',
                address: '1892 AIRPORT INDUSTRIAL PARK DR',
                phone: '770-952-9341',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 216.92,
            comment: '',
            name: 'YRC INC.',
            guarantee: false,
            asc: {total: 50.0, charges: [{code: 'COD', amount: 50.0, name: 'COD'}]},
            rateId: 196455028,
            scac: 'RDWY',
            fsc: 10.92,
            initialcharge: 156.0,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 92,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30060.0',
                code: '402.0',
                phone800: '',
                city: 'MARIETTA',
                address: '1892 AIRPORT INDUSTRIAL PARK DR',
                phone: '770-952-9341',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30060.0',
                code: '402.0',
                phone800: '',
                city: 'MARIETTA',
                address: '1892 AIRPORT INDUSTRIAL PARK DR',
                phone: '770-952-9341',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 258.65,
            comment: '',
            name: 'YRC Freight Accelerated',
            guarantee: false,
            asc: {total: 50.0, charges: [{code: 'COD', amount: 50.0, name: 'COD'}]},
            rateId: 196455020,
            scac: 'YRCA',
            fsc: 13.65,
            initialcharge: 195.0,
            service: {number: 60, name: 'LTL'}
        },
        {
            carrierLogoURL: '',
            ratedClass: 92,
            currency: 'USD',
            tsa: false,
            originterminal: {
                zip: '30060.0',
                code: '402.0',
                phone800: '',
                city: 'MARIETTA',
                address: '1892 AIRPORT INDUSTRIAL PARK DR',
                phone: '770-952-9341',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA'
            },
            relation: 'D',
            destinationterminal: {
                zip: '30060.0',
                code: '402.0',
                phone800: '',
                city: 'MARIETTA',
                address: '1892 AIRPORT INDUSTRIAL PARK DR',
                phone: '770-952-9341',
                state: 'GA',
                address1: '',
                fax: '',
                name: 'ATLANTA'
            },
            estimatedDeliveryDate: '2018-05-09',
            fscpercent: 7.0,
            days: 1,
            finalcharge: 276.92,
            comment: '',
            name: 'YRC INC. - Guaranteed',
            guarantee: false,
            asc: {
                total: 110.0,
                charges: [{code: 'COD', amount: 50.0, name: 'COD'}, {
                    code: 'COD',
                    amount: 60.0,
                    name: 'Guarantee Standard'
                }]
            },
            rateId: 196455019,
            scac: 'RDWY',
            fsc: 10.92,
            initialcharge: 156.0,
            service: {number: 60, name: 'LTL'}
        }
    ],
    selectedRateId: 0,
    shipdate: '2017-10-23',
    fastestDeliveryRateId: 196455024,
    lowestCostRateId: 196455024,
    unitmeasurement: 'U',
    tsa: false,
    direction: 'inbound',
    quote_name: '',
    equipmentType: 0,
};

@Injectable()
export class MockQuoteInterceptor implements HttpInterceptor {

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.useMocking.quote) {
            if (req.url.startsWith(`${environment.apiBaseUrl}/cfc/site.cfc?`) && req.method === REQUEST_TYPE_GET) {
                return of(this.createQuoteMock(req));
            }
        }
        return next.handle(req);
    }

    private createQuoteMock(quoteRequest): HttpResponse<any> {
        if (quoteRequest.origZip === '00001') { // return error response - scenario 1 - ???
            return new HttpResponse({
                status: 400,
                body: {
                    messageType: 'ERROR',
                    message: 'Create Quote Failed',
                }
            });

        } else { // return successful response
            const response = new HttpResponse({
                status: 200,
                body: quoteResponse
            });
            return response;
        }
    }
}
