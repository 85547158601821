import {Injectable} from "@angular/core";
import {LoginSettings} from "../models/loginSettings";
import { HttpRequest } from "@angular/common/http";
import {ApiService, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from "./api.service";
import {ResponseDTO} from "../models/responseDto";
import {catchError, map} from "rxjs/operators";
import {SignalsService} from "./signals.service";
import {NotificationService} from "./notification.service";

@Injectable()
export class LoginService {

    constructor(
        private _notificationService: NotificationService,
        private _api: ApiService,
        private _signalsService: SignalsService,
    ) {}

    public async getJWTFromFSO(fso) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `/Login/FSO/check`, {token: fso});
        if (!fso) {
            return undefined;
        }
        return this._api.callApiService<ResponseDTO<any>>(req).pipe(
            map((response) => {
                if (response && response.isValid) {
                    return response.dto.jwt;
                } else {
                    return undefined;
                }
            }),
            catchError(() => undefined)
        ).toPromise();
    }

    public getLoginSettings() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Login/Settings`);
        return this._api.callApiService<ResponseDTO<LoginSettings>>(req).pipe(
            map((response) => {
                if (response && response.isValid) {
                    this._signalsService.loginSettingsSignal.set(response.dto);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError((err) => {
                this._signalsService.clearLoginSettings();
                this._signalsService.clearUserLoggedIn();
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Login Settings' });
                throw err;
            })
        );
    }
}
